import * as React from 'react'

function SvgClipboard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--clipboard ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-clipboard_svg__a"
          d="M19 19c0 .552-.449 1-1 1H6c-.551 0-1-.448-1-1V8c0-.552.449-1 1-1v1c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V7c.551 0 1 .448 1 1v11zM8 4l8 .003V8H8V4zm10 1V4c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v1C4.346 5 3 6.346 3 8v11c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V8c0-1.654-1.346-3-3-3z"
        />
      </defs>
      <use xlinkHref="#24-clipboard_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgClipboard
