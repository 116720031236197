import React from 'react'
import { Gift, Star, TagIcon, Leaf } from '../NewIcons'

const Tag = ({ variant, colore, isBrand, label }) => {

  const hasIcon = variant === 'occasioni' || variant === 'novita' || variant === 'best-seller' || variant === 'sustainable-living'

  const SelectedIcon = () => {
    switch (variant) {
      case 'occasioni':
        return <Gift />
      case 'novita':
        return <Star />
      case 'best-seller':
        return <TagIcon />
      case 'sustainable-living':
        return <Leaf />
      default:
        return ''
    }
  }

  return (
    <div className={`tag ${isBrand ? 'tag--brand' : ''} ${hasIcon ? 'tag--with-icon' : ''} ${colore ? 'tag--' + colore : ''}`}>
      {!isBrand ? (
        <>
          {hasIcon && 
          <div className="tag__icon">
            <SelectedIcon />
          </div>
          }
          <p className="tag__label">{label}</p>
        </>
      ) : (
        <div className="tag__brand">{label}</div>
      )}
    </div>
  )
}

export default Tag
