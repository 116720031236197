import { api } from '../utils'
import { MENUS } from '../utils/endpoint'

export const fetchMenus = (lang = 'it', apiBaseUrl = '') => ({
  type: 'FETCH_HEADER',
  payload: api.get(apiBaseUrl + MENUS, { params: { lang } }),
})

export default function reducer(
  state = {
    menusData: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_HEADER_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_HEADER_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_HEADER_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        menusData: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
