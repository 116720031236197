import React from 'react'
import IconBase from '../IconBase/IconBase'

function Facebook({ className }) {
  return (
    <IconBase name="facebook" w={16} className={className}>
      <path
        d="M9.33464 9.00001H11.0013L11.668 6.33334H9.33464V5.00001C9.33464 4.31334 9.33464 3.66668 10.668 3.66668H11.668V1.42668C11.4506 1.39801 10.63 1.33334 9.7633 1.33334C7.9533 1.33334 6.66797 2.43801 6.66797 4.46668V6.33334H4.66797V9.00001H6.66797V14.6667H9.33464V9.00001Z"
        fill="white"
      />
    </IconBase>
  )
}

export default Facebook
