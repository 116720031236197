import { api, API_HOST } from '.'

export const downloadFileFromApi = async (url, fallbackFilename) => {
  let finalUrl = url
  if (url.includes('http://') || url.includes('https://')) {
    finalUrl = url
  } else {
    finalUrl = (process.env.NODE_ENV === 'development' ? API_HOST : window.location.origin) + url
  }
  return api.get(finalUrl, { responseType: 'blob' }).then((res) => {
    const filename =
      res.headers?.['content-disposition']?.match(/filename=['"]?([^'"\s]+)['"]?/)?.[1] ||
      fallbackFilename
    const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers.content_type }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)

    return res
  })
}
