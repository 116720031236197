import React, { useEffect, useState } from 'react'

export const QuantitaContext = React.createContext()

const QuantitaContextProvider = (props) => {
  const [quantita, setQuantita] = useState([])
  const [erroriQuantita, setErroriQuantita] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [ContextCart, setContextCart] = useState()
  useEffect(() => {
    checkQuantita()
  }, [quantita])

  useEffect(() => {
    checkQuantita()
  }, [])

  useEffect(() => {}, [ContextCart])

  const checkProssimi = (codice_articolo, prossimi_arrivi, disponibilita) => {
    const new_quantita = [...quantita]

    if (ContextCart) {
      const valore = ContextCart.righe_carrello.filter(
        (riga) => riga.articolo.codice === codice_articolo
      )
      const riga = valore.length > 0 ? valore[0] : null
      //nel caso in cui quantita inserita + quantita nella riga > disponibilità => CalcoloNuoviArrivi
      const totale = riga?.quantita + getQuantita(codice_articolo)
      prossimi_arrivi &&
        prossimi_arrivi.length > 0 &&
        totale > disponibilita &&
        new_quantita.forEach((articolo) => {
          if (articolo.codice === codice_articolo) {
            articolo.prossimi_arrivi = getNuoviArrivi(prossimi_arrivi, disponibilita, totale)
            articolo.prenotazione = true
          }
        })

      setQuantita(new_quantita)
      return totale
    }
  }

  const getQuantitaDalPreventivo = (codice_articolo, disponibilita, isEdit) => {
    if (ContextCart && !isEdit) {
      const valore = ContextCart.righe_carrello.filter(
        (riga) => riga.articolo.codice === codice_articolo
      )
      const riga = valore.length > 0 ? valore[0] : null
      const risultato = disponibilita - riga?.quantita < 0 ? 0 : disponibilita - riga?.quantita
      return riga?.quantita ? risultato : disponibilita
    }
    return disponibilita
  }

  const orderObj = (codice_selectedArticolo) => {
    const new_order_obj = [...quantita]
    const result = new_order_obj.sort((a, b) =>
      a.codice == codice_selectedArticolo ? -1 : b.codice == codice_selectedArticolo ? 1 : 0
    )
    setQuantita(result)
  }

  const initializeAll = (articoli) => {
    const new_quantita = [...quantita]

    articoli.forEach(
      (articolo) =>
        quantita.filter((art) => art.codice === articolo.codice).length === 0 &&
        new_quantita.push({
          id: articolo.id,
          codice: articolo.codice,
          multipli: articolo.acquistabile_multipli === 0 ? 1 : articolo.acquistabile_multipli,
          quantita: 0,
          prossimi_arrivi: [],
          escludi_da_listino_di_stampa: articolo?.escludi_da_listino_di_stampa
            ? articolo?.escludi_da_listino_di_stampa
            : null,
        })
    )
    setQuantita(new_quantita)
  }

  const initilizeCell = (id, codice_articolo, multipli, escludi_da_listino_di_stampa) => {
    const new_quantita = [...quantita]

    quantita.filter((articolo) => articolo.codice === codice_articolo).length === 0 &&
      new_quantita.push({
        codice: codice_articolo,
        multipli: multipli === 0 ? 1 : multipli,
        id: id,
        prossimi_arrivi: [],
        quantita: 0,
        escludi_da_listino_di_stampa: escludi_da_listino_di_stampa
          ? escludi_da_listino_di_stampa
          : null,
      })

    setQuantita(new_quantita)
  }

  //controlla se ogni quantita rispetta la condizione dei multipli & che almeno un articolo abbia una quantità inserita
  const checkQuantita = () => {
    const new_errors = Object.assign({}, erroriQuantita)

    // ! Attualmente commentare il controllo degli errori per i multipli
    // ! L'utente a quanto pare può inserire una quantità libera tramite input

    // Capire quali sono le conseguenza nell'inserire una quantità libera -> un possibile sconto?

    const isError = (articolo) => {
      return articolo?.quantita % articolo?.multipli === 0 ? false : true
    }

    quantita.forEach((articolo) => {
      isError(articolo)
        ? (new_errors[articolo?.codice] = true)
        : (new_errors[articolo?.codice] = false)
    })

    quantita.length > 0
      ? quantita.filter((articolo) => articolo.quantita !== 0).length === 0
        ? setDisabled(true)
        : disabled === true && setDisabled(false)
      : setDisabled(true)

    setErroriQuantita(new_errors)
  }

  const insertArticolo = (codice_articolo, quantita_articolo, multipli, id) => {
    const new_quantita = [...quantita]
    const nuovo_articolo = {
      codice: codice_articolo,
      quantita: quantita_articolo,
      multipli: multipli ? multipli : 1,
      prossimi_arrivi: [],
      id: id,
    }
    new_quantita.filter((articolo) => articolo?.codice === codice_articolo).length === 0
      ? new_quantita.push(nuovo_articolo)
      : new_quantita.forEach(
          (articolo) =>
            articolo.codice === codice_articolo && (articolo.quantita = quantita_articolo)
        )

    setQuantita(new_quantita)
  }

  //è valido solo quando entrambe le condizioni sono VALIDE -> Quindi bisogna fare un check anche per disabled
  const isValid = () => {
    return disabled === false && getErroriQuantita() === true ? true : false
  }

  const getErroriQuantita = () => {
    return Object.values(erroriQuantita).filter((errore) => errore === true).length > 0
      ? false
      : true
  }

  const getError = (codice_articolo) =>
    erroriQuantita[codice_articolo] ? erroriQuantita[codice_articolo] : false

  //permette di ricevere la quantita dell'articolo con il codice desiderato
  const getQuantita = (codice_articolo) => {
    const result = quantita.filter((articolo) => articolo.codice === codice_articolo)
    return result.length > 0 ? (isNaN(result[0].quantita) ? 0 : result[0].quantita) : 0
  }

  const getRigaQuantita = (codice_articolo) => {
    if (ContextCart) {
      const valore = ContextCart.righe_carrello.filter(
        (riga) => riga.articolo.codice === codice_articolo
      )
      const riga = valore.length > 0 ? valore[0] : null

      return riga?.quantita
    }
  }

  const updateQuantita = (codice_articolo, quantita_articolo) => {
    const new_q = [...quantita]
    new_q.forEach((articolo) => {
      if (articolo.codice === codice_articolo) {
        articolo.quantita = quantita_articolo
      }
    })
    setQuantita(new_q)
  }

  const funcGetNuoviArrivi = (arrivi, da_prenotare) => {
    let counter = 0
    let res_prossimo_arrivo
    arrivi.forEach((obj_prossimo_arrivo, index) => {
      if (counter < da_prenotare) {
        counter += obj_prossimo_arrivo.disponibilita
        if (counter >= da_prenotare || index == arrivi.length - 1) {
          res_prossimo_arrivo = obj_prossimo_arrivo
        }
      }
    })
    return res_prossimo_arrivo
  }

  const getNuoviArrivi = (prossimi_arrivi, disponibilita, quantita_articolo) => {
    let new_prossimi_arrivi = []
    if (!prossimi_arrivi) return new_prossimi_arrivi
    let counter = 0
    let da_prenotare = quantita_articolo - disponibilita

    prossimi_arrivi = prossimi_arrivi.filter(
      (prossimo_arrivo) => prossimo_arrivo.prenotabile === true
    )

    let index = prossimi_arrivi.length

    while (counter < da_prenotare && index != 0) {
      let prossimo_arrivo = funcGetNuoviArrivi(
        prossimi_arrivi.slice(0, index),
        da_prenotare - counter
      )
      index = prossimi_arrivi.indexOf(prossimo_arrivo)
      new_prossimi_arrivi.push(prossimo_arrivo)
      counter += prossimo_arrivo.disponibilita
    }
    return new_prossimi_arrivi
  }

  //controlla tuttto quello viene inserito dentro l'input
  const onChangeInputQuantita = (
    id,
    codice_articolo,
    input_quantita,
    massimaQuantitaPrenotabile,
    multipli,
    disponibilita,
    prossimi_arrivi,
    isEdit,
    escludi_da_listino_di_stampa
  ) => {
    const new_quantita = [...quantita]
    let value = isNaN(input_quantita) ? 0 : input_quantita
    new_quantita.filter((articolo) => articolo.codice === codice_articolo).length === 0 &&
      new_quantita.push({
        id: id,
        codice: codice_articolo,
        quantita: 0,
        multipli: multipli === 0 ? 1 : multipli,
        prossimi_arrivi: [],
        escludi_da_listino_di_stampa: escludi_da_listino_di_stampa
          ? escludi_da_listino_di_stampa
          : null,
      })

    new_quantita.forEach((articolo) => {
      if (articolo.codice === codice_articolo) {
        ;(!prossimi_arrivi || !prossimi_arrivi?.length > 0) && (input_quantita = disponibilita)

        articolo.quantita =
          massimaQuantitaPrenotabile > 0
            ? value > getQuantitaDalPreventivo(codice_articolo, massimaQuantitaPrenotabile, isEdit)
              ? getQuantitaDalPreventivo(codice_articolo, massimaQuantitaPrenotabile, isEdit)
              : value
            : value
        articolo.prenotazione = false
        if (value > disponibilita && value <= massimaQuantitaPrenotabile) {
          articolo.prossimi_arrivi = getNuoviArrivi(
            prossimi_arrivi,
            disponibilita,
            input_quantita
          )
          articolo.prossimi_arrivi.length > 0 && (articolo.prenotazione = true)
        }

        checkProssimi(codice_articolo, prossimi_arrivi, disponibilita)
      }
    })
    setQuantita(new_quantita)
  }

  const resetAllQuantita = () => {
    const new_quantita = [...quantita]
    new_quantita.length > 0 &&
      new_quantita.forEach((articolo) => {
        articolo.quantita = 0
        articolo.prossimi_arrivi = []
      })

    setQuantita(new_quantita)
  }

  const resetQuantita = (codice_articolo) => {
    const new_quantita = [...quantita]
    const isNotEmpty = new_quantita.filter((articolo) => codice_articolo === articolo.codice).length

    isNotEmpty > 0 &&
      new_quantita.forEach(
        (articolo) =>
          articolo.codice === codice_articolo &&
          ((articolo.quantita = 0), (articolo.prossimi_arrivi = []))
      )

    setQuantita(new_quantita)
  }

  const handleQuantita = (
    id,
    codice_articolo,
    quantita_articolo,
    massimaQuantitaPrenotabile,
    prossimi_arrivi,
    disponibilita,
    type,
    isEdit,
    noMultiplo,
    escludi_da_listino_di_stampa
  ) => {
    const new_quantita = [...quantita]
    // ! Nel caso fosse 0 dare multipli di 1
    quantita_articolo === 0 && (quantita_articolo = 1)

    // ? Inizializzo l'oggetto
    new_quantita.filter((articolo) => articolo.codice === codice_articolo).length === 0 &&
      new_quantita.push({
        codice: codice_articolo,
        quantita: 0,
        multipli: noMultiplo ? 1 : quantita_articolo,
        id: id,
        prossimi_arrivi: [],
        noMultiplo: noMultiplo ? quantita_articolo : null,
        escludi_da_listino_di_stampa: escludi_da_listino_di_stampa
          ? escludi_da_listino_di_stampa
          : null,
      })

    // ! Per scegliere il Multiplo corretto
    const getMultiplo = (articolo) => {
      return noMultiplo ? (articolo?.noMultiplo ? articolo?.noMultiplo : 1) : articolo?.multipli
    }
    switch (type) {
      case 'add':
        new_quantita.forEach((articolo) => {
          if (articolo.codice === codice_articolo) {
            const isCorrect = articolo.quantita % getMultiplo(articolo) === 0
            const calcoloMultiplo =
              Math.ceil(articolo.quantita / getMultiplo(articolo)) * getMultiplo(articolo)

            //console.log(noMultiplo, getMultiplo(articolo), isCorrect, calcoloMultiplo)
            const valoreCorretto = isNaN(calcoloMultiplo)
              ? 0
              : quantita_articolo > disponibilita
              ? disponibilita
              : calcoloMultiplo

            articolo.quantita = isCorrect
              ? (isNaN(articolo.quantita) ? 0 : articolo.quantita) + quantita_articolo
              : valoreCorretto

            // ! Per evitare che venga ritornato NaN nell'inserire un multiplo superiore alla quantita disponibile
            // ? Permette di inserire nell'input la differenza data dalla disponibilità e il valore già inserito nel preventivo
            // ! -> questo check permette di forzare il non andare oltre alle disponibilita -> ;(!prossimi_arrivi || !prossimi_arrivi.length > 0) &&
            // ? Vedere quali sono le casistiche e come vuole gestirle il cliente
            // ! Attualmente le quantità non superano la massimaDisponibilità a prescindere dalla presenza di prossimi Arrivi o no
            // ! La massima disponibilità è data dalla somma delle disponibilità o dalla disponibilità nel caso in cui mancassero i prossimi arrivi
            articolo.quantita >
              getQuantitaDalPreventivo(codice_articolo, massimaQuantitaPrenotabile, isEdit) &&
              (articolo.quantita = getQuantitaDalPreventivo(
                codice_articolo,
                massimaQuantitaPrenotabile,
                isEdit
              ))

            articolo.prenotazione = false
            articolo.quantita > disponibilita &&
              ((articolo.prossimi_arrivi = getNuoviArrivi(
                prossimi_arrivi,
                disponibilita,
                quantita_articolo
              )),
              (articolo.prenotazione = true))

            checkProssimi(codice_articolo, prossimi_arrivi, disponibilita)
            massimaQuantitaPrenotabile > 0 &&
              articolo.quantita > massimaQuantitaPrenotabile &&
              (articolo.quantita = massimaQuantitaPrenotabile)
          }
        })
        break
      case 'remove':
        new_quantita.forEach((articolo) => {
          if (articolo.codice === codice_articolo) {
            const isCorrect = articolo.quantita % getMultiplo(articolo) === 0
            const valoreCorretto =
              Math.ceil(articolo.quantita / getMultiplo(articolo)) * getMultiplo(articolo) -
              getMultiplo(articolo)

            articolo.codice === codice_articolo &&
              (articolo.quantita =
                articolo.quantita - quantita_articolo > 0
                  ? (articolo.quantita === NaN ? 0 : articolo.quantita) - quantita_articolo
                  : 0)

            articolo.prenotazione = false
            articolo.quantita > disponibilita &&
              ((articolo.prossimi_arrivi = getNuoviArrivi(
                prossimi_arrivi,
                disponibilita,
                quantita_articolo
              )),
              (articolo.prenotazione = true))

            checkProssimi(codice_articolo, prossimi_arrivi, disponibilita)
            articolo.codice === codice_articolo &&
              !isCorrect &&
              (articolo.quantita = valoreCorretto)
          }
        })
    }

    setQuantita(new_quantita)
  }

  const getMassimaQuantitaPrenotabile = (articolo) => {
    if (!articolo) return 0

    const prossimi_arrivi = articolo.prossimi_arrivi
    const disponibilita = articolo.disponibilita
    const disp = disponibilita ? disponibilita : 0
    return prossimi_arrivi
      ? prossimi_arrivi
          .filter((item) => item.prenotabile)
          .reduce((current, quantita) => current + Number(quantita.disponibilita), 0) + disp
      : disponibilita
      ? disponibilita
      : 0
  }

  return (
    <QuantitaContext.Provider
      value={{
        quantita,
        handleQuantita,
        erroriQuantita,
        getQuantita,
        onChangeInputQuantita,
        getError,
        disabled,
        isValid,
        insertArticolo,
        resetAllQuantita,
        resetQuantita,
        initilizeCell,
        setQuantita,
        initializeAll,
        orderObj,
        setContextCart,
        checkProssimi,
        ContextCart,
        updateQuantita,
        getMassimaQuantitaPrenotabile,
      }}
    >
      {props.children}
    </QuantitaContext.Provider>
  )
}

export default QuantitaContextProvider
