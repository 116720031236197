import { UTENTE } from '../utils/endpoint'
import { api } from '../utils'

export const fetchUtente = (locale, apiBaseUrl = '') => ({
  type: 'FETCH_UTENTE',
  payload: api
    .get(apiBaseUrl + UTENTE, {
      params: { lang: locale || 'it' },
    })
    .catch((err) => console.log(err)),
})

export const getUserAfterToken = () => ({
  type: 'FETCH_UTENTE',
  payload: api.get(UTENTE),
})

export default function reducer(
  state = {
    utente: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'LOGOUT': {
      return { ...state, fetched: true, utente: null }
    }
    case 'FETCH_UTENTE_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_UTENTE_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_UTENTE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        utente: action.payload?.data?.id !== undefined ? action.payload.data : undefined,
      }
    }

    default: {
      return state
    }
  }
}
