import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer from './root-reducer'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { createWrapper } from 'next-redux-wrapper'

const middleware = applyMiddleware(promise, thunk)

export const makeStore = () => createStore(createRootReducer(), composeWithDevTools(middleware))

/* export const makeStore = ({ isServer }) => {
  if (isServer) {
    return createStore(createRootReducer(), composeWithDevTools(middleware))
  } else {
    const { persistStore, persistReducer } = require('redux-persist')
    const storage = require('redux-persist/lib/storage').default

    const persistConfig = {
      key: 'next_js',
      whitelist: ['categorieapi', 'traduzioni'],
      storage,
    }

    const persistedReducer = persistReducer(persistConfig, createRootReducer())

    const store = createStore(persistedReducer, composeWithDevTools(middleware)) // Creating the store again

    store.__persistor = persistStore(store)

    return store
  }
} */

export const wrapper = createWrapper(makeStore, { debug: false })
