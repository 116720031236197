import { api } from '../utils'
import { CURRENTSITE } from '../utils/endpoint'

export const fetchServiceGift = (apiBaseUrl = '') => ({
  type: 'FETCH_SERVICEGIFT',
  payload: api.get(apiBaseUrl + CURRENTSITE),
})

export const fetchServiceGiftAuthenticated = (apiBaseUrl = '', headers) => ({
  type: 'FETCH_SERVICEGIFT_AUTHENTICATED',
  payload: api.get(apiBaseUrl + `${CURRENTSITE}user_authenticated/`, { headers }),
})

export const setServiceGift = (data) => ({
  type: 'SET_SERVICEGIFT',
  payload: data,
})

export default function reducer(
  state = {
    logged: null,
    as400_down: null,
    service_gift: null,
    is_user_authenticated: false,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'NON_ATTIVO': {
      return {
        ...state,
        service_gift: {
          attivo: false,
          premium: true,
          pubblico: true,
          personalizzabile: true,
        },
      }
    }
    case 'NON_PUBBLICO': {
      return {
        ...state,
        logged: null,
        service_gift: {
          attivo: true,
          lingue: ['it'],
          logo_url:
            'https://media.on-gadget.com/media/filer_public/7b/f5/7bf560bb-8709-4d79-a9be-f62300d54104/logo_iol_italia.png',
          premium: true,
          pubblico: false,
          personalizzabile: true,
        },
      }
    }
    case 'NON_PERSONALIZZABILE': {
      return {
        ...state,
        service_gift: {
          attivo: true,
          premium: true,
          pubblico: true,
          personalizzazione: false,
        },
      }
    }
    case 'IS_NOT_PREMIUM': {
      return {
        ...state,
        service_gift: {
          attivo: true,
          premium: false,
          pubblico: true,
          lingue: ['it', 'en'],
          personalizzazione: false,
        },
      }
    }
    case 'LOGGED': {
      return { ...state, logged: true }
    }
    case 'IS_PREMIUM': {
      return {
        ...state,
        service_gift: {
          attivo: true,
          lingue: ['it'],
          logo_url:
            'https://media.on-gadget.com/media/filer_public/7b/f5/7bf560bb-8709-4d79-a9be-f62300d54104/logo_iol_italia.png',
          premium: true,
          pubblico: true,
          personalizzazione: true,
        },
      }
    }
    case 'FETCH_SERVICEGIFT_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_SERVICEGIFT_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_SERVICEGIFT_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        as400_down: action.payload.data?.as400_down ? action.payload.data?.as400_down : null,
        service_gift: action.payload.data?.servicegift ? action.payload.data.servicegift : null,
      }
    }
    case 'FETCH_SERVICEGIFT_AUTHENTICATED_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_SERVICEGIFT_AUTHENTICATED_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_SERVICEGIFT_AUTHENTICATED_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        is_user_authenticated: action.payload.data,
      }
    }
    case 'SET_SERVICEGIFT': {
      return {
        ...state,
        fetching: false,
        fetched:
          !action.payload.data?.servicegift || action.payload.data?.servicegift?.pubblico
            ? true
            : false, // se non è pubblico lascio a false perchè sarà impostato da FETCH_SERVICEGIFT_AUTHENTICATED_FULFILLED
        as400_down: action.payload.data?.as400_down ? action.payload.data?.as400_down : null,
        service_gift: action.payload.data?.servicegift ? action.payload.data.servicegift : null,
      }
    }

    default: {
      return state
    }
  }
}
