import * as React from 'react'

function SvgGift(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--gift ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-gift_svg__a"
          d="M17.5 15v4H13v-4h4.5zM11 15v4H6.5v-4H11zM5 9.2c0-.083.013-.15.026-.2H11v4H5.026A.784.784 0 015 12.8V9.2zM7 6c0-.552.513-1 1.143-1C9.4 5 10.17 6.09 10.594 7H8.143C7.513 7 7 6.552 7 6zm8.857-1C16.487 5 17 5.448 17 6s-.513 1-1.143 1H13.4c.42-.91 1.188-2 2.457-2zM19 9.2v3.6c0 .083-.013.15-.026.2H13V9h5.974c.013.05.026.117.026.2zm.2-2.2h-.393c.118-.314.193-.647.193-1 0-1.654-1.41-3-3.143-3C13.935 3 12.729 4.199 12 5.397 11.271 4.199 10.065 3 8.143 3 6.41 3 5 4.346 5 6c0 .353.076.686.193 1H4.8C3.791 7 3 7.967 3 9.2v3.6c0 1.107.64 1.993 1.5 2.164v4.369c0 .919.813 1.667 1.812 1.667h11.375c1 0 1.813-.748 1.813-1.667v-4.369c.86-.171 1.5-1.057 1.5-2.164V9.2c0-1.233-.791-2.2-1.8-2.2z"
        />
      </defs>
      <use xlinkHref="#24-gift_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgGift
