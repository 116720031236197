import React from 'react'
import MyImage from '../../atoms/MyImage/MyImage'

const ProdottoSearchCard = ({ prodotto }) => {
  return (
    <div className="prodotto-search-card">
      <div className="prodotto-search-card__content">
        <div className="prodotto-search-card__image-box">
          <MyImage
            src={
              prodotto?.main_image
                ? prodotto?.main_image
                : prodotto?.image_url
                ? prodotto?.image_url
                : 'https://via.placeholder.com/96'
            }
            alt={prodotto?.titolo}
            width={96}
            height={96}
          />
        </div>

        <div className="prodotto-search-card__details">
          <p className="prodotto-search-card__title">{prodotto.codice}</p>
          <p className="prodotto-search-card__description">{prodotto?.titolo}</p>
        </div>
      </div>
    </div>
  )
}

export default ProdottoSearchCard
