import * as React from 'react'

function SvgCustom(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={'icon icon--custom ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#FFF"
              d="M9-8h6c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V-7c0-.552.448-1 1-1z"
              transform="translate(4 20) rotate(90 12 4)"
            />
            <path
              fill="#038ABF"
              d="M15-8c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V-7c0-.552.448-1 1-1h6zm-1 2h-4v18c0 1.054.816 1.918 1.85 1.995L12 14c1.054 0 1.918-.816 1.995-1.85L14 12V-6z"
              transform="translate(4 20) rotate(90 12 4)"
            />
          </g>
          <g>
            <path
              fill="#FFF"
              d="M8 0h6c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V1c0-.552.448-1 1-1z"
              transform="translate(2.873 6.336) rotate(41 11 12)"
            />
            <path
              fill="#04ACEF"
              d="M14 0c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V1c0-.552.448-1 1-1h6zm-1 2H9v18c0 1.054.816 1.918 1.85 1.995L11 22c1.054 0 1.918-.816 1.995-1.85L13 20V2z"
              transform="translate(2.873 6.336) rotate(41 11 12)"
            />
          </g>
          <g>
            <path
              fill="#FFF"
              d="M1 0h6c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V1c0-.552.448-1 1-1z"
              transform="translate(4 5)"
            />
            <path
              fill="#82DAFD"
              d="M7 0c.552 0 1 .448 1 1v19c0 2.21-1.79 4-4 4s-4-1.79-4-4V1c0-.552.448-1 1-1h6zM6 17H2v3c0 1.054.816 1.918 1.85 1.995L4 22c1.054 0 1.918-.816 1.995-1.85L6 20v-3zm0-5H2v3h4v-3zm0-5H2v3h4V7zm0-5H2v3h4V2z"
              transform="translate(4 5)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgCustom
