import * as React from 'react'

function SvgWishlistFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--wishilist-fill ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g transform="translate(-1002 -491) translate(1002 491)">
        <path d="M12 21c-.266 0-.52-.105-.708-.294l-7.767-7.78c-2.036-2.04-2.036-5.359 0-7.399C4.508 4.543 5.821 4 7.22 4s2.712.543 3.695 1.527L12 6.614l1.084-1.086C14.068 4.543 15.381 4 16.78 4s2.712.543 3.695 1.527c2.036 2.04 2.036 5.359.001 7.399l-7.768 7.781c-.188.188-.442.293-.708.293" />
      </g>
    </svg>
  )
}

export default SvgWishlistFill
