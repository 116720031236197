import * as React from 'react'

function SvgInfo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--info ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="info_svg__a"
          d="M10 8.333c.46 0 .833.374.833.834v4.166a.834.834 0 11-1.666 0V9.167c0-.46.373-.834.833-.834zm0-2.5a.834.834 0 11-.002 1.668A.834.834 0 0110 5.833zm0 10.834A6.675 6.675 0 013.333 10 6.675 6.675 0 0110 3.333 6.675 6.675 0 0116.667 10 6.675 6.675 0 0110 16.667m0-15A8.332 8.332 0 001.667 10 8.333 8.333 0 0010 18.333c4.602 0 8.333-3.73 8.333-8.333S14.602 1.667 10 1.667"
        />
      </defs>
      <use xlinkHref="#info_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgInfo
