import React, { useState } from 'react'
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const Tooltip = ({ label, id, pos, size, className, children }) => {
  const overlay = (props) => (
    <BTooltip
      id={id}
      className={'tooltip tooltip--' + (size ? size : 'md') + (className ? ' ' + className : '')}
      {...props}
    >
      {label}
    </BTooltip>
  )

  const [show, setShow] = useState(false)
  const { width } = useWindowDimensions()
  const isMobile = width < 600

  return (
    <OverlayTrigger
      placement={pos ? pos : 'top'}
      show={show}
      delay={{ show: 50, hide: 50 }}
      overlay={overlay}
    >
      <div
        onClick={() => {
          setShow(!show)
        }}
        onMouseEnter={() => {
          !isMobile && setShow(true)
        }}
        onMouseLeave={() => {
          setShow(false)
        }}
      >
        {children}
      </div>
    </OverlayTrigger>
  )
}

export default Tooltip
