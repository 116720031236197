import React, { useEffect } from 'react'
import Close from './../../atoms/NewIcons/Close'
import Button from './../../atoms/Button/Button'
import ProdottoSideCart from './../ProdottoSideCart/ProdottoSideCart'
import { useRouter } from 'next/router'
import { priceNotation } from '../../../utils/safe'
import LoadingSvg from '../../atoms/Loading/Loading'
import { Trans } from '../../atoms'
import useTrans from '../../../hooks/useTrans'
import { useSelector } from 'react-redux'

const SideCart = ({ carrello, open, setOpen, isLoadingCart }) => {
  const router = useRouter()
  const t = useTrans()
  const { service_gift } = useSelector((state) => state.servicegift)

  const close = (e) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return (
    <>
      <div
        className={'overlay-side-cart' + (open ? ' overlay-side-cart--open' : '')}
        onClick={(e) => close(e)}
      ></div>

      <div className={'side-cart' + (open ? ' side-cart--open' : '')}>
        {isLoadingCart ? (
          <LoadingSvg />
        ) : (
          carrello && (
            <>
              <div className="side-cart__head">
                <button className="side-cart__close" onClick={(e) => close(e)}>
                  <Close />
                </button>
                <p className="side-cart__title">
                  <Trans chiave="Riepilogo Carrello" />{' '}
                </p>
              </div>
              <div className="side-cart__body">
                <div className="side-cart__section">
                  {(!service_gift || service_gift?.personalizzazione) && (
                    <p className="side-cart__section-title">
                      <Trans chiave="Articoli non personalizzati" />
                    </p>
                  )}
                  <div className="side-cart__items">
                    {carrello.righe_carrello_merce_neutra?.map((item, i) => (
                      <ProdottoSideCart
                        key={i}
                        item={item}
                        prodotto={item.articolo}
                        quantita={item.quantita}
                      />
                    ))}
                  </div>
                </div>
                {carrello.personalizzazioni_carrello?.map((item) => (
                  <div className="side-cart__section">
                    <p className="side-cart__section-title">
                      <Trans chiave="Personalizzazione" />
                      {item.nome_lavorazione}
                    </p>
                    <div className="side-cart__items">
                      {item.righe_carrello.map((element, i) => (
                        <ProdottoSideCart
                          key={i}
                          item={element}
                          prodotto={element.articolo}
                          quantita={element.quantita}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="side-cart__footer">
                <div className="side-cart__footer-row side-cart__footer-row--totale">
                  <div>
                    <Trans chiave="Totale" />
                  </div>
                  <div>{priceNotation(carrello?.totale, 2)} €</div>
                </div>
                <div className="side-cart__footer-row">
                  <div>
                    <Trans chiave="Costo merce" />
                  </div>
                  <div>{priceNotation(carrello?.valore_merce, 2)} €</div>
                </div>
                {carrello?.valore_personalizzazione > 0 && (
                  <div className="side-cart__footer-row">
                    <div>
                      <Trans chiave="Costo personalizzazione" />
                    </div>
                    <div>{priceNotation(carrello?.valore_personalizzazione, 2)} €</div>
                  </div>
                )}
                {carrello?.valore_confezionamento > 0 && (
                  <div className="side-cart__footer-row">
                    <div>
                      <Trans chiave="Confezionamento" />
                    </div>
                    <div>{priceNotation(carrello?.valore_confezionamento, 2)} €</div>
                  </div>
                )}
                {/* {carrello?.valore_impianto > 0 || carrello?.valore_gestione > 0 || carrello?.valore_siae > 0 || carrello?.valore_supplementi_minimi_stampa > 0 || carrello?.valore_tracciatura_logo > 0 && ( */}
                <div className="side-cart__footer-row">
                  <div>
                    <Trans chiave="Altri Costi" />
                  </div>
                  <div>
                    {priceNotation(
                      carrello?.valore_impianto +
                        carrello?.valore_gestione +
                        carrello?.valore_siae +
                        carrello?.valore_supplementi_minimi_stampa +
                        carrello?.valore_tracciatura_logo,
                      2
                    )}{' '}
                    €
                  </div>
                </div>
                {carrello?.valore_sconto_imballi_integri != 0 && (
                  <div className="side-cart__footer-row">
                    <div>
                      <Trans chiave="Sconti" />
                    </div>
                    <div>{priceNotation(carrello?.valore_sconto_imballi_integri, 2)} €</div>
                  </div>
                )}
                {carrello?.totale_imposta > 0 && (
                  <div className="side-cart__footer-row">
                    <div>
                      <Trans chiave="IVA" />
                    </div>
                    <div>{priceNotation(carrello?.totale_imposta, 2)} €</div>
                  </div>
                )}
                {/* )} */}
                <div className="side-cart__footer-row">
                  <div>
                    {carrello?.valore_ritiro ? (
                      <Trans chiave="Ritiro" />
                    ) : (
                      <Trans chiave="Spedizione" />
                    )}
                  </div>
                  <div>
                    {priceNotation(
                      carrello?.valore_ritiro
                        ? carrello.valore_ritiro
                        : carrello?.valore_trasporto
                        ? carrello?.valore_trasporto
                        : 0,
                      2
                    )}{' '}
                    €
                  </div>
                </div>

                <div className="side-cart__footer-row side-cart__footer-row--buttons">
                  <Button
                    variant="secondary"
                    label={t('Chiudi')}
                    className="side-cart__button"
                    onClick={() => setOpen(false)}
                  />

                  <Button
                    variant="primary"
                    label={t('Vai al carrello')}
                    className="side-cart__button"
                    onClick={() => {
                      router.push('/cart')
                      setOpen(false)
                    }}
                  />
                </div>
              </div>
            </>
          )
        )}
      </div>
    </>
  )
}

export default SideCart
