import React from 'react'
import LoadingSvg from '../../atoms/Loading/Loading'
import { useSelector } from 'react-redux'

const PdfLayout = (props) => {
  const { loginRequired } = props

  const { utente, fetching, fetched } = useSelector((state) => state.utente)

  if (loginRequired && fetched && (!utente || utente?.guest)) {
    // TODO: check su guest
    window !== undefined && (window.location.href = '/myaccount/accedi/')
  }

  return loginRequired && (fetching || !utente || utente?.guest) ? (
    <LoadingSvg />
  ) : (
    <div className="w-container">{props.children}</div>
  )
}

export default PdfLayout
