import React, { useState } from 'react'
import LoginTemplate from '../../template/LoginTemplate/LoginTemplate'
import NewModal from '../../molecules/NewModal/NewModal'
import { displayNotification } from '../../atoms/Notifica/Notifica'
import LoadingSvg from '../../atoms/Loading/Loading'
import useTrans from '../../../hooks/useTrans'
import { api } from '../../../utils'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { trackingGAEvent } from '../../../utils/gtm'

const ModalLogin = ({ visible, onClose, withRedirect = false, url = '' }) => {
  const isAs400Down = useSelector((state) => state.servicegift.as400_down)
  const t = useTrans()
  const router = useRouter()
  const [error, setError] = useState()
  const [Loading, setLoading] = useState(false)
  const service_gift = useSelector((state) => state.servicegift?.service_gift)
  const login_text = useSelector((state) => state.logintext)

  const Login = async (params) => {
    const user_input = { username: params.email, password: params.password }
    setLoading(true)
    await api
      .post('/jwt/token/', user_input)
      .then(async (res) => {
        displayNotification('success.login', t('Hai eseguito il login con successo', 'success'))
        setError()
        setLoading(false)
        trackingGAEvent('login')
        if (withRedirect) {
          router.push(`${url}`).then((res) => router.reload())
          onClose()
          // router.push(`${url}`)
        } else {
          router.reload()
          onClose()
        }
      })
      .catch((err) => {
        setError(err.message)
        setLoading(false)
      })
  }
  return (
    <NewModal visible={visible} onClose={onClose} className="modal-login">
      {Loading ? (
        <LoadingSvg />
      ) : isAs400Down ? (
        <div style={{ padding: 15, textAlign: 'center' }}>
          <h3>{t('Sito in manutenzione temporanea, riprova più tardi.')}</h3>
        </div>
      ) : (
        <LoginTemplate
          Login={Login}
          CredentialError={error}
          service_gift={service_gift}
          ClosePopup={onClose}
          login_text={login_text}
          withRedirect={withRedirect}
          url={url}
          isModal
        />
      )}
    </NewModal>
  )
}

export default ModalLogin
