import { useRouter } from 'next/router'
import React, { useState, useEffect, memo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import Button from '../../atoms/Button/Button'
import Color from '../../atoms/Color/Color'
import {
  Car,
  Pin,
  Book,
  Download,
  EMail,
  Info,
  WishlistFill,
  WishlistLine,
  ChevronRight,
  ChevronDown,
} from '../../atoms/NewIcons'
import Tag from '../../atoms/Tag/Tag'
import Taglia from '../../atoms/Taglia/Taglia'
import Trans from '../../atoms/Trans/Trans'
import Wishlist from '../../atoms/WishList/WishList'
import _ from 'lodash'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import MyImage from '../../atoms/MyImage/MyImage'
import { priceNotation } from '../../../utils/safe'
import useTrans from '../../../hooks/useTrans'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import ModalLogin from '../../organisms/ModalLogin/ModalLogin'
import NewModal from '../../molecules/NewModal/NewModal'
import useDownloadFromApi from '../../../hooks/useDownloadFromApi'
import {
  showButtonsRiepilogo,
  showDettagliProdotto,
  showRiepilogo,
  showTabellaSchedaProdotto,
} from '../../../utils/servicegift'
import { GIORNICONSEGNA } from '../../../utils/endpoint'
import { useSelector } from 'react-redux'
import { api } from '../../../utils'
import { trackingDownload } from '../../../utils/gtm'

SwiperCore.use([Navigation])

const MainProdotto = ({
  utente,
  prodotto,
  selectedArticolo,
  selectedTaglia,
  selectedColor,
  HandleColorClick,
  variant,
  wishlist,
  ArrowClick,
  service_gift,
  scrollToDettagli,
  scrollToTableSchedaProdotto,
  setSelectedTaglia,
}) => {
  const t = useTrans()
  const router = useRouter()
  const isAs400Down = useSelector((state) => state.servicegift.as400_down)
  const [filteredTaglie, setTaglie] = useState([])
  const [filteredColori, setColori] = useState([])
  const [currentPrezzo, setCurrentPrezzo] = useState([])
  const [zoomModalVisible, setZoomModalVisible] = useState(false)

  const [selectedImage, setSelectedImage] = useState(
    selectedArticolo?.immagini_urls[0] ? selectedArticolo.immagini_urls[0] : null
  )
  const selectedImagePathname = selectedImage ? new URL(selectedImage)?.pathname : null

  const findTaglie = () => {
    const taglie_colori = prodotto?.colori?.filter((colore) => colore.id === selectedColor.id)[0]
    setTaglie(taglie_colori?.taglie ? taglie_colori?.taglie : [])
  }
  const checkValues = () => {
    if (prodotto?.taglie?.length > 0) {
      let new_taglie = []
      let new_colori = []
      prodotto?.taglie.forEach(
        (taglia) => !new_taglie.includes(taglia.id) && new_taglie.push(taglia.id)
      )
      prodotto?.colori.forEach(
        (colore) => !new_colori.includes(colore.id) && new_colori.push(colore.id)
      )

      new_colori.map(
        (colore) => prodotto?.colori?.filter((colore_filtered) => colore_filtered?.id === colore)[0]
      )
      findTaglie()
      setColori(
        new_colori.map(
          (colore) =>
            prodotto?.colori?.filter((colore_filtered) => colore_filtered?.id === colore)[0]
        )
      )
    }
  }

  useEffect(() => {
    setSelectedImage(selectedArticolo?.immagini_urls[0] ? selectedArticolo.immagini_urls[0] : null)
    findTaglie()
    selectedArticolo &&
      setCurrentPrezzo(
        prodotto.articoli.filter((item) => item.codice == selectedArticolo.codice)[0]?.prezzo
      )
  }, [selectedArticolo, selectedArticolo?.prezzo])

  useEffect(() => {
    findTaglie()
  }, [selectedColor])

  useEffect(() => {
    HandleChangeColor(prodotto, selectedColor, selectedArticolo, selectedTaglia)
  }, [selectedColor])

  useEffect(() => {
    selectedTaglia && HandleChangeTaglia(selectedTaglia)
  }, [selectedTaglia])
  useEffect(() => {
    checkValues()
  }, [])
  // useEffect(() => {}, [consegna])

  const HandleChangeColor = (prodotto, colore, articolo, taglia) => {
    let slug = ''
    if (taglia != '') {
      slug = prodotto.articoli.filter(
        (item) => item.attributi_lista.filter((attributo) => attributo.id === colore.id).length > 0
      )
      if (slug.length > 0) {
        let support = slug.filter(
          (item) =>
            item.attributi_lista.filter((attributo) => attributo.id == taglia?.id).length > 0
        )
        if (support.length == 0) {
          slug = slug[0].url
        } else {
          slug = support[0].url
        }
      } else {
        slug = slug[0].url
      }
    } else {
      slug = prodotto.articoli.filter(
        (item) => item.attributi_lista.filter((attributo) => attributo.id === colore.id).length > 0
      )[0].url
    }
    router.push(slug, undefined, {
      shallow: true,
    })
    HandleColorClick(colore)
  }

  const HandleChangeTaglia = (taglia) => {
    setSelectedTaglia(taglia)
    let slug = prodotto.articoli.filter(
      (item) =>
        item.attributi_lista.filter((attributo) => attributo.id === selectedColor.id).length > 0
    )
    let taglieItem = slug.filter(
      (item) => item.attributi_lista.filter((attributo) => attributo.id == taglia.id).length > 0
    )
    taglieItem.length > 0
      ? router.push(taglieItem[0].url, undefined, {
          shallow: true,
        })
      : router.push(slug[0].url, undefined, {
          shallow: true,
        })
  }

  return (
    <>
      <div className="main__prodotto">
        <NewModal
          className="main__prodotto__zoom"
          visible={zoomModalVisible}
          onClose={() => setZoomModalVisible(false)}
        >
          {/* Main Image */}
          <div className="main__image__wrapper">
            <div className="main__image">
              <MyImage
                className="main__image__unselected"
                src={selectedImage}
                alt={prodotto.titolo}
                title={prodotto.titolo}
                width={1000}
                height={1000}
                priority={true}
                loading="eager"
                onClick={() => setZoomModalVisible(false)}
              />
            </div>
          </div>

          {/*  Image Carousel */}
          <div
            className={`image__carousel ${
              selectedArticolo?.immagini_urls?.length > 3 ? 'image__carousel--nav' : ''
            }`}
          >
            {selectedArticolo?.immagini_urls && (
              <>
                <button className="image__carousel__nav image__carousel__nav--prev" />
                <button className="image__carousel__nav image__carousel__nav--next" />
                <div className="image__carousel__slider">
                  <Swiper
                    slidesPerView="auto"
                    watchOverflow
                    spaceBetween={16}
                    navigation={{
                      prevEl: '.image__carousel__nav--prev',
                      nextEl: '.image__carousel__nav--next',
                      disabledClass: 'image__carousel__nav--disabled',
                    }}
                    breakpoints={{
                      992: {
                        direction: 'vertical',
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {selectedArticolo.immagini_urls.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className={
                            'image__carousel__image-box' +
                            (selectedImage === image ? ' image__carousel__image-box--active' : '')
                          }
                        >
                          <MyImage
                            src={image}
                            key={index.toString() + image.toString()}
                            onClick={() => setSelectedImage(image)}
                            alt={prodotto.titolo}
                            title={prodotto.titolo}
                            width={96}
                            height={96}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </NewModal>

        {/* Main Image */}
        <div className="main__image__wrapper">
          <div className="main__image">
            <MyImage
              className="main__image__selected"
              src={selectedImage}
              alt={prodotto.titolo}
              title={prodotto.titolo}
              width={1000}
              height={1000}
              priority={true}
              loading="eager"
              onClick={() => {
                setZoomModalVisible(true)
              }}
            />
            <span className="main__image__tag">
              {prodotto.trend && (
                <Tag
                  variant={prodotto.trend.codice}
                  colore={prodotto.trend.colore}
                  label={prodotto.trend.nome}
                  isBrand={false}
                />
              )}
            </span>
            {!service_gift && selectedImagePathname && (
              <span className="main__image__download">
                <a
                  href={selectedImagePathname}
                  title={t('Download immagine')}
                  download
                  target="_blank"
                >
                  <Download />
                </a>
              </span>
            )}
            {utente && (
              <span className="main__image__wishlist">
                <Wishlist isActive={wishlist} onClick={() => ArrowClick()} />
              </span>
            )}
          </div>
        </div>

        {/*  Image Carousel */}
        <div
          className={`image__carousel ${
            selectedArticolo?.immagini_urls?.length > 3 ? 'image__carousel--nav' : ''
          }`}
        >
          {selectedArticolo?.immagini_urls && (
            <>
              <button className="image__carousel__nav image__carousel__nav--prev" />
              <button className="image__carousel__nav image__carousel__nav--next" />
              <div className="image__carousel__slider">
                <Swiper
                  slidesPerView="auto"
                  watchOverflow
                  spaceBetween={16}
                  navigation={{
                    prevEl: '.image__carousel__nav--prev',
                    nextEl: '.image__carousel__nav--next',
                    disabledClass: 'image__carousel__nav--disabled',
                  }}
                  breakpoints={{
                    992: {
                      direction: 'vertical',
                      slidesPerView: 3,
                      // loop: selectedArticolo.immagini_urls?.length > 3 - strippa se è condizionale (ma non posso attivarlo sempre se no duplica le immagini)
                    },
                  }}
                >
                  {selectedArticolo.immagini_urls.map((image, index) => (
                    // <img
                    //   key={index.toString() + image.toString()}
                    //   onClick={() => setSelectedImage(image)}
                    //   src={image}
                    //   className={`image__carousel__image ${
                    //     selectedImage === image && 'image__carousel__image__active'
                    //   }`}
                    // ></img>
                    <SwiperSlide key={index}>
                      <div
                        key={index}
                        className={
                          'image__carousel__image-box' +
                          (selectedImage === image ? ' image__carousel__image-box--active' : '')
                        }
                      >
                        <MyImage
                          src={image}
                          key={index.toString() + image.toString()}
                          onClick={() => setSelectedImage(image)}
                          alt={prodotto.titolo}
                          title={prodotto.titolo}
                          width={96}
                          height={96}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
        </div>

        {/* Product  */}
        <Product
          setSelectedTaglia={setSelectedTaglia}
          filteredTaglie={filteredTaglie}
          filteredColori={filteredColori}
          selectedArticolo={selectedArticolo}
          HandleChangeColor={HandleChangeColor}
          HandleChangeTaglia={HandleChangeTaglia}
          service_gift={service_gift}
          utente={utente}
          selectedColor={selectedColor}
          prodotto={prodotto}
          selectedTaglia={selectedTaglia}
          scrollToDettagli={scrollToDettagli}
          scrollToTableSchedaProdotto={scrollToTableSchedaProdotto}
          currentPrezzo={currentPrezzo}
        />
      </div>
      <div className="main-prodotto__bottom">
        {showRiepilogo(utente, service_gift, isAs400Down) && (
          <>
            <ConsegnaPrevista />
            <LinkUtili
              service_gift={service_gift}
              wishlist={wishlist}
              handleWishlist={ArrowClick}
              articolo={selectedArticolo}
              prodotto={prodotto}
            />
          </>
        )}
      </div>
    </>
  )
}

export default React.memo(MainProdotto)

const LinkUtili = ({
  service_gift,
  wishlist,
  handleWishlist,
  articolo,
  prodotto,
  mail_campioni,
}) => {
  const t = useTrans()
  const { utente } = useSelector((state) => state.utente)
  const [linkUtiliMobileOpen, setLinkUtiliMobileOpen] = useState(false)
  const [richiediCampioneModalOpen, setRichiediCampioneModalOpen] = useState(false)
  const [scaricaInfoOpen, setScaricaInfoOpen] = useState(false)
  const { downloading, saveFile } = useDownloadFromApi()

  return (
    <>
      {articolo && (
        <div
          className={'link-utili' + (linkUtiliMobileOpen ? ' link-utili--mobile-open' : '')}
          onMouseLeave={() => setScaricaInfoOpen(false)}
        >
          <p
            className="link-utili__title"
            onClick={() => setLinkUtiliMobileOpen(!linkUtiliMobileOpen)}
          >
            {t('Link utili')}
            <span className="link-utili__mobile-arrow">
              <ChevronDown />
            </span>
          </p>
          <div className="link-utili__content">
            {(!service_gift?.attivo || true) && (
              <>
                <div
                  className="link-utili__item link-utili__item--download"
                  onClick={() => setScaricaInfoOpen(!scaricaInfoOpen)}
                >
                  <span className="link-utili__icon">
                    <Download />
                  </span>
                  {t('Scarica o invia info prodotto')}
                  <div
                    className={'download-menu' + (scaricaInfoOpen ? ' download-menu--open' : '')}
                  >
                    <ul className="download-menu__list">
                      {!service_gift && (
                        <li className="download-menu__item">
                          <div className="download-menu__inner-item">
                            {t('Scarica immagini prodotto')}
                            <span className="download-menu__arrow">
                              <ChevronRight />
                            </span>
                          </div>
                          <ul className="download-menu__submenu">
                            <li className="download-menu__item">
                              <div className="download-menu__inner-item">
                                <a
                                  href={articolo?.url_zip_immagini_altarisoluzione}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (downloading) return
                                    trackingDownload('immagini', `${articolo?.codice}_images_hq`)
                                    saveFile(
                                      'hq',
                                      articolo?.url_zip_immagini_altarisoluzione,
                                      `${articolo?.codice}_images_hq.zip`
                                    )
                                  }}
                                  disabled={downloading ? true : false}
                                >
                                  {t('Scarica immagini ad alta risoluzione')}
                                </a>
                              </div>
                            </li>
                            <li className="download-menu__item">
                              <div className="download-menu__inner-item">
                                <a
                                  href={articolo?.url_zip_immagini}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (downloading) return
                                    trackingDownload('immagini', `${articolo?.codice}_images_lq`)
                                    saveFile(
                                      'lq',
                                      articolo?.url_zip_immagini,
                                      `${articolo?.codice}_images_lq.zip`
                                    )
                                  }}
                                  disabled={downloading ? true : false}
                                >
                                  {t('Scarica immagini a bassa risoluzione')}
                                </a>
                              </div>
                            </li>
                          </ul>
                          {/* TODO funzionalità submenu */}
                        </li>
                      )}
                      <li className="download-menu__item">
                        <div className="download-menu__inner-item">
                          {t('Scarica o invia scheda prodotto')}
                          <span className="download-menu__arrow">
                            <ChevronRight />
                          </span>
                        </div>
                        <ul className="download-menu__submenu">
                          <li className="download-menu__item">
                            <div className="download-menu__inner-item">
                              <Mailto
                                email=""
                                subject={t('Scheda tecnica prodotto') + ' ' + articolo?.codice}
                                body={
                                  t('Scarica la scheda tecnica del prodotto') +
                                  ' ' +
                                  `${articolo?.codice} ${articolo?.url_scheda_tecnica}`
                                }
                              >
                                {t('Invia scheda prodotto via e-mail')}
                              </Mailto>
                            </div>
                          </li>
                          <li className="download-menu__item">
                            <div className="download-menu__inner-item">
                              <a
                                href={`${articolo?.url_scheda_tecnica}`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  if (downloading) return
                                  saveFile(
                                    'scheda-tecnica',
                                    `${articolo?.url_scheda_tecnica}`,
                                    `${service_gift?.nome || 'sipec'}-prodotto-${
                                      prodotto?.codice
                                    }-${articolo?.slug}.pdf`
                                  )
                                }}
                                disabled={downloading ? true : false}
                              >
                                {t('Scarica scheda prodotto in pdf')}
                              </a>
                            </div>
                          </li>
                        </ul>
                        {/* TODO submenu */}
                      </li>
                      {false && // nascosto sia sipec che sg
                      (articolo?.url_scheda_conformita || articolo?.documenti.length > 0) ? (
                        <li className="download-menu__item">
                          <div className="download-menu__inner-item">
                            {t('Scarica documentazione')}
                            <span className="download-menu__arrow">
                              <ChevronRight />
                            </span>
                          </div>
                          <ul className="download-menu__submenu">
                            {articolo.url_scheda_conformita && (
                              <li className="download-menu__item">
                                <div className="download-menu__inner-item">
                                  <a
                                    href={`${articolo.url_scheda_conformita}`}
                                    download
                                    target="_blank"
                                  >
                                    {t('Scarica scheda conformità')}
                                  </a>
                                </div>
                              </li>
                            )}
                            {articolo?.documenti.length > 0 &&
                              articolo.documenti.map((item) => (
                                <li className="download-menu__item">
                                  <div className="download-menu__inner-item">
                                    <a href={`${item.url}`} download target="_blank">
                                      {item.nome}
                                    </a>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          {/* TODO submenu */}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {!service_gift && (
                  <div
                    className="link-utili__item"
                    onClick={() => setRichiediCampioneModalOpen(true)}
                  >
                    <span className="link-utili__icon">
                      <EMail />
                    </span>
                    <Mailto
                      email={mail_campioni}
                      subject={t('Richiesta campioni')}
                      body={t('Richiesta campioni prodotto') + ' ' + articolo.codice}
                    >
                      {t('Richiedi campione')}
                    </Mailto>
                  </div>
                )}
                {utente && (
                  <div className="link-utili__item" onClick={handleWishlist}>
                    <span className="link-utili__icon">
                      {wishlist ? <WishlistFill /> : <WishlistLine />}
                    </span>
                    {wishlist ? t('Rimuovi dai preferiti') : t('Aggiungi ai preferiti')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
const Mailto = ({ email = '', subject = '', body = '', children }) => {
  let params = subject || body ? '?' : ''
  if (subject) params += `subject=${encodeURIComponent(subject)}`
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`

  return <a href={`mailto:${email}${params}`}>{children}</a>
}

const ConsegnaPrevista = ({}) => {
  const t = useTrans()
  const { locale } = useRouter()
  const { utente } = useSelector((state) => state.utente)
  const { service_gift } = useSelector((state) => state.servicegift)
  const [consegna, setConsegna] = useState()

  useEffect(() => {
    showButtonsRiepilogo(utente, service_gift) &&
      api
        .get(GIORNICONSEGNA, { params: { lang: locale } })
        .then((response) => setConsegna(response.data))
  }, [utente])

  return consegna ? (
    <div className="consegna-prevista">
      {consegna.entro_ore !== null && (
        <div className="consegna-prevista__block">
          <span className="consegna-prevista__icon">
            <Car />
          </span>
          <div className="consegna-prevista__content">
            <p>
              <strong>
                {consegna.entro_ore > 0
                  ? t('Consegna prevista se ordini entro {0} ore:', [consegna.entro_ore])
                  : t('Consegna prevista se ordini entro {0} minuti:', [consegna.entro_minuti])}
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  <Trans chiave="Merce neutra" />:
                </strong>{' '}
                {t('tra {0} e {1}', [consegna.neutra_dal, consegna.neutra_al])}
                <span className="consegna-prevista__info">
                  <Tooltip
                    label={t(
                      'La data di consegna si riferisce a ordini per merce disponibile a magazzino'
                    )}
                  >
                    <Info />
                  </Tooltip>
                </span>
              </li>
              <li>
                <strong>
                  <Trans chiave="Merce personalizzata" />:
                </strong>{' '}
                {t('tra {0} e {1}', [consegna.personalizzata_dal, consegna.personalizzata_al])}
                <span className="consegna-prevista__info">
                  <Tooltip
                    label={t(
                      'Data di consegna potrebbe variare in base alla data di approvazione della bozza digitale o del campione fisico'
                    )}
                  >
                    <Info />
                  </Tooltip>
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
      {consegna.indirizzo !== null && (
        <div className="consegna-prevista__block">
          <span className="consegna-prevista__icon">
            <Pin />
          </span>
          <div className="consegna-prevista__content">
            <p>
              <strong>
                <Trans chiave="Invia a" />:
              </strong>
            </p>
            <p>{consegna.indirizzo}</p>
          </div>
        </div>
      )}
    </div>
  ) : null
}

const Product = memo(
  ({
    selectedArticolo,
    HandleChangeColor,
    service_gift,
    utente,
    selectedColor,
    prodotto,
    selectedTaglia,
    scrollToDettagli,
    scrollToTableSchedaProdotto,
    filteredColori,
    filteredTaglie,
    setSelectedTaglia,
    HandleChangeTaglia,
    currentPrezzo,
  }) => {
    const IconPagine = () => <Book />
    const t = useTrans()
    const router = useRouter()
    const isAs400Down = useSelector((state) => state.servicegift.as400_down)
    const [popupLogin, setPopupLogin] = useState(false)

    const VisualizzazionePrezzi = () => {
      let listino = selectedArticolo?.prezzo_listino
      const fasce = selectedArticolo?.con_fasce
      let currentPrezzo = selectedArticolo?.prezzo
      let text = ''
      let maxPrezzoFasce = null
      let show = false
      if (fasce) {
        let prezzo_riservato = currentPrezzo
        let fascia = selectedArticolo?.fasce?.[0].prezzo
        if (prezzo_riservato < fascia) {
          text = t('Prezzo riservato:')
        } else {
          listino = currentPrezzo
          currentPrezzo = fascia
          text = t('Prezzo da:')
          if (currentPrezzo != listino && utente) {
            text = t('Prezzo riservato da:')
          }
          if (utente) {
            maxPrezzoFasce = selectedArticolo?.fasce?.[selectedArticolo?.fasce.length - 1]?.prezzo
          }
        }
      } else {
        text = t('Prezzo:')
        if (currentPrezzo != listino && utente) {
          text = t('Prezzo riservato:')
          show = true
        }
      }
      return (
        <>
          <strong>{text}</strong>
          {currentPrezzo != listino &&
            utente &&
            (selectedArticolo?.prezzo < selectedArticolo?.fasce?.[0]?.prezzo || show) && (
              <span className="card__prezzo__value__full-price">{priceNotation(listino, 3)} €</span>
            )}
          <strong>{priceNotation(currentPrezzo, 3)} €</strong>
          {maxPrezzoFasce && (
            <>
              <strong>{t('a')}</strong>
              <strong>{priceNotation(maxPrezzoFasce, 3)} €</strong>
            </>
          )}
        </>
      )
    }

    return (
      <div className="prodotto">
        <div className="prodotto__header">
          <p className="prodotto__header__title">
            <Trans chiave="Codice Articolo" /> {selectedArticolo?.codice}
          </p>

          <h1 className="prodotto__header__descrizione">
            {selectedArticolo?.descrizione}
            <br />
            {selectedArticolo?.taglie && (
              <>
                <Trans chiave="Taglie" /> <strong>{selectedArticolo?.taglie.toString()}</strong>
              </>
            )}
          </h1>
        </div>
        {!service_gift?.attivo && selectedArticolo?.catalogo_instance !== null && (
          <div className="prodotto__catalogo">
            <IconPagine />
            <p>
              <Trans chiave="Pagina" /> {selectedArticolo?.pagina_catalogo}{' '}
              {/* <Trans chiave="Catalogo" />  */}
              {selectedArticolo?.catalogo_instance?.titolo &&
              selectedArticolo?.catalogo_instance?.titolo.toLowerCase().includes(t('catalogo')) ? (
                <>{selectedArticolo?.catalogo_instance?.titolo}</>
              ) : (
                <>
                  <Trans chiave="Catalogo" /> {selectedArticolo?.catalogo_instance?.titolo}
                </>
              )}
            </p>
          </div>
        )}

        <div className="prodotto__dettagli">
          <div className="prodotto__dettagli__colors">
            <p className="prodotto__dettagli__title">
              <Trans chiave="COLORE" />:{' '}
              <span className="prodotto__dettagli__title-value">{selectedColor?.nome}</span>
            </p>
            <div className="prodotto__dettagli__wrapper prodotto__dettagli__wrapper--colors">
              {prodotto.colori && filteredColori?.length > 0
                ? filteredColori?.map((colore_filtrato) => (
                    <Color
                      isActive={colore_filtrato.id === selectedColor.id ? true : false}
                      onClick={() => {
                        HandleChangeColor(
                          prodotto,
                          colore_filtrato,
                          selectedArticolo,
                          selectedTaglia
                        )
                        // HandleColorClick(colore)
                      }}
                      img={colore_filtrato?.image}
                      key={colore_filtrato.nome}
                      color={colore_filtrato.nome}
                      selectable={colore_filtrato.id != selectedColor.id}
                      size={'md'}
                      sizeMobile={'lg'}
                      colorName={colore_filtrato?.codice + ' - ' + colore_filtrato?.nome}
                    />
                  ))
                : prodotto.colori.map((colore, index) => (
                    <Color
                      isActive={colore.id === selectedColor.id ? true : false}
                      onClick={() => {
                        HandleChangeColor(prodotto, colore, selectedArticolo, selectedTaglia)
                        // HandleColorClick(colore)
                      }}
                      img={colore?.image}
                      key={index}
                      color={colore.nome}
                      selectable={colore.id != selectedColor.id}
                      size={'md'}
                      sizeMobile={'lg'}
                      colorName={colore?.codice + ' - ' + colore?.nome}
                    />
                  ))}
            </div>
          </div>
          {prodotto?.taglie && prodotto?.taglie?.length > 0 && (
            <div className="prodotto__dettagli__taglie">
              <p className="prodotto__dettagli__title">
                <Trans chiave="TAGLIA" />:{' '}
                <span className="prodotto__dettagli__title-value">{selectedTaglia?.nome}</span>
              </p>
              <div className="prodotto__dettagli__wrapper prodotto__dettagli__wrapper--taglie">
                {/*Creare il Componente-Atomo Taglia*/}
                {filteredTaglie?.map((taglia, i) => (
                  <Taglia
                    key={i}
                    taglia={taglia.nome}
                    onClick={() => HandleChangeTaglia(taglia)}
                    isActive={selectedTaglia?.id === taglia.id ? true : false}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="prodotto__prezzo__wrapper">
          {/* {selectedArticolo.con_fasce ? () : ()} */}
          {/* <strong>Prezzo riservato:</strong>
          <strong>{priceNotation(currentPrezzo, 3)} €</strong> */}
          {currentPrezzo !== null && currentPrezzo > 0 && VisualizzazionePrezzi()}
        </div>
        <div style={{ position: 'absolute' }}>
          <ModalLogin visible={popupLogin} onClose={() => setPopupLogin(false)} />
        </div>

        <div className="prodotto__buttons__wrapper">
          <Button
            label={t('Vedi Disponibilità')}
            variant="primary"
            onClick={() =>
              showTabellaSchedaProdotto(utente, service_gift, isAs400Down)
                ? scrollToTableSchedaProdotto()
                : service_gift
                ? router.push(`/myaccount/accedi?next=${router.asPath}`)
                : setPopupLogin(true)
            }
          />
          <Button
            label={t('Visualizza dettagli prodotto')}
            variant="secondary"
            onClick={() =>
              showDettagliProdotto(utente, service_gift, isAs400Down)
                ? scrollToDettagli()
                : service_gift
                ? router.push(`/myaccount/accedi?next=${router.asPath}`)
                : setPopupLogin(true)
            }
          />
        </div>

        {prodotto?.alternativo?.codice && (
          <div className="prodotto__alternativo">
            <p className="prodotto__alternativo__title">
              <Trans chiave="PRODOTTO ALTERNATIVO:" />{' '}
              <a href={prodotto?.alternativo.url}>{prodotto?.alternativo.codice}</a>
            </p>
          </div>
        )}
      </div>
    )
  }
)
