import * as React from 'react'

function SvgMenu(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--menu ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <g transform="translate(-328 -140) translate(0 120) translate(328 20)">
          <path d="M20.051 16c.522 0 .95.427.95.949v.102c0 .522-.428.949-.95.949H3.948C3.426 18 3 17.573 3 17.051v-.102c0-.522.426-.949.948-.949zm0-5c.522 0 .95.427.95.949v.102c0 .522-.428.949-.95.949H3.948C3.426 13 3 12.573 3 12.051v-.102c0-.522.426-.949.948-.949zm0-5c.522 0 .95.427.95.949v.102c0 .522-.428.949-.95.949H3.948C3.426 8 3 7.573 3 7.051v-.102C3 6.427 3.426 6 3.948 6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgMenu
