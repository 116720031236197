export const addCategoria = (categoria_id) => {
  return {
    type: 'ADD_CATEGORIA',
    categoria_id,
  }
}
export const addCategorie = (categoriaArray) => {
  return {
    type: 'ADD_CATEGORIE',
    categoriaArray,
  }
}
export const removeCategoria = (categoria_id) => {
  return {
    type: 'REMOVE_CATEGORIA',
    categoria_id,
  }
}

export const clearCategorie = () => ({
  type: 'CLEAR_CATEGORIA',
})

export const changeUrl = (url) => ({
  type: 'CHANGE_URL',
  url,
})

export default function reducer(
  state = {
    categorie: [],
    url: '',
  },
  action
) {
  switch (action.type) {
    case 'ADD_CATEGORIA': {
      let id = Number(action.categoria_id)
      let tempArray = []
      tempArray.push(action.categoria_id)
      if (!state?.categorie) {
        return Object.assign({}, state, {
          categorie: tempArray,
        })
      }
      if (state.categorie.includes(tempArray[0])) {
        return state
      }
      return Object.assign({}, state, {
        categorie: state.categorie.concat(tempArray),
      })
    }
    case 'ADD_CATEGORIE': {
      // Object.assign({}, state, {
      //   categorie: [],
      // })
      if (action.categoriaArray.length == 0 && state.categorie.length != 0) {
        return state
      }
      return Object.assign({}, state, {
        categorie: action.categoriaArray,
      })
    }
    case 'REMOVE_CATEGORIA': {
      const array = state.categorie.filter(function (item) {
        return item != action.categoria_id
      })
      return Object.assign({}, state, {
        categorie: array,
      })
    }
    case 'CLEAR_CATEGORIA': {
      return Object.assign({}, state, {
        categorie: [],
      })
    }
    case 'CHANGE_URL': {
      return Object.assign({}, state, {
        url: action.url,
      })
    }
    default: {
      return state
    }
  }
}
