import '../styles/global.sass'

import React, { useEffect, useMemo } from 'react'
import App from 'next/app'
import Custom404 from './404'
import { Layout } from '../components/index'
import NProgress from 'nprogress'
import Router, { useRouter } from 'next/router'
import { Provider, useDispatch, useSelector, useStore } from 'react-redux'
import { fetchCategorie } from '../containers/categorieapi'
import { fetchUtente } from '../containers/utente'
import { fetchLoginText } from '../containers/logintext'
import { api, API_FULL_PATH, API_PREFIX, isEmpty } from '../utils'
import { setPaginaCorrente } from '../containers/pagina_corrente'
import { wrapper } from '../store'
import { initGTMdataLayer, updateGTMDataLayer } from '../utils/gtm'
import { fetchTraduzioni } from '../containers/traduzioni'
import ListingContextProvider from '../context/ListingContext'
import QuantitaContextProvider from '../context/QuantitaContext'
import CartContextProvider from '../context/CartContext'
import { setServiceGift, fetchServiceGiftAuthenticated } from '../containers/servicegift'
import LoginServiceGift from '../components/organisms/LoginServiceGift/LoginServiceGift'
import AuthContextProvider from '../context/AuthContext'
import Notifica from '../components/atoms/Notifica/Notifica'
import FromAdminLayout from '../components/template/FromAdminLayout/FromAdminLayout'
import { isPdf } from '../utils/pdf'
import { fetchPrivacy } from '../containers/privacy'
import * as Sentry from '@sentry/browser'
import SgScript from '../components/atoms/SgScript/SgScript'
import Head from 'next/head'
import { CURRENTSITE } from '../utils/endpoint'
import parse from 'html-react-parser'

NProgress.configure({ showSpinner: false })

const fallbackLocale = 'en'

const Greenboxshop = ({ Component, pageProps, store, err, apiBaseUrl }) => {
  const router = useRouter()
  const storage = useStore((state) => state)
  const categorieapi = storage.getState().categorieapi
  const isStaff = storage.getState().utente.utente?.is_staff
  const { service_gift, fetched, is_user_authenticated } = useSelector((state) => state.servicegift)
  const { utente, fetched: fetchedUtente } = useSelector((state) => state.utente)
  const isLoggedServiceGift = is_user_authenticated || false
  const { paginaCorrente } = useSelector((state) => state.paginaCorrente)
  const dispatch = useDispatch()

  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeComplete', () => NProgress.done())
  Router.events.on('routeChangeError', () => NProgress.done())

  useEffect(() => {
    if (!isPdf(router)) {
      initGTMdataLayer()
    }

    storage.dispatch(fetchPrivacy(router.locale, apiBaseUrl))
    storage.dispatch(fetchUtente(router.locale, apiBaseUrl))
    storage.dispatch(fetchLoginText(apiBaseUrl, router.locale))
  }, [])

  useEffect(() => {
    if (utente?.email) {
      Sentry.setUser({ email: utente.email, username: utente.username, id: utente.id })
    }
  }, [utente])

  useEffect(() => {
    if (process.browser && !isEmpty(paginaCorrente) && !isPdf(router) && fetchedUtente) {
      setTimeout(updateGTMDataLayer(paginaCorrente, utente, router, service_gift), 500)
    }
  }, [paginaCorrente, fetchedUtente])

  const getLanguageData = async () => {
    if (categorieapi.categorieapi.length === 0 || router.locale !== categorieapi.lang) {
      await storage.dispatch(fetchCategorie(isStaff, router.locale, apiBaseUrl))
    }
  }

  useEffect(() => {
    getLanguageData()
  }, [router.locale])

  useEffect(() => {
    if (service_gift && service_gift?.attivo && !service_gift.pubblico) {
      dispatch(fetchServiceGiftAuthenticated(apiBaseUrl))
    }
    if (isEmpty(pageProps)) {
      dispatch(setPaginaCorrente({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      dispatch(setPaginaCorrente(pageProps))
    }
  }, [router.asPath])

  let DefaultLayout = Component.Layout || Layout
  if (router.query.from_admin) {
    DefaultLayout = FromAdminLayout
  }
  const isNotFound = useMemo(() => pageProps.statusCode && pageProps.statusCode === 404, [
    pageProps,
  ])

  const edit = store?.getState()?.toolbar?.edit

  if (service_gift && !service_gift.attivo) {
    return <Custom404 />
  }

  if (
    (service_gift && service_gift?.attivo && !service_gift?.lingue) ||
    service_gift?.lingue?.length === 0
  ) {
    return <Custom404 />
  }

  if (
    service_gift &&
    !service_gift.premium &&
    (router.pathname.startsWith('/myaccount') || router.pathname.startsWith('/cart'))
  ) {
    return <Custom404 />
  }

  const logoImage =
    apiBaseUrl.includes('sipec') || process.env.NODE_ENV === 'development' ? 'logosipec' : ''

  if (service_gift && service_gift?.attivo && !service_gift.pubblico && !isLoggedServiceGift) {
    if (!fetched) {
      return <></>
    }
    return <LoginServiceGift logo={logoImage} />
  }
  return (
    <Provider store={storage}>
      <AuthContextProvider>
        <CartContextProvider>
          <ListingContextProvider>
            <QuantitaContextProvider>
              {fetched && !service_gift && (
                <Head>
                  <meta
                    name="google-site-verification"
                    content="ktXu2usoUTCfqwXFXOhHOWrbDvojpRAh3-ohQULpb-I"
                  />
                </Head>
              )}
              {fetched && service_gift?.scripts_head && (
                <Head>{parse(service_gift?.scripts_head)}</Head>
              )}
              <DefaultLayout
                {...pageProps}
                logoImageProps={logoImage}
                apiBaseUrl={apiBaseUrl}
                loginRequired={Component.loginRequired || false}
              >
                {isNotFound ? <Custom404 /> : <Component {...pageProps} err={err} key={edit} />}
              </DefaultLayout>
              <Notifica />
              <SgScript />
            </QuantitaContextProvider>
          </ListingContextProvider>
        </CartContextProvider>
      </AuthContextProvider>
    </Provider>
  )
}

Greenboxshop.getInitialProps = async (app) => {
  const { locale } = app?.router
  const { store } = app?.ctx

  let apiBaseUrl = ''
  if (app.ctx.req && app.ctx.req.headers.host == 'host.docker.internal:3000') {
    apiBaseUrl = API_FULL_PATH
  } else if (process.env.NODE_ENV == 'development') {
    apiBaseUrl = API_FULL_PATH
  } else if (app.ctx.req && app.ctx.req.headers.host) {
    apiBaseUrl = 'https://' + app.ctx.req.headers.host.replace(':8000', '') + API_PREFIX
  }

  // apiBaseUrl = 'http://localhost:9999/d/api/' // da decommentare per test della build standalone in locale

  if (apiBaseUrl != '') {
    api.defaults.baseURL = apiBaseUrl
  }

  api.defaults.xsrfCookieName = 'csrftoken'
  api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

  const appProps = await App.getInitialProps(app)

  // Recupero le traduzioni server-side
  const isServer = Boolean(app.ctx.req)
  await store.dispatch(fetchTraduzioni(isServer, locale, apiBaseUrl))

  const response = await api.get(`${CURRENTSITE}`)
  await store.dispatch(setServiceGift(response))
  const data = await response.data
  const isServicegift = data?.servicegift ? true : false

  // Recupero lingue attive per sg
  const sgLocales = data?.servicegift?.lingue || []

  let shouldRedirect = false
  let redirectLocale = locale

  // Se SG
  if (isServicegift) {
    // Se locale non è attivo per sg, redirect to fallback o prima lingua disponibile
    if (sgLocales.length > 0 && !sgLocales.includes(locale)) {
      const sgDefaultLocale = sgLocales.includes(fallbackLocale) ? fallbackLocale : sgLocales[0]
      redirectLocale = sgDefaultLocale
      shouldRedirect = true
    }
  }

  // // Per log: richieste che non hanno "en" in header accept-language
  // const localeHeader = app.ctx.req?.headers['accept-language'] || ''
  // if (
  //   app.router.asPath === '/' &&
  //   !localeHeader.includes(fallbackLocale) &&
  //   !localeHeader.includes('it')
  // ) {
  //   Sentry.addBreadcrumb({
  //     category: 'message',
  //     message: localeHeader,
  //     level: Sentry.Severity.Info,
  //   })
  //   Sentry.captureMessage("Locale 'en' not in header accept-language")
  // }

  if (shouldRedirect) {
    app.ctx.res.writeHead(301, {
      Location: `/${redirectLocale}${app.router.asPath}`,
    })
    app.ctx.res.end()
  }

  return { ...appProps, apiBaseUrl, locale }
}

export default wrapper.withRedux(Greenboxshop)
