import React from 'react'
import { Download } from '../../atoms/NewIcons'

const imageFormats = ['svg', 'png', 'jpg', 'jpeg']

const AnteprimaLogo = ({ file, src, filename, className }) => {
  const isImage = file
    ? file.type.toString().toLowerCase().split('/')?.[0] === 'image'
    : filename
    ? imageFormats.includes(filename.toString().toLowerCase().split('.')?.[1] || '')
    : false

  if (file || src) {
    return isImage ? (
      <a
        href={file ? URL.createObjectURL(file) : src}
        {...(file ? { download: filename } : { target: '_blank' })}
      >
        <img src={file ? URL.createObjectURL(file) : src} className={className} />
      </a>
    ) : (
      <a
        href={file ? URL.createObjectURL(file) : src}
        download={filename}
        {...(src ? { target: '_blank' } : {})}
        className={className}
      >
        <span>
          <Download width={30} height={30} />
        </span>
      </a>
    )
  }

  return null
}

export default AnteprimaLogo
