import { PAGEBUILDER } from './endpoint'
import axios from 'axios'
import getConfig from 'next/config'

export const DOMAIN = 'https://www.sipec.com'

const { publicRuntimeConfig } = getConfig()

export const API_HOST = publicRuntimeConfig.API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`
export const JWT_TOKEN_REFRESH_ENDPOINT = `jwt/token/refresh/`
export const JWT_GET_TOKEN_ENDPOINT = `jwt/token/`
export const JWT_LOCAL_STORAGE_TOKENS_KEY = 'auth-tokens-development'

if (process.env.NODE_ENV == 'development') {
  require('axios-debug-log')
}

export const baseURL =
  process.env.NODE_ENV == 'development' ||
  API_HOST == 'http://localhost:9999' ||
  API_HOST == 'http://host.docker.internal:9999'
    ? API_FULL_PATH
    : API_PREFIX

export const api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
})

export const toggle = (array, value) => {
  const index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

String.prototype.capitalize = function () {
  return this.toLowerCase().charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.toGTMFormat = function () {
  return this.toLowerCase()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/[\s]/g, '_')
}

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getPlaceholder = async (params) => {
  const key = `key=${params.key}`
  const content_type_id = params.content_type_id ? `&content_type_id=${params.content_type_id}` : ''
  const object_id = params.object_id ? `&object_id=${params.object_id}` : ''
  const lang = params.lang ? `&lang=${params.lang}` : ''

  const is_pdf = params.isPdf ? `&is_pdf=true` : ''

  const edit_mode_active = params.utente?.edit ? `&edit_mode_active=true` : ''

  const response = await api.get(
    `${PAGEBUILDER}?${key}${content_type_id}${object_id}${lang}${edit_mode_active}${is_pdf}`,
    { headers: params.headers }
  )
  const placeholder = response.data

  return placeholder
}

export const initServiceWorker = () => {
  if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    navigator.serviceWorker
      .register('/sw.js')
      .catch((err) => console.error('Service worker registration failed', err))
  }
}

export const DEFAULT = 'default'

export const hasPagePerm = (props, utente) => {
  if ('pagina' in props || 'page' in props) {
    const pagina = props.pagina || props.page

    // utente non loggato
    if (!utente) return false
    // utente staff
    else if (utente.is_staff) return true
  } else return true
}

// non ancora usato per whilteist domain image
function imagesLoader({ root, src, width, quality }) {
  if (process.env.NODE_ENV !== 'production') {
    const missingValues = []

    // these should always be provided but make sure they are
    if (!src) missingValues.push('src')
    if (!width) missingValues.push('width')

    if (missingValues.length > 0) {
      throw new Error(
        `Next Image Optimization requires ${missingValues.join(
          ', '
        )} to be provided. Make sure you pass them as props to the \`next/image\` component. Received: ${JSON.stringify(
          { src, width, quality }
        )}`
      )
    }

    if (src.startsWith('//')) {
      throw new Error(
        `Failed to parse src "${src}" on \`next/image\`, protocol-relative URL (//) must be changed to an absolute URL (http:// or https://)`
      )
    }

    if (!src.startsWith('/') && configDomains) {
      let parsedSrc
      try {
        parsedSrc = new URL(src)
      } catch (err) {
        console.error(err)
        throw new Error(
          `Failed to parse src "${src}" on \`next/image\`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)`
        )
      }

      // nextJs images permette solo domini e non whitelist e quindi avremmo dovuto specificare ogni dominio di on-gadget
      if (
        !configDomains.includes(parsedSrc.hostname) &&
        (!parsedSrc.hostname.endsWith('.on-gadget.com') ||
          !parsedSrc.hostname.endsWith('.on-gadget.webranking.biz'))
      ) {
        throw new Error(
          `Invalid src prop (${src}) on \`next/image\`, hostname "${parsedSrc.hostname}" is not configured under images in your \`next.config.js\`\n` +
            `See more info: https://err.sh/next.js/next-image-unconfigured-host`
        )
      }
    }
  }

  return `${root}?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`
}

export const API_RIVENDITORI_PREFIX = '/d/api-rivenditori/'
export const API_RIVENDITORI_FULL_PATH = `${API_HOST}${API_RIVENDITORI_PREFIX}`
export const API_RIVENDITORI_HOST =
  process.env.NODE_ENV == 'development' || API_HOST == 'http://localhost:9999'
    ? API_RIVENDITORI_FULL_PATH
    : API_RIVENDITORI_PREFIX
