import * as React from 'react'
import FlagIt from './Flags/FlagIt.js'
import FlagEn from './Flags/FlagEn.js'
import FlagFr from './Flags/FlagFr.js'
import FlagEs from './Flags/FlagEs.js'
import FlagDe from './Flags/FlagDe.js'

function Flag({ locale }) {
  return (
    <>
      {locale == 'it' && <FlagIt />}
      {locale == 'en' && <FlagEn />}
      {locale == 'fr' && <FlagFr />}
      {locale == 'es' && <FlagEs />}
      {locale == 'de' && <FlagDe />}
    </>
  )
}

export default Flag
