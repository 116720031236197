import React from 'react'
import dynamic from 'next/dynamic'
import parse from 'html-react-parser'

/* const EmbeddedItem = dynamic(() => import('./EmbeddedItem'))
const PagebuilderSlider = dynamic(() => import('./PagebuilderSlider')) */

// DOCS: https://github.com/remarkablemark/html-react-parser

const PlaceholderContent = React.memo(({ content }) => {
  return parse(content)
})

export default PlaceholderContent
