import React, { useState } from 'react'
import { Color } from '../../index'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
import { GETPRODOTTOVALUES } from '../../../utils/endpoint'
import { api } from '../../../utils'
import { priceNotation } from '../../../utils/safe'
SwiperCore.use([Navigation, Scrollbar, A11y])

const ProdottoCard = ({ prodotto }) => {
  const [selectedItem, setSelectedItem] = useState(prodotto.articoli_colore[0])
  const [coloriMaster, setColoriMaster] = useState(prodotto.articoli_colore)
  const [selectedItemValues, setSelectedItemValues] = useState({
    disponibilita: 2000,
    prezzo: 2.39,
  })
  const [lastSelected, setLastSelected] = useState(prodotto.articoli_colore[0])
  const [lastSelectedValues, setLastSelectedValues] = useState({
    disponibilita: 2000,
    prezzo: 2.39,
  })

  const FetchItemValues = () => {
    const params = selectedItem
    api.get(GETPRODOTTOVALUES, { params }).then((response) => {
      setSelectedItemValues(response.data)
    })
  }

  const OnClick = (element) => {
    FetchItemValues()
    setSelectedItem(element)
    setLastSelected(element)
  }
  const MouseEnter = (element) => {
    FetchItemValues()
    setSelectedItem(element)
  }
  const MouseLeave = () => {
    setSelectedItem(lastSelected)
    setSelectedItemValues(lastSelectedValues)
  }

  let default_pro = selectedItem
  let titolo = 'Nessun titolo'
  let codice = null
  if (default_pro) {
    if (default_pro.titolo) {
      titolo = default_pro.titolo
    }
    if (default_pro.codice) {
      codice = default_pro.codice
    }
  } else {
    titolo = prodotto.codice
    codice = null
  }
  return (
    <>
      <div className="prodotto_card__container">
        <div className="prodotto_card__top_elements">
          <div className="prodotto_card__top_elements__brand">
            <img src="https://via.placeholder.com/63x24"></img>
          </div>
          <div className="prodotto_card__top_elements__wishlist">
            <img src="https://via.placeholder.com/24"></img>
          </div>
        </div>
        <div className="prodotto_card__img">
          <img src="https://via.placeholder.com/150"></img>
        </div>
        <div className="prodotto_card__title">
          <p>
            {titolo}-{codice}
          </p>
        </div>
        <div className="prodotto_card__colors">
          <Swiper
            spaceBetween={0}
            slidesPerView={3}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
            {coloriMaster.map((colore) => {
              return (
                <SwiperSlide>
                  <Color
                    color={colore}
                    isActive={colore.codice == selectedItem.codice ? true : false}
                    onClick={() => OnClick(colore)}
                    MouseEnter={() => MouseEnter(colore)}
                    MouseLeave={() => MouseLeave(colore)}
                    color={'black'}
                  />
                </SwiperSlide>
              )
              // return <li>{colore.codice}</li>
            })}
          </Swiper>
        </div>
        <div className="prodotto_card__stock">{selectedItemValues.disponibilita} pezzi</div>
        <div className="prodotto_card__price">
          <p className="prodotto_card__price__text">Prezzo riservato :</p>
          <p className="prodotto_card__price__value">
            {priceNotation(selectedItemValues?.prezzo, 3)} €
          </p>
        </div>
      </div>
    </>
  )
}
export default ProdottoCard
