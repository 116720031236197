import { combineReducers } from 'redux'
import toolbar from './containers/toolbar'
import traduzioni from './containers/traduzioni'
import utente from './containers/utente'
import paginaCorrente from './containers/pagina_corrente'
import categorie from './containers/categorie'
import categorieapi from './containers/categorieapi'
import servicegift from './containers/servicegift'
import menuHeaderAndFooter from './containers/menuHeaderAndFooter'
import menuBanner from './containers/menuBanner'
import logintext from './containers/logintext'
import creaofferta from './containers/crea-offerta'
import privacy from './containers/privacy'

export default (reducer) => (state, action) => {
  switch (action.type) {
    case 'PAGE':
      return { ...state, page: action.payload }
    default:
      const nextState = combineReducers({
        traduzioni,
        utente,
        toolbar,
        paginaCorrente,
        categorie,
        categorieapi,
        servicegift,
        menuHeaderAndFooter,
        menuBanner,
        logintext,
        creaofferta,
        privacy,
      })(state, action)

      if (action?.payload?.traduzioni) nextState.traduzioni = action?.payload?.traduzioni // utilizza sempre le traduzioni calcolate server-side
      if (action?.payload?.servicegift)
        nextState.servicegift = {
          ...action?.payload?.servicegift,
          is_user_authenticated:
            nextState?.servicegift?.is_user_authenticated ||
            action?.payload?.servicegift?.is_user_authenticated,
        } // utilizza sempre il servicegift calcolato server-side ma preserva is_user_authenticated client-side

      return nextState
  }
}
