import { Footer } from '../../molecules/index'
import React, { useState, useEffect } from 'react'
import Head from 'next/head'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { fetchMenus } from '../../../containers/menuHeaderAndFooter'
import dynamic from 'next/dynamic'
import NewHeader from '../../molecules/NewHeader/NewHeader'
import MainMenu from './menu/MainMenu'
import { fetchBanner } from '../../../containers/menuBanner'
import Banner from '../../atoms/Banner/Banner'
import LoadingSvg from '../../atoms/Loading/Loading'
const Toolbar = dynamic(() => import('../../molecules/Toolbar/Toolbar'))

const Layout = (props) => {
  const { logoImageProps, loginRequired } = props
  const { toolbarItem, toolbarPage } = props.children.props
  const toolbarProps = { toolbarItem, toolbarPage }
  const page = props.children.props.page || props.children.props.pagina

  const { service_gift } = useSelector((state) => state.servicegift)
  const { utente, fetching, fetched } = useSelector((state) => state.utente)
  const menusData = useSelector((state) => state.menuHeaderAndFooter.menusData)
  const bannerData = useSelector((state) => state.menuBanner.bannerData)

  const [openMenuMobile, setOpenMenuMobile] = useState(false)
  const [logoImage, setLogoImage] = useState(logoImageProps)
  const [bannerOpen, setBannerOpen] = useState(true) // TODO: parametro in cookie per memorizzare la chiusura da parte dell'utente?

  const dispatch = useDispatch()
  const router = useRouter()

  const loadHeaderAndFooterDataOnlyOnce = () => {
    dispatch(fetchMenus(router.locale))
  }
  const loadBannerDataOnlyOnce = () => {
    dispatch(fetchBanner(router.locale))
  }

  useEffect(() => {
    service_gift && service_gift?.logo_url && logoImage !== 'logosipec'
      ? setLogoImage(service_gift?.logo_url)
      : ''
  }, [service_gift])

  useEffect(() => {
    loadHeaderAndFooterDataOnlyOnce()
    loadBannerDataOnlyOnce()
  }, [])

  const toolbar = utente &&
    (utente.is_staff || (utente.is_staff_sg && service_gift) || utente.is_impersonate) && (
      <Toolbar {...toolbarProps} />
    )

  // TODO - impostare le condizioni per cui sia semplificato l'header
  const headerSemplificato = false

  if (loginRequired && fetched && (!utente || utente?.guest)) {
    // TODO: check su guest
    window !== undefined && (window.location.href = '/myaccount/accedi/')
  }

  return (
    <>
      <Head>
        {!service_gift ? (
          <link rel="icon" href="/favicon_sipec.svg" />
        ) : service_gift.favicon ? (
          <link rel="icon" href={service_gift.favicon} />
        ) : (
          <></>
        )}
      </Head>
      {toolbar ? toolbar : <></>}
      {bannerData?.description && bannerOpen && (
        <Banner
          placeholder={bannerData.description}
          link={bannerData.final_url}
          linkText={bannerData.cta}
          onClose={() => setBannerOpen(false)}
        />
      )}
      <NewHeader
        apiBaseUrl={props?.apiBaseUrl}
        logoImage={logoImage}
        service_gift={service_gift}
        page={page}
        utente={utente}
        semplificato={headerSemplificato}
        headerData={menusData && 'header' in menusData ? menusData.header : []}
        setOpenMenuMobile={setOpenMenuMobile}
      >
        <MainMenu
          openMenuMobile={openMenuMobile}
          setOpenMenuMobile={setOpenMenuMobile}
          headerData={menusData && 'header' in menusData ? menusData.header : []}
        />
      </NewHeader>
      <div
        className={
          'page-body' +
          (toolbar ? ' page-body--toolbar' : '') +
          (headerSemplificato ? ' page-body--header-semplificato' : '') +
          (service_gift ? ' page-body--service-gift' : '')
        }
      >
        {loginRequired && (fetching || !utente || utente?.guest) ? <LoadingSvg /> : props.children}
        <Footer
          isServiceGift={service_gift}
          footerData={menusData && 'footer' in menusData ? menusData.footer : []}
          logo={menusData && 'logo' in menusData ? menusData.logo : {}}
          azienda={menusData && 'azienda' in menusData ? menusData.azienda : {}}
        />
      </div>
    </>
  )
}

export default Layout
