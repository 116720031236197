import * as React from 'react'

function SvgXml(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={'icon icon--xml ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M23.203 2a2 2 0 011.498.675l8.797 9.939c.323.366.502.837.502 1.326v18.289C34 34.859 31.904 37 29.308 37H12.692C10.096 37 8 34.86 8 32.229V6.77C8 4.141 10.096 2 12.692 2zm-1.141 2h-9.37C11.21 4 10 5.236 10 6.771V32.23c0 1.534 1.21 2.77 2.692 2.77h16.616C30.79 35 32 33.764 32 32.229V14.03h-5.937a4 4 0 01-3.995-3.8l-.005-.2L22.062 4zm8.249 8.031l-6.249-7.06v5.06c0 1.054.816 1.918 1.851 1.995l.15.005h4.248z" />
        <g transform="translate(5 18.75)">
          <rect width={22.5} height={10} rx={1} />
          <path
            fill="#FFF"
            d="M3.935 7.5L5.18 5.475 6.426 7.5h1.296l-1.81-2.699 1.682-2.655H6.37L5.239 4.072 4.085 2.146H2.833l1.663 2.593L2.719 7.5h1.216zm5.725 0V5.01c0-.286-.023-.856-.07-1.71h.034l1.289 4.2h1.04l1.38-4.193h.033c-.03.674-.046 1.089-.05 1.245-.003.156-.005.294-.005.414V7.5h1.059V2.146h-1.546L11.48 6.24h-.022l-1.267-4.094H8.646V7.5H9.66zm9.614 0v-.938h-2.171V2.146h-1.136V7.5h3.307z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgXml
