import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = (props) => (
  <ContentLoader
    style={{ marginRight: 20 }}
    speed={2}
    width={props.vista2 ? 397 : 265}
    height={props.vista2 ? 512 : 440}
    viewBox={props.vista2 ? '0 0 397 512' : '0 0 225 440'}
    backgroundColor="#f1f2f4"
    foregroundColor="#e0e2e6"
    {...props}
  >
    <rect
      x="17"
      y={props.vista2 ? '345' : '302'}
      rx="0"
      ry="0"
      width={props.vista2 ? '347' : '225'}
      height="33"
    />
    <rect
      x="18"
      y="31"
      rx="0"
      ry="0"
      width={props.vista2 ? '347' : '225'}
      height={props.vista2 ? '281' : '241'}
    />
  </ContentLoader>
)

export default MyLoader
