import * as React from 'react'

function SvgGridA3V1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--grid-a3-v1 ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M9 13a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4a2 2 0 012-2h5zm11 0a2 2 0 012 2v4a2 2 0 01-2 2h-5a2 2 0 01-2-2v-4a2 2 0 012-2h5zM8 15H5a1 1 0 00-.993.883L4 16v2a1 1 0 00.883.993L5 19h3a1 1 0 00.993-.883L9 18v-2a1 1 0 00-1-1zm11 0h-3a1 1 0 00-.993.883L15 16v2a1 1 0 00.883.993L16 19h3a1 1 0 00.993-.883L20 18v-2a1 1 0 00-1-1zM9 3a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5zm11 0a2 2 0 012 2v4a2 2 0 01-2 2h-5a2 2 0 01-2-2V5a2 2 0 012-2h5zM8 5H5a1 1 0 00-.993.883L4 6v2a1 1 0 00.883.993L5 9h3a1 1 0 00.993-.883L9 8V6a1 1 0 00-1-1zm11 0h-3a1 1 0 00-.993.883L15 6v2a1 1 0 00.883.993L16 9h3a1 1 0 00.993-.883L20 8V6a1 1 0 00-1-1z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGridA3V1
