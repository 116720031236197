import * as React from 'react'

function SvgArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--arrow-left ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="arrow-right_svg__a"
          d="M4.167 10.833h9.887l-3.027 3.634a.833.833 0 101.281 1.066l4.166-5c.033-.039.049-.085.073-.128.02-.035.044-.065.059-.103a.835.835 0 00.06-.299v-.006a.835.835 0 00-.06-.299c-.015-.038-.04-.068-.06-.103-.023-.043-.04-.09-.072-.128l-4.167-5a.831.831 0 00-1.174-.107.833.833 0 00-.106 1.173l3.027 3.634H4.167a.834.834 0 100 1.666"
        />
      </defs>
      <use xlinkHref="#arrow-right_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgArrowRight
