import React from 'react'
import Select, { components } from 'react-select'
import { ChevronDown } from '../NewIcons'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  )
}

const Dropdown = (props) => {
  return (
    <Select
      className={`dropdown`}
      classNamePrefix="dropdown"
      closeMenuOnSelect
      isClearable={false}
      isSearchable={false}
      isDisabled={props.isDisabled || false}
      isMulti={false}
      instanceId={props.instanceId}
      name={props.instanceId}
      placeholder={props.placeholder}
      components={{ DropdownIndicator }}
      onChange={props.onChange}
      options={props.options}
      value={props.value}
    />
  )
}

export default Dropdown
