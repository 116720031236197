import { CATEGORIE } from '../utils/endpoint'
import { api } from '../utils'

// se client-side e non utente staff prendo da localStorage
export const fetchCategorie = (isCachable, lang = 'it', apiBaseUrl = '') => {
  let payload = null
  //TODO verificare che questo if serva, messo per evitare errori in debug
  if (typeof window !== 'undefined') {
    if (isCachable && localStorage.getItem('categorieapi')) {
      payload = { data: JSON.parse(localStorage.getItem('categorieapi')) }
    } else {
      payload = api.get(apiBaseUrl + CATEGORIE, { params: { lang } })
    }
  } else {
    payload = api.get(apiBaseUrl + CATEGORIE, { params: { lang } })
  }

  return {
    type: 'FETCH_CATEGORIE',
    payload,
  }
}

export default function reducer(
  state = {
    categorieapi: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_CATEGORIE_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_CATEGORIE_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_CATEGORIE_FULFILLED': {
      const lang = action.payload.config.params.lang
      const categorieapi = action.payload.data

      if (typeof window !== 'undefined')
        localStorage.setItem('categorieapi', JSON.stringify(action.payload.data))

      return {
        ...state,
        fetching: false,
        fetched: true,
        categorieapi,
        lang,
      }
    }

    default: {
      return state
    }
  }
}
