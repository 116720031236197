import * as React from 'react'

const FlagFr = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 2">
      <rect width="3" height="2" fill="#002395" />
      <rect width="2" height="2" x="1" fill="#fff" />
      <rect width="1" height="2" x="2" fill="#ED2939" />
    </svg>
  )
}

export default FlagFr
