import React from 'react'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'
import { ArrowRight } from '../../atoms/NewIcons'
import Trans from '../../atoms/Trans/Trans'
import useTrans from '../../../hooks/useTrans'
import Button from '../../atoms/Button/Button'
import TrendButton from '../../atoms/TrendButton/TrendButton'

const AbbigliamentoMenu = ({ categorie, trends, setCurrentItem, categoriaParent }) => {
  const t = useTrans()
  const Categoria = (categoria) => {
    return (
      categoria.categoria.numero_prodotti > 0 && (
        <div className="abbigliamento__menu__categoria">
          <div className="abbigliamento__menu__categoria__image-box">
            {categoria?.categoria?.main_image_url && (
              <MyImage
                src={categoria.categoria.main_image_url}
                alt={categoria.categoria.nome}
                width={48}
                height={48}
              />
            )}
          </div>
          {categoria?.categoria?.url && (
            <Link href={categoria.categoria.url}>
              <a
                className="abbigliamento__menu__categoria__title"
                onClick={() => setCurrentItem(null)}
              >
                {categoria.categoria.nome}
                <span className="abbigliamento__menu__categoria__num-prod">
                  ({categoria.categoria.numero_prodotti})
                </span>
              </a>
            </Link>
          )}
        </div>
      )
    )
  }

  return (
    <div className="submenu abbigliamento__menu" onMouseLeave={() => setCurrentItem(null)}>
      <div className="w-container">
        <div className="abbigliamento__menu__content">
          <div className="abbigliamento__menu__content__categorie">
            {categorie
              .filter((f) => f.in_menu)
              .map((item, index) => (
                <Categoria categoria={item} key={index} />
              ))}
            {/* {Array.from(Array(11).keys()).map(() => (
              <Categoria />
            ))} */}
          </div>
          {trends?.length > 0 ? (
            <div className="abbigliamento__menu__content__trend">
              <strong>
                <Trans chiave="Sfoglia per trend" />
              </strong>
              <div className="abbigliamento__menu__content__trend__wrapper">
                {trends.map((trend) => (
                  <TrendButton label={trend.nome} url={trend.url} key={trend.id} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
        {categoriaParent && categoriaParent.url && (
          <Link href={categoriaParent.url}>
            <a className="submenu__viewall">
              <Button
                variant="ghost"
                size="large"
                label={t('Vedi tutti')}
                icon={<ArrowRight />}
                iconPos="right"
              />
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

export default AbbigliamentoMenu
