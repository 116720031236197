import React from 'react'
import useTrans from '../../../hooks/useTrans'
import { useRouter } from 'next/router'
import { Profile } from '../../atoms/NewIcons'
import { DropdownMenu } from '../../template/CataloghiTemplate/CataloghiTemplate'
import useAuth from '../../../hooks/useAuth'
import { useSelector } from 'react-redux'

const UtenteDropdown = () => {
  const { service_gift } = useSelector((state) => state?.servicegift)
  const { utente } = useSelector((state) => state?.utente)
  const { Logout } = useAuth()
  const t = useTrans()
  const router = useRouter()
  const Options = [
    {
      label: t('Il mio account'),
      type: 'link',
      link: '/myaccount/',
      sg: true,
    },
    {
      label: t('I miei dati'),
      type: 'link',
      link: '/myaccount/profilo/personale/',
      sg: true,
    },
    utente.permessi_frontend.find((value) => value === 'visualizzare_ordini') && {
      label: t('I miei ordini'),
      type: 'link',
      link: '/myaccount/documenti/ordini/',
      sg: true,
    },
    // {
    //   label: t('Comunicazioni'),
    //   type: 'link',
    //   link: '/myaccount/comunicazioni/',
    // },
    // {
    //   label: t('Savati per dopo'),
    //   type: 'link',
    //   link: '/myaccount/salvati/',
    //   sg: true,
    // },
    {
      label: t('Le mie offerte'),
      type: 'link',
      link: '/myaccount/offerte/salvate/',
    },
    {
      label: t('Listini e condizioni'),
      type: 'link',
      link: '/myaccount/download/altridocumenti',
    },
    {
      label: t('Database'),
      type: 'link',
      link: '/myaccount/download/database',
    },
    {
      separatore: true,
      label: t('Ordine veloce'),
      type: 'link',
      link: '/strumenti-servizi/ordine-veloce/',
    },
    {
      label: t('Ordini Taylor Made'),
      type: 'link',
      link: '/strumenti-servizi/tailor-made/',
    },
    {
      label: t('Logout'),
      onClick: async () => await Logout(),
      sg: true,
      separatore: service_gift ? true : false,
    },
  ]

  return (
    <DropdownMenu
      IconClass={'header__main__icon'}
      icon={<Profile width={26} />}
      Options={Options.filter((item) => (service_gift ? item?.sg === true : item))}
    />
  )
}

export default UtenteDropdown
