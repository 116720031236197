import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'
import {
  Profile,
  CartNormal,
  ChevronDown,
  Menu,
  LogoSipec,
  WishlistLine,
} from '../../atoms/NewIcons'
import SearchBar from '../../organisms/SearchBar/SearchBar'
import { useRouter } from 'next/router'
import { CartContext } from '../../../context/CartContext'
import Flag from '../../atoms/Flag/Flag'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import SideCart from '../SideCart/SideCart'
import LoadingSpinner from '../../atoms/Loading/Loading'
import Trans from '../../atoms/Trans/Trans'
import ModalLogin from '../../organisms/ModalLogin/ModalLogin'
import { useDispatch, useSelector } from 'react-redux'
import CondizioneServizio from '../../organisms/CondizioniServizio/CondizioneServizio'
import UtenteDropdown from '../UtenteDropdown/UtenteDropdown'
import { isCarrelloDisponibile } from '../../../utils/servicegift'

const NewHeader = ({
  utente,
  semplificato,
  children,
  headerData,
  service_gift,
  setOpenMenuMobile,
  logoImage,
  apiBaseUrl,
}) => {
  const router = useRouter()
  const { width } = useWindowDimensions()
  const { pathname, query } = useRouter()
  const { cartMain, isLoadingCart, isLoadingCount, cartCount, getCart, getCount } = useContext(
    CartContext
  )
  const isAs400Down = useSelector((state) => state.servicegift.as400_down)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openSideCart, setOpenSideCart] = useState(false)
  const [openSearchResults, setOpenSearchResults] = useState(false)
  const [popupLogin, setPopupLogin] = useState(false)
  const [windowScroll, setWindowScroll] = useState(0)

  useEffect(() => {
    if (isCarrelloDisponibile(utente, service_gift) && cartCount == undefined) {
      getCount()
    }
  }, [utente, service_gift])

  useEffect(() => {
    if (pathname.includes('myaccount')) {
      setPopupLogin(false)
    }
  }, [pathname])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [windowScroll])

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
    if (window.scrollY < windowScroll || windowScroll == 0) {
      document.body.classList.remove('w-scrolldown')
    } else {
      document.body.classList.add('w-scrolldown')
    }
    setWindowScroll(window.scrollY)
  }

  const CartButton = () => {
    return (
      <span className="header__main__cart-wrapper">
        <CartNormal classes="header__main__icon" />
        {isLoadingCount ? (
          <span className="header__main__cart-overlay header__main__cart-overlay--loading">
            <LoadingSpinner width={20} height={20} />
          </span>
        ) : (
          <>
            {utente && cartCount ? (
              <span className="header__main__cart-overlay header__main__cart-overlay--notification">
                {cartCount}
              </span>
            ) : null}
          </>
        )}
      </span>
    )
  }

  const DropdownLanguage = ({ variant }) => {
    return (
      <div
        className={
          'dropdown__language' +
          (openDropdown ? ' dropdown__language--open' : '') +
          (variant ? ` dropdown__language--${variant}` : '')
        }
      >
        <button
          role="button"
          className="dropdown__language__trigger"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <span className="dropdown__language__trigger__flag">
            <Flag locale={router.locale} />
          </span>
          <span className="dropdown__language__trigger__label">{router.locale}</span>
          <ChevronDown className="dropdown__language__arrow" />
        </button>

        {openDropdown && (
          <div className="dropdown__language__options">
            {!service_gift
              ? router.locales.map((locale) => (
                  <p
                    onClick={() => {
                      router
                        .push(
                          pathname === '/' && router.defaultLocale === locale
                            ? `/${locale}`
                            : { pathname, query },
                          undefined,
                          { locale: locale, shallow: true }
                        )
                        .then((res) => router.reload())
                    }}
                    key={locale}
                    className={
                      'dropdown__language__option' +
                      (router.locale == locale ? ' dropdown__language__option--selected' : '')
                    }
                  >
                    <span className="dropdown__language__option__flag">
                      <Flag locale={locale} />
                    </span>
                    <span className="dropdown__language__option__label">{locale}</span>
                  </p>
                ))
              : service_gift?.attivo &&
                service_gift?.lingue?.map((locale) => (
                  <p
                    onClick={() => {
                      router
                        .push(
                          pathname === '/' && router.defaultLocale === locale
                            ? `/${locale}`
                            : { pathname, query },
                          undefined,
                          { locale: locale, shallow: true }
                        )
                        .then((res) => router.reload())
                    }}
                    key={locale}
                    className={
                      'dropdown__language__option' +
                      (router.locale == locale ? ' dropdown__language__option--selected' : '')
                    }
                  >
                    <span className="dropdown__language__option__flag">
                      <Flag locale={locale} />
                    </span>
                    <span className="dropdown__language__option__label">{locale}</span>
                  </p>
                ))}
          </div>
        )}
      </div>
    )
  }

  if (semplificato) {
    return (
      <>
        <header className="header header--semplificato">
          <div className="w-container">
            <div className="header__content">
              {logoImage === 'logosipec' ? (
                <span className="header__logo header__logo--sipec">
                  <LogoSipec />
                </span>
              ) : (
                <img src={logoImage} className="header__logo" />
              )}
              <Link href="/myaccount/registrazione/">
                <a className="header__link">
                  <Trans chiave="Diventa Partner" />
                </a>
              </Link>
            </div>
          </div>
        </header>
      </>
    )
  }
  return (
    <>
      <header className="header header--full">
        <div className="header__top">
          <div className="header__top__content">
            <div className="header__top__lingua">
              <DropdownLanguage variant="top" />
            </div>

            <div className="header__top__link__container">
              {headerData?.header ? (
                <>
                  {headerData?.header.map((obj, k) => {
                    return (
                      <a
                        className="header__top__link"
                        href={obj?.url}
                        target={obj?.blank ? '_blank' : undefined}
                        key={k}
                      >
                        {obj?.nome}
                      </a>
                    )
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="header__main">
          <Link href="/">
            <a>
              {logoImage === 'logosipec' ? (
                <span className="header__main__logo header__main__logo--sipec">
                  <LogoSipec />
                </span>
              ) : (
                <img src={logoImage} className="header__main__logo" />
              )}
            </a>
          </Link>
          <div className="header__main__input__container">
            <SearchBar
              openSearchResults={openSearchResults}
              setOpenSearchResults={setOpenSearchResults}
            />
          </div>
          <div className="header__main__icon__wrapper">
            <div className="header__main__icon-box">
              <DropdownLanguage variant="header" />
            </div>
            <div style={{ position: 'absolute' }}>
              <ModalLogin visible={popupLogin} onClose={() => setPopupLogin(false)} />
            </div>
            {(!service_gift || service_gift?.premium) && (
              <>
                {utente ? (
                  width < 991 ? (
                    <Link href={'/myaccount'}>
                      <a className="header__main__icon-box">
                        <Profile classes="header__main__icon" />
                      </a>
                    </Link>
                  ) : (
                    <div className="header__main__icon-box">
                      <UtenteDropdown />
                    </div>
                  )
                ) : !service_gift || isAs400Down ? (
                  <a className="header__main__icon-box" onClick={() => setPopupLogin(true)}>
                    <Profile classes="header__main__icon" />
                  </a>
                ) : (
                  <Link href={`/myaccount/accedi?next=${router.query?.next || router.asPath}`}>
                    <a className="header__main__icon-box">
                      <Profile classes="header__main__icon" />
                    </a>
                  </Link>
                )}

                {utente && (
                  <Link href={'/myaccount/preferiti'}>
                    <a className="header__main__icon-box--wishlist">
                      <WishlistLine classes="header__main__icon" />
                    </a>
                  </Link>
                )}

                {width > 991 && isCarrelloDisponibile(utente, service_gift) && cartCount ? (
                  <a
                    className="header__main__icon-box"
                    onClick={() => {
                      getCart()
                      setOpenSideCart(true)
                    }}
                  >
                    <span className="header__main__cart-wrapper">
                      <CartNormal classes="header__main__icon" />
                      <span className="header__main__cart-overlay header__main__cart-overlay--notification">
                        {cartCount}
                      </span>
                    </span>
                  </a>
                ) : isCarrelloDisponibile(utente, service_gift) ? (
                  <Link href={'/cart'}>
                    <a style={{ position: 'relative' }} className="header__main__icon-box">
                      <CartButton />
                    </a>
                  </Link>
                ) : service_gift ? (
                  <Link href={`/myaccount/accedi?next=/cart`}>
                    <a style={{ position: 'relative' }} className="header__main__icon-box">
                      <CartButton />
                    </a>
                  </Link>
                ) : (
                  <a
                    style={{ position: 'relative' }}
                    className="header__main__icon-box"
                    onClick={() => setPopupLogin(true)}
                  >
                    <CartButton />
                  </a>
                )}
              </>
            )}
            <div className="header__main__icon-box header__main__icon-box--menu-mobile">
              <Menu
                classes="header__main__icon"
                onClick={() => {
                  setOpenMenuMobile(true)
                }}
              />
            </div>
          </div>
        </div>
        {isCarrelloDisponibile(utente, service_gift) && cartCount > 0 && (
          <SideCart
            open={openSideCart}
            setOpen={setOpenSideCart}
            carrello={cartMain}
            isLoadingCart={isLoadingCart}
          />
        )}
        {children}
      </header>
      {/* Va qui per un problema di z-index */}
      {openSearchResults && <div className="search-results-overlay"></div>}

      <CondizioneServizio />
    </>
  )
}

export default NewHeader
