import * as React from 'react'

function SvgDati(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--dati ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-dati_svg__a"
          d="M12 4.146L5 8.085v.144c0 4.74 2.566 9.13 6.698 11.454l.302.17.302-.17A13.158 13.158 0 0019 8.23v-.144l-7-3.939zm0 17.704c-.339 0-.678-.084-.979-.254l-.303-.17A15.16 15.16 0 013 8.23v-.146c0-.718.39-1.386 1.018-1.741l7.002-3.938a2.009 2.009 0 011.959-.001l7.001 3.938A2.006 2.006 0 0121 8.085v.144c0 5.463-2.957 10.52-7.718 13.197l-.302.17c-.302.17-.641.255-.98.255z"
        />
      </defs>
      <use xlinkHref="#24-dati_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgDati
