// import Icon from './Icon'
import React from 'react'
import { Trans } from '../../atoms'
import { ChevronRight, ChevronLeft } from '../../atoms/NewIcons'
const Pagination = React.memo(({ pagination, handleChangePage, currentPage }) => {
  let before = []
  let after = []

  const isActive = (page) => {
    return currentPage === page ? 'pagination__item--active' : ''
  }

  const isDisabled = (rel) => {
    if (rel === 'prev' && currentPage === 1)
      return { status: true, class: 'pagination__nav--disabled' }
    else if (rel === 'next' && currentPage === pagination.num_pages)
      return { status: true, class: 'pagination__nav--disabled' }
    return { status: false, class: '' }
  }

  const checkSeparator = () => {
    if (pagination.num_pages > 4) {
      if (currentPage + 3 < pagination.num_pages) return true
      else if (currentPage - 3 >= 0 && currentPage + 3 !== pagination.num_pages) return true
    } else return false
  }

  // CASI:
  // currentPage + 3 < pagination.num_pages
  // currentPage + 3 === pagination.num_pages
  // currentPage - 3 > 1

  if (pagination.num_pages > 4) {
    if (currentPage + 3 < pagination.num_pages) {
      before = pagination.page_range.slice(currentPage - 1, currentPage + 2).map((page) => (
        <a
          className={`pagination__item ${isActive(page)}`}
          onClick={() => handleChangePage(page)}
          key={page}
        >
          {page}
        </a>
      ))

      after.push(
        <a
          className={`pagination__item`}
          onClick={() => handleChangePage(pagination.num_pages)}
          key={pagination.num_pages}
        >
          {pagination.num_pages}
        </a>
      )
    } else if (currentPage + 3 === pagination.num_pages) {
      before = pagination.page_range.slice(currentPage - 1, currentPage + 3).map((page) => (
        <a
          className={`pagination__item ${isActive(page)}`}
          onClick={() => handleChangePage(page)}
          key={page}
        >
          {page}
        </a>
      ))
    } else if (currentPage - 3 >= 0) {
      before.push(
        <a className={`pagination__item`} onClick={() => handleChangePage(1)} key={1}>
          {1}
        </a>
      )

      after = pagination.page_range
        .slice(pagination.num_pages - 3, pagination.num_pages)
        .map((page) => (
          <a
            className={`pagination__item ${isActive(page)}`}
            onClick={() => handleChangePage(page)}
            key={page}
          >
            {page}
          </a>
        ))
    }
  } else if (pagination.num_pages) {
    before = pagination.page_range.map((page) => (
      <a
        className={`pagination__item ${isActive(page)}`}
        onClick={() => handleChangePage(page)}
        key={page}
      >
        {page}
      </a>
    ))
  }

  const handleChangePageRel = (page, rel) => {
    if (!isDisabled(rel).status) handleChangePage(page)
  }

  return (
    <div className="pagination effect-fadeup">
      <a
        className={`pagination__nav pagination__nav--prev ${isDisabled('prev').class}`}
        onClick={() => handleChangePageRel(currentPage - 1, 'prev')}
      >
        <ChevronLeft className="pagination__arrow pagination__arrow--prev" />
        <span>
          <Trans chiave="Back" />
        </span>
      </a>

      {before}

      {checkSeparator() ? (
        <span className="pagination__item pagination__item--separator"></span>
      ) : null}

      {after}

      <a
        className={`pagination__nav pagination__nav--next ${isDisabled('next').class}`}
        onClick={() => handleChangePageRel(currentPage + 1, 'next')}
      >
        <span>
          <Trans chiave="Next" />
        </span>
        <ChevronRight className="pagination__arrow pagination__arrow--next" />
      </a>
    </div>
  )
})

export default Pagination
