import React from 'react'
import MyImage from '../MyImage/MyImage'

const Color = ({
  img,
  colorName,
  onClick,
  size,
  sizeMobile,
  isActive,
  selectable,
  onMouseEnter,
  onMouseLeave,
  fromPdf = false,
}) => {
  return (
    <div
      className={
        'color' +
        (size ? ' color--' + size : '') +
        (sizeMobile ? ' color--mobile-' + sizeMobile : '') +
        (selectable ? ' color--selectable' : '') +
        (isActive ? ' color--active' : '')
      }
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="color__image-box">
        {fromPdf ? (
          <img className="color__image" src={img} alt={colorName} title={colorName} />
        ) : (
          <MyImage
            className="color__image"
            src={img}
            alt={colorName}
            title={colorName}
            width={28}
            height={28}
          />
        )}
        {/* size Color - 4px tra padding e bordo */}
      </div>
    </div>
  )
}

export default React.memo(Color)
