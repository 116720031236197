import React, { useState } from 'react'
import FormField from '../../atoms/FormField/FormField'
import FormInput from '../../atoms/FormInput/FormInput'
import Button from '../../atoms/Button/Button'
import { Formik } from 'formik'
import Link from 'next/link'
import * as yup from 'yup'
import useTrans from '../../../hooks/useTrans'
import { useSelector } from 'react-redux'
import { Trans } from '../../atoms'
import { trackingGAEvent } from '../../../utils/gtm'

const validationSchema = yup.object({
  username: yup.string().required('il campo username è richiesto'),
  password: yup.string().required('Il campo password è richiesto'),
})

const LoginContent = ({ isModal, Login, ClosePopup, CredentialError }) => {
  const t = useTrans()
  const login_text = useSelector((state) => state.logintext)

  const [error, setError] = useState(null)

  function createMArkup(testo) {
    return { __html: testo }
  }

  return (
    <div className={`${isModal ? 'login_template' : ''}`}>
      <div className="login">
        <div className="login__content">
          <p className="login__title">{t('Accedi')}</p>
          <p className="login__description">
            {t('Accedi per visualizzare i prezzi per te, per controllare gli ordini e molto altro')}
          </p>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              password: '',
              username: '',
            }}
            onSubmit={({ username, password }) => {
              const params = {
                email: username,
                password: password,
              }
              Login(params)
                .then(() => setError(null))
                .catch((err) => setError(err.message))
            }}
          >
            {(props) => (
              <>
                <FormField
                  label="Username"
                  status={
                    props.touched.username && props.errors.username
                      ? 'error'
                      : props.touched.username && 'success'
                  }
                  errorMessage={props.errors.username}
                >
                  <FormInput
                    name="username"
                    value={props.values.username}
                    onChange={props.handleChange('username')}
                    onBlur={props.handleBlur('username')}
                    autoComplete
                  />
                </FormField>

                <FormField
                  label="Password"
                  status={
                    props.touched.password && props.errors.password
                      ? 'error'
                      : props.touched.password && 'success'
                  }
                  errorMessage={props.errors.password}
                >
                  <FormInput
                    name="password"
                    type="password"
                    value={props.values.password}
                    onChange={props.handleChange('password')}
                    onBlur={props.handleBlur('password')}
                  />
                </FormField>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {CredentialError && <p style={{ color: 'red' }}>{t('Credenziali Errate')}</p>}

                {CredentialError && (
                  <p style={{ color: 'red' }}>
                    {t(
                      'Se accedevi al vecchio sito, utilizza le stesse credenziali con la username e la password scritte in minuscolo'
                    )}
                  </p>
                )}

                <Link href="/myaccount/recupera-password">
                  <a className="login__password-recovery">{t('Recupera la password')}</a>
                </Link>

                <Button
                  label="Accedi"
                  variant="primary"
                  size="medium"
                  onClick={() => props.handleSubmit()}
                  disabled={!props.isValid || !props.dirty}
                />
              </>
            )}
          </Formik>
        </div>
        <div className="login__content">
          <p className="login__title">{t('Registrati')}</p>
          <div
            className="login__description"
            dangerouslySetInnerHTML={createMArkup(login_text?.login_text?.descrizione)}
          >
            {/* <p>{t(`${service_gift_text}Registrati per usufruire di tutti i vantaggi di Sipec`)}</p> */}
            {/* <ul className="u-content-list">
        <li>{t(`${service_gift_text}Prezzi sempre aggiornati`)}</li>
        <li>{t(`${service_gift_text}Sempre la disponibilità reale degli articoli`)}</li>
        <li>
          {t(
            `${service_gift_text}Potri inserire direttamente i tuoi ordini on-line (uno shop 24/24)`
          )}
        </li>
        <li>
          {t(`${service_gift_text}Potrai approfittare delle offere e consultare gli articoli fine
          serie e fuori catalogo`)}
        </li>
      </ul> */}
          </div>
          <Link
            href="/myaccount/registrazione"
            onClick={() => {
              trackingGAEvent('sign_up_start', 'registrati_cta')
              ClosePopup()
            }}
          >
            <a className="login__button">
              <Button label={t('Registrati')} variant="secondary" size="medium" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

const LoginTemplate = ({ Login, CredentialError, ClosePopup = () => {}, isModal = false }) => {
  const t = useTrans()
  const isAs400Down = useSelector((state) => state.servicegift.as400_down)

  const loginContentProps = { Login, CredentialError, ClosePopup, isModal }

  return isModal ? (
    <LoginContent {...loginContentProps} />
  ) : (
    <div className="page login_template">
      <div className="w-container">
        <h1 className="page__title">
          <Trans chiave="Accedi o registrati" />
        </h1>
        {isAs400Down ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '40vh',
            }}
          >
            <h1>{t('Sito in manutenzione temporanea, riprova più tardi.')}</h1>
          </div>
        ) : (
          <LoginContent {...loginContentProps} />
        )}
      </div>
    </div>
  )
}

export default LoginTemplate
