import React from 'react'
import Link from 'next/link'
import useTrans from '../../../hooks/useTrans'
import { Close } from '../NewIcons'
const Banner = ({ onClick, withoutClose, onClose, link, linkText, alert, placeholder }) => {
  const t = useTrans()
  return (
    <div className="banner">
      <div className="banner__content">
        <p className="banner__title">
          {alert && <span className="banner__alert">{t('Attenzione')}: </span>}
          {placeholder}{' '}
          {onClick ? (
            <a onClick={onClick}>{linkText}</a>
          ) : link ? (
            <Link href={link}>
              <a>{linkText}</a>
            </Link>
          ) : (
            <></>
          )}
        </p>
        {!withoutClose && <Close className="banner__close" onClick={onClose} />}
      </div>
    </div>
  )
}

export default React.memo(Banner)
