import * as React from 'react'

function SvgChevronDoubleDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--chevron-double-down ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <path
          d="M12 8.586l4.293-4.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-5 5c-.39.39-1.024.39-1.414 0l-5-5c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L12 8.586zM12 17.586l4.293-4.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-5 5c-.39.39-1.024.39-1.414 0l-5-5c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L12 17.586z"
          transform="translate(-708 -688) translate(708 688)"
        />
      </g>
    </svg>
  )
}

export default SvgChevronDoubleDown
