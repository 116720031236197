import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../utils'
import { TRADUZIONI } from '../utils/endpoint'

export default function useTrans() {
  const [posted, setPost] = useState(false)
  const { traduzioni, fetched, fetching } = useSelector((state) => state.traduzioni)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
  }, [traduzioni])

  useEffect(() => {
    mounted.current = true
  }, [])

  const replaceSostituzioni = (value, sostituzioni) => {
    let result = value
    if (sostituzioni?.length) {
      for (let i in sostituzioni)
        result = result.replace('{' + String(i) + '}', String(sostituzioni[i]))
    }
    return result
  }

  const getTraduzione = (chiave, sostituzioni) => {
    //   let render = 0
    //   render = render + 1
    //   console.log('Numero render per - ', chiave, render)

    if (!chiave) {
      return
    }
    if (!fetched || fetching) {
      return chiave
    } else {
      const traduzione = traduzioni.find((t) => t.chiave.trim() === chiave.trim())

      if (traduzione) {
        return replaceSostituzioni(traduzione?.valore, sostituzioni)
      } else {
        api
          .post(TRADUZIONI, { chiave })
          .then((res) => {
            console.log('Inserita Chiave', chiave)
          })
          .catch((error) => console.log(error))

        return replaceSostituzioni(chiave, sostituzioni)
      }
    }
  }

  return getTraduzione
}
