import * as React from 'react'

function SvgXls(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={'icon icon--xls ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M23.203 2a2 2 0 011.498.675l8.797 9.939c.323.366.502.837.502 1.326v18.289C34 34.859 31.904 37 29.308 37H12.692C10.096 37 8 34.86 8 32.229V6.77C8 4.141 10.096 2 12.692 2zm-1.141 2h-9.37C11.21 4 10 5.236 10 6.771V32.23c0 1.534 1.21 2.77 2.692 2.77h16.616C30.79 35 32 33.764 32 32.229V14.03h-5.937a4 4 0 01-3.995-3.8l-.005-.2L22.062 4zm8.249 8.031l-6.249-7.06v5.06c0 1.054.816 1.918 1.851 1.995l.15.005h4.248z" />
        <g transform="translate(5 18.75)">
          <rect width={22.5} height={10} rx={1} />
          <path
            fill="#FFF"
            d="M5.03 7.5l1.246-2.025L7.52 7.5h1.296L7.008 4.801 8.69 2.146H7.466L6.334 4.072 5.181 2.146H3.928l1.663 2.593L3.815 7.5H5.03zm8.268 0v-.938h-2.172V2.146H9.991V7.5h3.307zm2.617.073c.62 0 1.104-.139 1.452-.417.348-.279.522-.66.522-1.143 0-.349-.093-.644-.279-.884-.185-.24-.528-.48-1.029-.72a7.003 7.003 0 01-.72-.375.786.786 0 01-.221-.216.49.49 0 01-.07-.262c0-.161.058-.292.173-.392.114-.1.28-.15.494-.15.18 0 .364.023.551.07.187.046.423.128.709.245l.366-.883a5.431 5.431 0 00-.793-.278 3.14 3.14 0 00-.796-.099c-.567 0-1.01.136-1.332.407-.32.27-.481.643-.481 1.117 0 .251.049.47.146.659.098.188.229.352.392.494.164.142.41.29.736.443.35.166.58.287.694.363a.95.95 0 01.258.236.466.466 0 01.088.28c0 .188-.066.33-.2.425-.132.095-.323.143-.572.143a2.67 2.67 0 01-.687-.1 6.141 6.141 0 01-.917-.34v1.055c.439.215.944.322 1.516.322z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgXls
