export const REDIRECTS = 'redirects/'
export const PAGINE = 'pagine/'
export const PAGEBUILDER = 'pagebuilder/'
export const TRADUZIONI = 'traduzioni/'
export const UTENTE = 'me/'
export const EDITDATIUTENTE = UTENTE + 'edit_dati/'
export const EDITDATIAZIENDA = UTENTE + 'edit_dati_azienda/'
export const EDITPASSWORDUTENTE = UTENTE + 'edit_password/'
export const EDITUSERNAMEUTENTE = UTENTE + 'edit_username/'
export const SETSISTEMAPAGAMENTOUTENTE = UTENTE + 'set_sistema_pagamento/'
export const PAGINEDETAIL = 'pagine/getbypathorchiave/'
export const CATEGORIE = 'categorie/'
export const PRODOTTI = 'prodotti/'
export const CATEGORIESLUGTREE = 'categorie/getbyslugtree/'
export const LISTING = 'listing/'
export const PROFILOPERSONALE = 'profilo-personale/'
export const PROFILOCHECKEMAIL = PROFILOPERSONALE + 'check_email/'
export const PROFILOCHECKUSERNAME = PROFILOPERSONALE + 'check_username/'
export const PROFILOAZIENDA = 'profilo-azienda/current-user/'
export const PROFILOAZIENDACHECKPIVA = PROFILOAZIENDA + 'check_piva/'
export const GETPRODOTTOVALUES = 'prezzi-articolo/'
export const GETPRODOTTOVALUESPRODOTTI = 'prezzi-prodotto/'
export const SLUG = 'slug/'
export const WISHLISTELEMENTS = 'elementisalvati/'
export const CARRELLO = 'carrelli/'
export const ADDTOMAIN = 'move_to_main/'
export const CARRELLOADDTOMAIN = 'carrelli/add_to_main_cart/'
export const PERSONALIZZAZIONI = '/personalizzazioni/'
export const PERSONALIZZAZIONICARRELLO = '/carrelli-personalizzazioni/'
export const PROSSIMIARRIVI = '/prossimi-arrivi/'
export const INDIRIZZISALVATI = 'indirizzi-salvati/'
export const CARRELLOMAIN = '/carrelli/main/'
export const SVUOTA = 'svuota/'
export const CARRELLOLATER = '/carrelli/later/'
export const INDIRIZZI = 'indirizzi/'
export const NAZIONI = 'nazioni/'
export const PROVINCE = 'province/'
export const RIGHECARRELLO = 'righe-carrello/'
export const MOVETOLATER = 'move_to_later/'
export const CATALOGHI = 'cataloghi/'
export const CATEGORIEMATERIALEPROMOZIONALE = 'categorie-materiale-promozionale/'
export const RICHIESTACATALOGO = 'richiesta-catalogo/'
export const REPARTI = 'reparti/'
export const MOTIVAZIONI = 'motivazioni/'
export const CONTATTO = 'contatto/'
export const CATEGORIEFAQ = 'categorie-faq/'
export const NEWS = 'news/'
export const INEVIDENZA = '?in_evidenza='
export const INHOMEPAGE = '?in_homepage='
export const CATEGORIENEWS = 'categorie-news/'
export const MIEIORDINI = 'miei-ordini/'
export const RICHIESTAATTIVAZIONE = 'richiesta-attivazione/'
export const BOLLE = 'bolle/'
export const FATTURE = 'fatture/'
export const SCADENZE = 'scadenze/'
export const MODALITA_SPEDIZIONE = 'modalita-spedizione/'
export const PORTI = 'porti/'
export const VETTORI = 'vettori/'
export const ORDINI = 'ordini/'
export const ORDINIFLUSSOPERSONALIZZAZIONE = 'ordini-flusso-personalizzazione/'
export const CARICAMENTOLOGHIORDINE = 'caricamento-loghi-ordine/'
export const CARICAMENTOBOZZEDIGITALIORDINE = 'caricamento-bozze-digitali-ordine/'
export const SISTEMIPAGAMENTO = 'sistemi-pagamento/'
export const REGISTRAZIONE = 'registrazione/'
export const REGISTRAZIONESG = 'registrazione/sg/'
export const REGISTRAZIONEADMINAA = 'registrazione/admin_aa/'
export const REGISTRAZIONECHECK = 'registrazione/check_email_piva/'
export const MENUS = 'menus/'
export const MENU_BANNER = 'menu/banner/'
export const HOMEPAGE_DATA_MENU = 'menu/hp/'
export const HOMEPAGE_DATA = 'homepage/'
export const PAGINA_CONTATTI_DATA = 'contatti-menu/'
export const ALTRIDOCUMENTI = 'altri-documenti/'
export const PRODOTTIPREFERITI = 'prodotti-preferiti/'
export const COSTIPERSONALIZZAZIONE = '/costi_personalizzazione/'
export const STRUMENTISERVIZI = 'strumenti-servizi/'
export const CANALECOMMERCIALE = 'canale-commerciale/'
export const ORDINISERVICEGIFT = 'ordini-servicegift/'
export const ORDINISERVICEGIFTADMIN = 'ordini-servicegift-admin/'
export const CARRELLOCOUNT = 'carrelli/main/count/'
export const PERSONALIZZAZIONIORDINE = 'personalizzazioni-ordine/'
export const CREAZIONEUTENTEADMINDAA = 'admin_anagrafica_azienda/'
export const CONFERMABOZZEDIGITALI = 'conferma_bozze_digitali/'
export const SEARCH = 'search/'
export const CRONOLOGIA_RICERCA = 'me/cronologia_ricerca/'
export const PRODOTTI_VISTI = 'me/prodottivisti/'
export const RICHIEDIDISPONIBILITA = 'richieste-disponibilita/'
export const GIORNICONSEGNA = 'giorni-consegna/'
export const TECNICHE = 'tecniche/'
export const CHECKPERSONALIZZAZIONE = 'check_personalizzazione/'
export const ORDINASUBITO = 'ordina_subito/'
export const RICHIESTATAYLORMADE = 'richiesta-taylormade/'
export const CURRENTSITE = 'current-site/'
export const PROMOTION = 'promozioni/'
export const GET_PROMO_CODE = PROMOTION
export const SET_PROMO_CODE = PROMOTION + 'set_promo_code/'
export const REMOVE_PROMO_CODE = PROMOTION + 'remove_promo_code/'
export const COOKIE_BANNER_SG = 'service_gift_cookie_banner/'
export const SOCIAL_LOGIN = 'social-login/'
