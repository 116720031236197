import * as React from 'react'

const FlagEs = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="900" height="600" viewBox="0 0 900 600">
      <rect width="900" height="600" fill="#c60b1e" />
      <rect width="900" height="300" y="150" fill="#ffc400" />
    </svg>
  )
}

export default FlagEs
