import * as React from 'react'

function SvgTagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--tag ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-tag_svg__a"
          d="M10.56 8.44a1.496 1.496 0 010 2.12 1.496 1.496 0 01-2.12 0 1.498 1.498 0 112.12-2.12m-4.726 4.452l6.032 6.033 7.058-7.058-6.032-6.033-7.785-.726.727 7.784zm6.032 8.107c-.465 0-.929-.177-1.284-.53l-6.417-6.418a1 1 0 01-.289-.614l-.872-9.344a1 1 0 011.09-1.088l9.343.872c.232.021.45.123.614.288l6.417 6.417c.343.343.532.799.532 1.285 0 .486-.189.942-.532 1.285l-7.317 7.316a1.81 1.81 0 01-1.285.531z"
        />
      </defs>
      <use xlinkHref="#24-tag_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgTagIcon
