import * as React from 'react'

function SvgChevronLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--chevron-left ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M10.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L10.414 12z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronLeft
