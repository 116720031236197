import * as React from 'react'

function SvgBook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--book ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="book_svg__a"
          d="M15.833 13.669l-5 1.123v-8.46l5-1.123v8.46zM4.167 5.209l5 1.122v8.46l-5-1.122v-8.46zm13.02-1.694a.836.836 0 00-.704-.161L10 4.81 3.517 3.354a.832.832 0 00-1.017.813v10.168c0 .39.27.728.65.813l6.667 1.498a.814.814 0 00.366 0l6.667-1.498a.833.833 0 00.65-.813V4.167a.837.837 0 00-.313-.652z"
        />
      </defs>
      <use xlinkHref="#book_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgBook
