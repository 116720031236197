import * as React from 'react'

function SvgGoccia(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 13"
      className={'icon icon--goccia ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <g>
          <path
            fill="#FFF"
            d="M5.729 1.862l.45.494.332.373.318.364.304.356.289.346.274.338.26.33.246.32.23.312.218.304.202.295c.065.097.128.192.188.286l.174.278.08.136.153.266c.024.043.048.087.07.13l.131.253c.947 1.91.635 3.097-.894 3.665l-.206.07c-.32.102-.687.179-1.1.233l-.281.033-.296.026-.31.02-.322.013-.337.007h-.173l-.344-.003-.166-.004-.323-.013-.31-.02-.295-.026-.282-.033c-.413-.054-.78-.131-1.1-.232l-.206-.07c-1.53-.57-1.841-1.757-.894-3.666l.13-.254.071-.13.152-.265.082-.136.173-.278c.06-.094.123-.19.188-.286l.203-.295.216-.304.232-.312.245-.32.26-.33.275-.338.289-.346.303-.356.318-.364.333-.373.45-.494z"
          />
          <path d="M5.729 0l-.674.718-.41.444-.39.437-.375.43c-.061.07-.122.14-.181.211l-.35.417c-.113.138-.223.275-.33.41l-.315.401c-.255.331-.493.654-.712.968l-.254.374c-.246.368-.466.725-.66 1.07l-.184.339c-2.089 3.97-.532 6.164 4.835 6.164s6.923-2.194 4.835-6.164l-.185-.34c-.194-.344-.414-.7-.66-1.07l-.254-.373c-.22-.314-.456-.637-.711-.968l-.315-.401c-.108-.135-.218-.272-.332-.41L7.76 2.24l-.366-.425-.19-.216-.392-.437-.408-.444L5.729 0zm0 1.862l.45.494.332.373.318.364.304.356.289.346.274.338.26.33.246.32.23.312.218.304.202.295c.065.097.128.192.188.286l.174.278.08.136.153.266c.024.043.048.087.07.13l.131.253c.947 1.91.635 3.097-.894 3.665l-.206.07c-.32.102-.687.179-1.1.233l-.281.033-.296.026-.31.02-.322.013-.337.007h-.173l-.344-.003-.166-.004-.323-.013-.31-.02-.295-.026-.282-.033c-.413-.054-.78-.131-1.1-.232l-.206-.07c-1.53-.57-1.841-1.757-.894-3.666l.13-.254.071-.13.152-.265.082-.136.173-.278c.06-.094.123-.19.188-.286l.203-.295.216-.304.232-.312.245-.32.26-.33.275-.338.289-.346.303-.356.318-.364.333-.373.45-.494z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgGoccia
