import * as React from 'react'

function SvgChevronDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--chevron-down ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M12 13.586l4.293-4.293a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L12 13.586z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronDown
