import TagManager from 'react-gtm-module'
import toUpper from 'lodash/toUpper'
import { DOMAIN } from '.'
import replace from 'lodash/replace'
import { priceNotationNumber } from './safe'

const GTM_PAGE_DEFAULT = 'others'
const GTM_ID = 'GTM-WKFM8CM'
const ELEMENTS_PER_PAGE = 12

export const initGTMdataLayer = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
  })
}

export const sendGTMData = (data) => {
  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

export const uniformData = (param) => replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()

export const getFileNameFromUrl = (fileUrl) => {
  const match = fileUrl?.match(/(?:\/|\\|^)([^/\\]+)\.[^.]+$/)
  return match ? match[1] : null
}

const getGTMProps = (paginaCorrente, router, service_gift) => {
  let country = toUpper(router?.locale)
  let language = router?.locale
  // let brand = service_gift?.nome == null ? 'Sipec' : service_gift?.nome
  let page = paginaCorrente?.page?.chiave || ''
  const is_404 = paginaCorrente?.statusCode === 404
  let firstLevelCategory = ''
  let secondLevelCategory = ''
  let thirdLevelCategory = ''

  let pageType = GTM_PAGE_DEFAULT

  const corporatePages = [
    'about',
    'news',
    'strumenti-servizi',
    'tailor-made',
    'cataloghi',
    'contatti',
    'informativa-privacy',
    'condizioni-servizio',
    'cookie-policy',
  ]

  if (page.includes('categoria')) pageType = 'listing'
  else if (page.includes('homepage')) pageType = 'homepage'
  else if (page.includes('cart')) pageType = 'cart'
  else if (page.includes('checkout')) pageType = 'checkout'
  else if (page.includes('preferiti')) pageType = 'wishlist'
  // prima di myaccount
  else if (page.includes('myaccount')) pageType = 'profile'
  else if (page.includes('articolo') || page.includes('prodotto')) pageType = 'product'
  else if (
    page.includes('contatti-form-success') ||
    page.includes('richiesta-attivazione-form-success')
  )
    pageType = 'thank_you_page'
  else if (is_404 || page.includes('404')) pageType = '404'
  else if (corporatePages.includes(page)) pageType = 'corporate'
  else if (router?.asPath) {
    if (
      router.asPath.includes('cart/checkout/completed/') ||
      router.asPath.includes('strumenti-servizi/tailor-made/success') ||
      router.asPath.includes('myaccount/registrazione/completed')
    )
      pageType = 'thank_you_page'
  }

  if (pageType == 'listing' && router?.asPath) {
    let parts = router?.asPath.split('/')
    const index = parts.indexOf('c') ? parts.indexOf('c') : 0
    firstLevelCategory = parts[index + 1] || ''
    secondLevelCategory = parts[index + 2] || ''
    thirdLevelCategory = parts[index + 3] || ''
  }

  return {
    country,
    language,
    pageType,
    firstLevelCategory,
    secondLevelCategory,
    thirdLevelCategory,
  }
}

const getUtenteProps = (utente, router) => {
  let loginStatus = utente ? 'logged' : 'not_logged'
  let userID = utente ? utente?.id : 'not_logged'
  let agency_id = utente ? utente?.anagrafica_azienda_codice : 'not_logged'

  return {
    loginStatus,
    userID,
    agency_id,
  }
}

export const trackingProductImpression = (prodotti, page = 1) => {
  let products = []

  for (let i = 0; prodotti && i < prodotti.length; i++) {
    const prodotto = prodotti[i]
    const articoli = prodotto?.articoli_colore || []

    const fasce = prodotto?.listino?.con_fasce
    let currentPrezzo = prodotto?.listino?.prezzo
    if (fasce && prodotto?.listino?.fasce?.length) {
      currentPrezzo = prodotto.listino.fasce[0]?.prezzo || currentPrezzo
    }

    const default_articolo = articoli
      ? articoli?.filter((articolo) => articolo?.default === true)
      : []

    const articolo =
      default_articolo.length > 0
        ? default_articolo[0]
        : articoli && articoli?.length > 0
        ? articoli[0]
        : null

    products.push({
      item_id: prodotto?.codice || '', // "$$$product_id$$$"
      item_name: uniformData(articolo?.titolo) || '', // '$$$product_name$$$'
      affiliation: '',
      coupon: 'none', // '$$$discount_name$$$'
      currency: 'EUR', // '$$$currency_code$$$'
      discount: '', // $$$discount_value$$$
      index: i + 1 + ELEMENTS_PER_PAGE * ((page && page >= 0 ? page : 1) - 1), // $$$product_position$$$
      item_brand: uniformData(prodotto.brand) || '', // '$$$brand$$$'
      item_category: '', // '$$$product_category$$$' Al momento non valorizzato
      item_category2: '', // '$$$product_first_category$$$' Al momento non valorizzato
      item_category3: '', // '$$$product_second_category$$$' Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard', // '$$$list_type$$$'
      item_list_name: 'standard', // '$$$list_type$$$'
      item_variant: articolo?.colore?.nome || '', // '$$$product_color$$$'
      location_id: '',
      price: priceNotationNumber(currentPrezzo, 3) || '', // $$$product_price$$$
      quantity: 1,
    })
  }

  sendGTMData({
    event: 'view_item_list',
    ecommerce: {
      items: products,
    },
  })
}

export const trackingCartView = (ordine) => {
  let products = []

  const righe_neutre = ordine?.righe_carrello_merce_neutra ?? []
  const righe_personalizzate =
    ordine?.personalizzazioni_carrello.flatMap(
      (personalizzazione) => personalizzazione?.righe_carrello ?? []
    ) ?? []
  const righe = righe_neutre?.concat(righe_personalizzate)

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'view_cart',
    ecommerce: {
      currency: 'EUR',
      value: priceNotationNumber(ordine?.totale, 2),
      add_cart_location: '',
      items: products,
    },
  })
}

export const trackingCheckoutStart = (ordine) => {
  let products = []

  const righe_neutre = ordine?.righe_carrello_merce_neutra ?? []
  const righe_personalizzate =
    ordine?.personalizzazioni_carrello.flatMap(
      (personalizzazione) => personalizzazione?.righe_carrello ?? []
    ) ?? []
  const righe = righe_neutre?.concat(righe_personalizzate)

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'begin_checkout',
    ecommerce: {
      add_cart_location: '',
      items: products,
    },
  })
}

export const trackingCheckoutShipping = (ordine, shippingType) => {
  let products = []

  const righe_neutre = ordine?.righe_carrello_merce_neutra ?? []
  const righe_personalizzate =
    ordine?.personalizzazioni_carrello.flatMap(
      (personalizzazione) => personalizzazione?.righe_carrello ?? []
    ) ?? []
  const righe = righe_neutre?.concat(righe_personalizzate)

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'add_shipping_info',
    ecommerce: {
      add_cart_location: '',
      currency: 'EUR',
      value: priceNotationNumber(ordine?.totale, 2),
      coupon: '',
      shipping_tier: shippingType || '',
      items: products,
    },
  })
}

export const trackingCheckoutPayment = (ordine, paymentType, coupon) => {
  let products = []

  const righe_neutre = ordine?.righe_carrello_merce_neutra ?? []
  const righe_personalizzate =
    ordine?.personalizzazioni_carrello.flatMap(
      (personalizzazione) => personalizzazione?.righe_carrello ?? []
    ) ?? []
  const righe = righe_neutre?.concat(righe_personalizzate)

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'add_payment_info',
    ecommerce: {
      add_cart_location: '',
      currency: 'EUR',
      value: priceNotationNumber(ordine?.totale, 2),
      coupon: coupon || '',
      payment_type: paymentType ? uniformData(paymentType) : '',
      items: products,
    },
  })
}

export const trackingPurchase = (ordine) => {
  let products = []
  const righe = ordine?.righe_carrello

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'purchase',
    ecommerce: {
      add_cart_location: '',
      transaction_id: ordine?.id || '',
      affiliation: '',
      value: priceNotationNumber(ordine?.totale, 2),
      tax: priceNotationNumber(ordine?.totale_imposta, 2),
      shipping: priceNotationNumber(ordine?.valore_trasporto, 2),
      currency: 'EUR',
      coupon: ordine?.promo_code_applicato || '',
      items: products,
    },
  })
}

export const trackingProductClick = (prodotto, index, page = 1, selectedArticle) => {
  const articoli = prodotto?.articoli_colore || []

  const fasce = prodotto?.listino?.con_fasce
  let currentPrezzo = prodotto?.listino?.prezzo
  if (fasce && prodotto?.listino?.fasce?.length) {
    currentPrezzo = prodotto.listino.fasce[0]?.prezzo || currentPrezzo
  }

  let articolo = articoli?.find((articolo) => articolo?.id === selectedArticle.id)

  if (!articolo) {
    const default_articolo = articoli
      ? articoli?.filter((articolo) => articolo?.default === true)
      : []

    articolo =
      default_articolo.length > 0
        ? default_articolo[0]
        : articoli && articoli?.length > 0
        ? articoli[0]
        : null
  }

  const product = {
    item_id: prodotto?.codice || '',
    item_name: uniformData(articolo?.titolo) || '',
    affiliation: '',
    coupon: 'none',
    currency: 'EUR',
    discount: '',
    index: index + 1 + ELEMENTS_PER_PAGE * ((page && page >= 0 ? page : 1) - 1),
    item_brand: uniformData(prodotto.brand) || '',
    item_category: '', // Al momento non valorizzato
    item_category2: '', //  Al momento non valorizzato
    item_category3: '', //  Al momento non valorizzato
    item_category4: '',
    item_category5: '',
    item_list_id: 'standard',
    item_list_name: 'standard',
    item_variant: articolo?.colore?.nome || '',
    location_id: '',
    price: priceNotationNumber(currentPrezzo, 3) || '',
    quantity: 1,
  }

  sendGTMData({
    event: 'select_item',
    ecommerce: {
      items: [product],
    },
  })
}

export const trackingProductDetail = (
  prodotto,
  selectedArticolo,
  selectedTaglia,
  selectedColor
) => {
  const articolo = selectedArticolo

  const fasce = articolo?.con_fasce
  let currentPrezzo = selectedArticolo?.prezzo
  if (fasce) {
    let prezzo_riservato = currentPrezzo
    let fascia = selectedArticolo?.fasce?.[0].prezzo
    if (prezzo_riservato >= fascia) {
      currentPrezzo = fascia
    }
  }

  const product = {
    item_id: prodotto?.codice || '',
    item_name: uniformData(articolo?.titolo) || '',
    affiliation: '',
    coupon: 'none',
    currency: 'EUR',
    discount: '',
    index: 1,
    item_brand: uniformData(prodotto.brand) || '',
    item_category: '', // Al momento non valorizzato
    item_category2: '', //  Al momento non valorizzato
    item_category3: '', //  Al momento non valorizzato
    item_category4: '',
    item_category5: '',
    item_list_id: 'standard',
    item_list_name: 'standard',
    item_variant: selectedColor?.nome || '',
    location_id: '',
    price: priceNotationNumber(currentPrezzo, 3) || '',
    quantity: 1,
    size: selectedTaglia?.codice || '',
  }

  sendGTMData({
    event: 'view_item',
    ecommerce: {
      items: [product],
    },
  })
}

export const trackingAddCart = (ordine, location) => {
  let products = []

  if (location === 'fast_order') {
    const righe = ordine.filter((riga) => riga?.quantita > 0 && riga?.id)
    for (let i = 0; righe && i < righe.length; i++) {
      const riga = righe[i]
      products.push({
        item_id: riga?.codice || '', // In questo caso abbiamo il codice dell'articolo e non il codice prodotto
        item_name: '',
        affiliation: '',
        coupon: 'none',
        currency: 'EUR',
        discount: '',
        index: i + 1,
        item_brand: '',
        item_category: '', //  Al momento non valorizzato
        item_category2: '', //  Al momento non valorizzato
        item_category3: '', //  Al momento non valorizzato
        item_category4: '',
        item_category5: '',
        item_list_id: 'standard',
        item_list_name: 'standard',
        item_variant: '',
        location_id: '',
        price: '',
        quantity: riga?.quantita || '',
        product_type: 'neutro',
        size: '',
      })
    }
  } else if (location === 'file_order') {
    const righe = ordine
    // Al momento non ho nessun dato
  } else {
    for (let i = 0; ordine?.righe_carrello && i < ordine.righe_carrello.length; i++) {
      const riga = ordine.righe_carrello[i]
      const articolo = riga?.articolo

      products.push({
        item_id: articolo?.prodotto_codice || '',
        item_name: uniformData(articolo?.titolo) || '',
        affiliation: '',
        coupon: 'none',
        currency: 'EUR',
        discount: '',
        index: i + 1,
        item_brand: uniformData(articolo?.prodotto_brand) || '',
        item_category: '', // Al momento non valorizzato
        item_category2: '', //  Al momento non valorizzato
        item_category3: '', //  Al momento non valorizzato
        item_category4: '',
        item_category5: '',
        item_list_id: 'standard',
        item_list_name: 'standard',
        item_variant: articolo?.colore?.nome || '',
        location_id: '',
        price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
        quantity: riga?.quantita || '',
        product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
        size: articolo?.taglia?.codice || '',
      })
    }
  }

  sendGTMData({
    event: 'add_to_cart',
    ecommerce: {
      add_cart_location: location || '',
      items: products,
    },
  })
}

export const trackingRemoveCart = (righe) => {
  let products = []

  for (let i = 0; righe && i < righe.length; i++) {
    const riga = righe[i]
    const articolo = riga?.articolo

    products.push({
      item_id: articolo?.prodotto_codice || '',
      item_name: uniformData(articolo?.titolo) || '',
      affiliation: '',
      coupon: 'none',
      currency: 'EUR',
      discount: '',
      index: i + 1,
      item_brand: uniformData(articolo?.prodotto_brand) || '',
      item_category: '', // Al momento non valorizzato
      item_category2: '', //  Al momento non valorizzato
      item_category3: '', //  Al momento non valorizzato
      item_category4: '',
      item_category5: '',
      item_list_id: 'standard',
      item_list_name: 'standard',
      item_variant: articolo?.colore?.nome || '',
      location_id: '',
      price: priceNotationNumber(riga?.prezzo_unitario_netto, 3) || '',
      quantity: riga?.quantita || '',
      product_type: riga?.personalizzazione_carrello_id ? 'personalizzato' : 'neutro',
      size: articolo?.taglia?.codice || '',
    })
  }

  sendGTMData({
    event: 'remove_from_cart',
    ecommerce: {
      items: products,
    },
  })
}

export const trackingDownload = (categoriaDownload, nomeFile) => {
  sendGTMData({
    event: 'download',
    position: categoriaDownload,
    label: uniformData(nomeFile) || '',
  })
}

export const trackingRegistrazioneFunnel = (step) => {
  const steps = {
    1: 'step_1_dati_personali',
    2: 'step_2_credenziali',
    3: 'step_3_dati_aziendali',
    4: 'step_4_info_aggiuntive',
    5: 'step_5_consensi',
    6: 'step_6_conferma',
  }
  const stepName = steps[step]

  if (stepName)
    sendGTMData({
      event: 'sign_up_funnel',
      position: stepName,
    })
}

export const trackingGAEvent = (event, position = undefined, label = undefined) => {
  sendGTMData({
    event: event,
    position: position ? uniformData(position) : position,
    label: label ? uniformData(label) : label,
  })
}

export const trackingGAEventProdotto = (
  event,
  position = undefined,
  label = undefined,
  productname = undefined,
  sku = undefined
) => {
  sendGTMData({
    event: event,
    position: position ? uniformData(position) : position,
    label: label ? uniformData(label) : label,
    productname: productname ? uniformData(productname) : productname,
    sku: sku ? uniformData(sku) : sku,
  })
}

const getGTMPageProps = (paginaCorrente, router) => {
  let pageTitle = uniformData(paginaCorrente?.pagina?.titolo)
  let documentLocation = window.location.href
  let pagePath = router?.asPath
  let originalLocation = document?.referrer

  return {
    documentLocation,
    originalLocation,
    pagePath,
    pageTitle,
  }
}

export const updateGTMDataLayer = (paginaCorrente, utente, router, service_gift) => {
  const gtmProps = getGTMProps(paginaCorrente, router, service_gift)
  const utenteProps = getUtenteProps(utente, router)
  const gtmPageProps = getGTMPageProps(paginaCorrente, router)

  sendGTMData({
    event: 'wSetup',
    ...gtmProps,
    ...utenteProps,
  })

  sendGTMData({
    event: 'virtual_pageview',
    ...gtmPageProps,
  })
}

// TODO: metodo vecchio per far funzionare nuovo checkout sg in staging
export const trackingCheckout = (carrello_main, step, dettaglio) => {
  let products = []
  let checkoutOpt = ''

  switch (step) {
    case 1:
      checkoutOpt = dettaglio ? 'ritiro' : 'spedizione'
      break
    case 2:
      checkoutOpt = dettaglio.toLowerCase().replace(/ /g, '_')
      break
    case 3:
      checkoutOpt = dettaglio == 'tm' ? 'trasporto_mia_intestazione' : 'trasporto_sipec'
      break
    case 4:
      checkoutOpt = null
      break
    case 5:
      checkoutOpt = dettaglio.toLowerCase().replace(/ /g, '_')
      break
  }

  for (let i = 0; carrello_main && i < carrello_main.righe_carrello?.length; i++) {
    products.push({
      name: carrello_main.righe_carrello[i]?.articolo?.titolo || '',
      id: carrello_main.righe_carrello[i]?.articolo?.codice || '',
      price: carrello_main.righe_carrello[i]?.prezzo_unitario_netto || '',
      brand: '', // $$$brand$$$
      category: '', // $$$product_category$$$
      variant: carrello_main.righe_carrello[i]?.articolo?.colore?.nome || '',
      quantity: carrello_main.righe_carrello[i]?.quantita || '',
      dimension14: carrello_main.righe_carrello[i]?.articolo?.taglia || '',
      dimension16: '', // $$$discount_name$$$
      dimension15: '', // $$$product_type$$$
      metric4: '', // $$$discount_value$$$
    })
  }

  sendGTMData({
    event: 'checkout',
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: {
          step: step,
          option: checkoutOpt,
        },
        products: products,
      },
    },
  })
}
