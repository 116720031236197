export const MODULI_EXTRA_PREVENTIVO_PDP = 'preventivo_pdp'
export const MODULI_EXTRA_CAMPI_TIPOLOGIA_UTENTE = 'campi_tipologia_utente'
export const MODULI_EXTRA_CHECKOUT_GUEST = 'checkout_guest'
export const MODULI_EXTRA_SISTEMI_PAGAMENTO_DIFFERENZIATI = 'sistemi_pagamento_differenziati'
export const MODULI_EXTRA_SOCIAL_LOGIN = 'social_login'

export const showTabellaSchedaProdotto = (utente, service_gift, isAs400Down) => {
  if (isAs400Down) return false

  if (utente) return true

  if (service_gift && !service_gift.premium) return true

  if (
    service_gift &&
    service_gift.premium &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  )
    return true

  return false
}

export const showPersonalizzazioneFormSchedaProdotto = (utente, service_gift, isAs400Down) => {
  if (isAs400Down) return false

  if (utente) return true

  if (
    service_gift &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  )
    return true

  return false
}

export const showDettagliProdotto = (utente, service_gift, isAs400Down) => {
  if (isAs400Down) return false

  if (utente) return true

  if (service_gift && !service_gift.premium) return true

  if (
    service_gift &&
    service_gift.premium &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  )
    return true

  return false
}

export const showColonnaQuantita = (utente, service_gift) => {
  if (utente && (!service_gift || service_gift?.premium)) return true

  if (
    service_gift &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  )
    return true

  return false
}

export const showRiepilogo = (utente, service_gift, isAs400Down) => {
  if (isAs400Down) return false

  if (utente && (!service_gift || service_gift?.premium)) return true

  if (
    service_gift &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  )
    return true

  return false
}

export const showButtonsRiepilogo = (utente, service_gift, isAs400Down) => {
  if (showRiepilogo(utente, service_gift, isAs400Down)) {
    if (!service_gift) return true

    if (service_gift?.premium) return true
  }

  return false
}

export const getIgnoraMinimiStampa = (utente, service_gift) => {
  if (service_gift) return service_gift.ignora_minimi_stampa

  return utente?.ignora_minimi_stampa
}

export const isCarrelloDisponibile = (utente, service_gift) => {
  if (utente) return true

  if (!service_gift && utente) return true

  if (
    service_gift &&
    service_gift.premium &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_PREVENTIVO_PDP) &&
    service_gift.attiva_preventivo_pagina_prodotto
  ) {
    return true
  }

  return false
}

export const getTipologieUtenteRegistrazione = (service_gift) => {
  // se non è abilitato il modulo, ritorna solo la tipologia 'azienda'
  return service_gift.moduli_extra.includes(MODULI_EXTRA_CAMPI_TIPOLOGIA_UTENTE)
    ? service_gift?.tipologie_utenti?.filter((x) => x.attivo)
    : service_gift?.tipologie_utenti?.filter((x) => x.chiave === 'azienda' && x.attivo) || []
}

export const getVisibleFieldsRegistrazione = (service_gift, tipologiaUtenteChiave) => {
  if (!service_gift) return []

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return []

  return [
    ...tipologiaUtenteValue.campi_obbligatori_registrazione,
    ...tipologiaUtenteValue.campi_facoltativi_registrazione,
  ]
}

export const getRequiredFieldsRegistrazione = (service_gift, tipologiaUtenteChiave) => {
  if (!service_gift) return []

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return []

  return [...tipologiaUtenteValue.campi_obbligatori_registrazione]
}

export const getRequiredFields = (service_gift, tipologiaUtenteChiave, variant) => {
  if (!service_gift) return []

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave
  )

  const fallback = [
    'utente.nome',
    'utente.cognome',
    'utente.email',
    'utente.telefono',
    'anagrafica_azienda.ragione_sociale',
    'anagrafica_azienda.partita_iva',
    'anagrafica_azienda.email',
    'anagrafica_azienda.telefono',
  ]

  // fallback
  if (!tipologiaUtenteValue) return fallback

  switch (variant) {
    case 'registrazione':
      return getRequiredFieldsRegistrazione(service_gift, tipologiaUtenteChiave)
    case 'checkout':
      return getRequiredFieldsCheckout(service_gift, tipologiaUtenteChiave)
  }

  return fallback
}

export const isRequiredFieldRegistrazione = (service_gift, tipologiaUtenteChiave, chiave) => {
  if (!service_gift) return false

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave
  )

  if (!tipologiaUtenteValue) return false

  return tipologiaUtenteValue.campi_obbligatori_registrazione.indexOf(chiave) > -1
}

export const isRequiredField = (service_gift, tipologiaUtenteChiave, variant, chiave) => {
  if (!service_gift) return false

  const requiredFields = getRequiredFields(service_gift, tipologiaUtenteChiave, variant)

  return requiredFields.indexOf(chiave) > -1
}

export const isMissingRequiredFields = (service_gift, utente, variant) => {
  if (!service_gift || !utente) return false

  const requiredFields = getRequiredFields(service_gift, utente.tipologia, variant)

  let missing = false

  requiredFields.forEach((field) => {
    const chiavePrefix = field.split('.')?.[0] || ''
    const chiave = field.split('.')?.[1] || ''
    if (chiavePrefix == 'utente' || chiavePrefix == 'anagrafica_azienda') {
      const value = (chiavePrefix == 'utente' ? utente : utente.anagrafica_azienda)?.[chiave]
      if (!value) {
        missing = true
      }
    }
  })

  return missing
}

export const getMissingRequiredFields = (service_gift, utente, variant) => {
  if (!service_gift || !utente) return false

  const requiredFields = getRequiredFields(service_gift, utente.tipologia, variant)

  let missingFields = []

  requiredFields.forEach((field) => {
    const chiavePrefix = field.split('.')?.[0] || ''
    const chiave = field.split('.')?.[1] || ''
    if (chiavePrefix == 'utente' || chiavePrefix == 'anagrafica_azienda') {
      const value = (chiavePrefix == 'utente' ? utente : utente.anagrafica_azienda)?.[chiave]
      if (!value) {
        missingFields.push(field)
      }
    }
  })

  return missingFields
}

export const isStepRegistrazioneRequired = (service_gift, tipologiaUtenteChiave, step) => {
  if (!service_gift) return false

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return false

  const campiRegistrazione = [
    ...tipologiaUtenteValue.campi_obbligatori_registrazione,
    ...tipologiaUtenteValue.campi_facoltativi_registrazione,
  ]

  let foundSpedizione = null,
    foundFatturazione = null
  switch (step) {
    case 'tipologia':
      return getTipologieUtenteRegistrazione(service_gift)?.length > 1 ? true : false
    case 'credenziali':
      return true
    case 'dati_fatturazione_spedizione':
      const foundIndirizzi =
        campiRegistrazione.find((x) => x.includes('indirizzo_fatturazione.')) ||
        campiRegistrazione.find((x) => x.includes('indirizzo_spedizione.'))
      return !!foundIndirizzi
    case 'autorizzazioni':
      return campiRegistrazione.includes('utente.autorizzazioni_consensi')
    case 'indirizzi_diversi':
      // per mostrare checkbox per indirizzo spedizione
      foundSpedizione = campiRegistrazione.find((x) => x.includes('indirizzo_spedizione.'))
      foundFatturazione = campiRegistrazione.find((x) => x.includes('indirizzo_fatturazione.'))
      return !!foundSpedizione && !!foundFatturazione
    case 'indirizzo_fatturazione':
      foundFatturazione = campiRegistrazione.find((x) => x.includes('indirizzo_fatturazione.'))
      return !!foundFatturazione
    case 'indirizzo_spedizione':
      foundSpedizione = campiRegistrazione.find((x) => x.includes('indirizzo_spedizione.'))
      return !!foundSpedizione
  }
}

export const isSocialLoginAllowed = (service_gift) => {
  if (!service_gift) return false

  return (
    service_gift.moduli_extra.includes(MODULI_EXTRA_SOCIAL_LOGIN) &&
    (service_gift?.social_auth_google_oauth2_key || service_gift?.social_auth_facebook_key)
  )
}

export const isStepCheckoutRequired = (
  service_gift,
  tipologiaUtenteChiave,
  step,
  utente,
  isMerceNeutra
) => {
  if (!service_gift) return false

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return false

  const campiCheckout = [
    ...tipologiaUtenteValue.campi_obbligatori_checkout,
    ...tipologiaUtenteValue.campi_facoltativi_checkout,
  ]

  let foundSpedizione = null,
    foundFatturazione = null
  switch (step) {
    case 'tipologia':
      return !utente && getTipologieUtenteRegistrazione(service_gift)?.length > 1 ? true : false
    case 'credenziali':
      return !utente
    case 'autorizzazioni':
      return campiCheckout.includes('utente.autorizzazioni_consensi')
    case 'info_generali':
      return isMissingRequiredFields(service_gift, utente, 'checkout')
    case 'dati_fatturazione_spedizione':
      const foundIndirizzi =
        campiCheckout.find((x) => x.includes('indirizzo_fatturazione.')) ||
        campiCheckout.find((x) => x.includes('indirizzo_spedizione.'))
      return !!foundIndirizzi
    case 'note_ordine':
      return campiCheckout.find((x) => x.includes('checkout.note_ordine'))
    case 'bozza_digitale':
      return !isMerceNeutra
    case 'pagamento':
      return true
    case 'indirizzi_diversi':
      // per mostrare checkbox per indirizzo fatturazione
      foundSpedizione = campiCheckout.find((x) => x.includes('indirizzo_spedizione.'))
      foundFatturazione = campiCheckout.find((x) => x.includes('indirizzo_fatturazione.'))
      return !!foundSpedizione && !!foundFatturazione
    case 'indirizzo_fatturazione':
      foundFatturazione = campiCheckout.find((x) => x.includes('indirizzo_fatturazione.'))
      return !!foundFatturazione
    case 'indirizzo_spedizione':
      foundSpedizione = campiCheckout.find((x) => x.includes('indirizzo_spedizione.'))
      return !!foundSpedizione
    case 'fattura_richiesta':
      return !!tipologiaUtenteValue.campi_obbligatori_checkout.find((x) =>
        x.includes('checkout.fattura_richiesta')
      )
    case 'fattura':
      return !!campiCheckout.find((x) => x.includes('checkout.fattura_richiesta'))
  }
}

export const getVisibleFieldsCheckout = (service_gift, tipologiaUtenteChiave) => {
  if (!service_gift) return []

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return []

  return [
    ...tipologiaUtenteValue.campi_obbligatori_checkout,
    ...tipologiaUtenteValue.campi_facoltativi_checkout,
  ]
}

export const getRequiredFieldsCheckout = (service_gift, tipologiaUtenteChiave) => {
  if (!service_gift) return []

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave && x.attivo
  )

  if (!tipologiaUtenteValue) return []

  return [...tipologiaUtenteValue.campi_obbligatori_checkout]
}

export const isRequiredFieldCheckout = (service_gift, tipologiaUtenteChiave, chiave) => {
  if (!service_gift) return false

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave
  )

  if (!tipologiaUtenteValue) return false

  return tipologiaUtenteValue.campi_obbligatori_checkout.indexOf(chiave) > -1
}

export const getVisibleFields = (service_gift, tipologiaUtenteChiave, variant) => {
  switch (variant) {
    case 'registrazione':
      return getVisibleFieldsRegistrazione(service_gift, tipologiaUtenteChiave)
    case 'checkout':
      return getVisibleFieldsCheckout(service_gift, tipologiaUtenteChiave)
  }

  return []
}

export const isStepRequired = (
  service_gift,
  tipologiaUtenteChiave,
  variant,
  step,
  utente,
  isMerceNeutra
) => {
  switch (variant) {
    case 'registrazione':
      return isStepRegistrazioneRequired(service_gift, tipologiaUtenteChiave, step)
    case 'checkout':
      return isStepCheckoutRequired(
        service_gift,
        tipologiaUtenteChiave,
        step,
        utente,
        isMerceNeutra
      )
  }

  return false
}

export const isGuestAllowed = (service_gift) => {
  if (!service_gift) return false

  if (
    service_gift &&
    service_gift.premium &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_CHECKOUT_GUEST) &&
    service_gift.attiva_checkout_come_guest
  )
    return true

  return false
}

export const isSistemiPagamentoByTipologiaAllowed = (service_gift) => {
  if (!service_gift) return false

  if (
    service_gift &&
    service_gift.premium &&
    service_gift.moduli_extra.includes(MODULI_EXTRA_SISTEMI_PAGAMENTO_DIFFERENZIATI)
  )
    return true

  return false
}

export const hasDifferentRequiredFieldsIndirizzoFatturazione = (
  service_gift,
  tipologiaUtenteChiave
) => {
  if (!service_gift) return false

  const tipologiaUtenteValue = service_gift?.tipologie_utenti.find(
    (x) => x.chiave === tipologiaUtenteChiave
  )

  if (!tipologiaUtenteValue) return false

  const campiObbligatoriSpedizione = tipologiaUtenteValue.campi_obbligatori_checkout
    .filter((x) => x.includes('indirizzo_spedizione.'))
    .map((x) => x.split('.')?.[1] || x)
  const campiObbligatoriFatturazione = tipologiaUtenteValue.campi_obbligatori_checkout
    .filter((x) => x.includes('indirizzo_fatturazione.'))
    .map((x) => x.split('.')?.[1] || x)

  let isDifferent = false
  campiObbligatoriFatturazione.forEach((x) => {
    if (!campiObbligatoriSpedizione.includes(x)) isDifferent = true
  })

  return isDifferent
}
