import * as React from 'react'

function SvgFilter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--filter ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path d="M11 19c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1m10-2h-7.185c-.414-1.161-1.513-2-2.815-2s-2.401.839-2.815 2H3c-.553 0-1 .447-1 1 0 .553.447 1 1 1h5.185c.414 1.161 1.513 2 2.815 2s2.401-.839 2.815-2H21c.553 0 1-.447 1-1 0-.553-.447-1-1-1m-2-4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1m0-4c-1.302 0-2.401.839-2.815 2H3c-.553 0-1 .447-1 1 0 .553.447 1 1 1h13.185c.414 1.161 1.513 2 2.815 2 1.654 0 3-1.346 3-3s-1.346-3-3-3M7 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1M3 7h1.185C4.599 8.161 5.698 9 7 9s2.401-.839 2.815-2H21c.553 0 1-.447 1-1 0-.553-.447-1-1-1H9.815C9.401 3.839 8.302 3 7 3s-2.401.839-2.815 2H3c-.553 0-1 .447-1 1 0 .553.447 1 1 1" />
    </svg>
  )
}

export default SvgFilter
