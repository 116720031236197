import * as React from 'react'

function SvgBin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--bin ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="bin_svg__a"
          d="M7.5 9.167c.458 0 .833.375.833.833v3.333a.836.836 0 01-.833.834.836.836 0 01-.833-.834V10c0-.458.375-.833.833-.833zm5 0c.458 0 .833.375.833.833v3.333a.836.836 0 01-.833.834.836.836 0 01-.833-.834V10c0-.458.375-.833.833-.833zm2.5 6.666c0 .46-.373.834-.833.834H5.833A.835.835 0 015 15.833V6.667h10v9.166zM8.333 3.607c0-.13.179-.274.417-.274h2.5c.238 0 .417.144.417.274V5H8.333V3.607zM17.5 5h-4.167V3.607c0-1.07-.934-1.94-2.083-1.94h-2.5c-1.15 0-2.083.87-2.083 1.94V5H2.5a.836.836 0 00-.833.833c0 .459.375.834.833.834h.833v9.166c0 1.379 1.122 2.5 2.5 2.5h8.334c1.378 0 2.5-1.121 2.5-2.5V6.667h.833a.836.836 0 00.833-.834A.836.836 0 0017.5 5z"
        />
      </defs>
      <use xlinkHref="#bin_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgBin
