import React from 'react'
import { useEffect, useState } from 'react'

import PlaceholderContent from '../PlaceholderContent/PlaceholderContent'

const Placeholder = React.memo(({ placeholder, theme = null }) => {
  const [content, setContent] = useState(null)

  const parseContent = async () => {
    let content = null

    if (placeholder.url) {
      content = (
        <iframe
          className={'pagebuilder-placeholder'}
          src={`${placeholder.url}${theme ? '?theme=' + theme : ''}`}
        />
      )
    } else {
      content = <PlaceholderContent content={placeholder.content} />
    }

    setContent(content)
  }

  useEffect(() => {
    parseContent()
  }, [placeholder])

  const classes = `custom-content ${placeholder.url ? 'active' : placeholder.key} ${
    theme ? 'theme--' + theme : ''
  }`

  return <div className={classes}>{content}</div>
})

export default Placeholder
