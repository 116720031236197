import { NextSeo } from 'next-seo'
import React from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { escape } from '../../../utils/safe'
import { DOMAIN } from '../../../utils/index'
// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = React.memo(({ seo, additionalMetaTags = null }) => {
  const { title, description, image, canonical_url, noindex } = seo

  const { service_gift } = useSelector((state) => state.servicegift)

  const router = useRouter()

  const ogImage = image ? image : ''

  return (
    <NextSeo
      title={escape(title)}
      description={escape(description)}
      canonical={canonical_url}
      languageAlternates={seo.hreflangs}
      noindex={noindex}
      nofollow={noindex}
      additionalMetaTags={additionalMetaTags}
      openGraph={{
        site_name: seo.site_name,
        url: (service_gift ? 'https://' + service_gift.domain : DOMAIN) + router.asPath,
        title: escape(title),
        description: escape(description),
        type: 'website',
        images: [{ url: ogImage }],
      }}
    />
  )
})

export default MetaSeo
