import React, { useState, useEffect } from 'react'
import { Search, Remove, ArrowRight } from '../../atoms/NewIcons'
import { api } from '../../../utils'
import LoadingSvg from '../../atoms/Loading/Loading'
import ProdottoSearchCard from '../../molecules/ProdottoSearchCard/ProdottoSearchCard'
import { Button } from '../../atoms'
import { SEARCH, CRONOLOGIA_RICERCA } from '../../../utils/endpoint'
import Trans from '../../atoms/Trans/Trans'
import { useRouter } from 'next/router'
import useTrans from '../../../hooks/useTrans'

const SearchBar = ({ size, disabled, openSearchResults, setOpenSearchResults }) => {
  const t = useTrans()
  const [status, setStatus] = useState('')
  const [result, setResult] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [cronologia, setCronologia] = useState()
  const [Loading, setLoading] = useState(false)
  const router = useRouter()
  const lang = router.locale
  useEffect(() => {
    api
      .get(CRONOLOGIA_RICERCA, { params: { lang } })
      .then((res) => setCronologia(res?.data))
      .catch((err) => console.log(err))
  }, [])

  function handleValueChange(event) {
    setInputValue(event.target.value)
    setStatus(event.target.value ? 'filled' : '')
  }

  const handleFetch = (searchValue) => {
    setIsFetching(true)

    api
      .get(SEARCH, { params: { q: searchValue, lang: lang } })
      .then((res) => {
        setIsFetching(false)
        setResult(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setIsFetching(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    inputValue?.toString()?.split('')?.length > 3 ? setIsFetching(true) : setIsFetching(false)
    const timeout = setTimeout(() => {
      inputValue && inputValue?.toString()?.split('')?.length > 3 && handleFetch(inputValue)
    }, 500)

    // ! Rimuove il timeout precedente nel caso dovesse essere ritriggerato
    return () => (clearTimeout(timeout), setIsFetching(false))
  }, [inputValue])

  const handleDelete = (searchValue) => {
    setCronologia(undefined)
  }

  return (
    <div
      className={'search-bar search-bar--' + (size ? size : 'lg')}
      onBlur={(e) => {
        if (e.currentTarget === e.target) {
        }
        if (!e.currentTarget.contains(e.relatedTarget)) {
        }
      }}
    >
      <input
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpenSearchResults(false)
            process.browser &&
              window !== undefined &&
              (window.location.href = `/${router.locale}/search/${inputValue}`)
          }

          //(setLoading(true), handleFetch(e.target.value), setOpenSearchResults(true))
        }}
        value={inputValue}
        onClick={() => setOpenSearchResults(true)}
        className="search-bar__input"
        placeholder={t('Cerca per codice articolo,prodotto,categoria...')}
        onChange={handleValueChange}
        disabled={disabled}
      />
      <a className="search-bar__icon">
        {status == 'filled' ? (
          isFetching ? (
            <LoadingSvg width={15} height={15} />
          ) : (
            <Remove onClick={() => (setInputValue(''), setOpenSearchResults(false))} />
          )
        ) : (
          <Search onClick={() => handleFetch(inputValue)} />
        )}
      </a>
      {openSearchResults && (
        <SearchResult
          isFetching={isFetching}
          cronologia={cronologia}
          result={result}
          setInputValue={setInputValue}
          handleFetch={handleFetch}
          handleDelete={handleDelete}
          onMouseLeave={() => setOpenSearchResults(false)}
          inputValue={inputValue}
          Loading={Loading}
        />
      )}
    </div>
  )
}

export default SearchBar

const SearchResult = ({
  result,
  setInputValue,
  handleDelete,
  onMouseLeave,
  inputValue,
  cronologia,
  isFetching,
  Loading,
}) => {
  const router = useRouter()
  const t = useTrans()
  return (
    <div className="search-results" onMouseLeave={onMouseLeave}>
      {/* TODO: DINAMICIZZARE LA CRONOLOGIA */}
      {Loading ? (
        <LoadingSvg />
      ) : (
        <>
          {!result || inputValue.toString().split('').length === 0 ? (
            <>
              {/* CRONOLOGIA DI RICERCA - quando il campo è vuoto */}
              <div className="search-results__cronologia">
                <p className="search-results__title">
                  <Trans chiave="La tua cronologia di ricerca" />
                </p>
                <button
                  className="search-results__reset-cronologia"
                  onClick={() =>
                    api
                      .get('me/delete_cronologia_ricerca/')
                      .then(() => handleDelete())
                      .catch((err) => console.log(err))
                  }
                >
                  <Trans chiave="Cancella tutto" />
                </button>
              </div>
              <div className="search-results__cols">
                <div className="search-results__col">
                  <div className="search-results__list">
                    {cronologia &&
                      cronologia?.ricerche?.map((item, i) => (
                        <a
                          href={`/${router.locale}/search/${item?.query}`}
                          className="search-results__item"
                          key={`cronologia-ricerca-${i}`}
                        >
                          {item?.query}
                        </a>
                      ))}
                  </div>
                </div>
                <div className="search-results__col">
                  {cronologia?.prodotti?.map((prodotto, i) => (
                    <a href={prodotto?.url}>
                      <ProdottoSearchCard prodotto={prodotto} key={`cronologia-prodotto-${i}`} />
                    </a>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="search-results__suggestion">
                {!isFetching && result?.prodotti?.length === 0 && result?.categorie.length === 0 && (
                  <>
                    <Trans chiave="Nessun risultato per" />
                    <strong> {inputValue} </strong>
                  </>
                )}

                {!isFetching && result?.suggestion && (
                  <>
                    <Trans chiave="forse cercavi" />{' '}
                    <a
                      onClick={() => {
                        setInputValue(result?.suggestion)
                      }}
                      className="search-results__suggestion"
                    >
                      <strong>{result?.suggestion}</strong>
                    </a>
                    ?
                  </>
                )}
              </p>

              {result && (
                <div className="search-results__cols">
                  {result?.prodotti?.length > 0 && (
                    <div className="search-results__col">
                      {result?.prodotti?.length > 0 && (
                        <div className="search-results__block">
                          {result?.prodotti?.slice(0, 10)?.map((prodotto, i) => (
                            <a href={prodotto?.url} key={`risultati-prodotto-${i}`}>
                              <ProdottoSearchCard prodotto={prodotto} />
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="search-results__col">
                    {result?.categorie.length > 0 && (
                      <div className="search-results__block">
                        <p className="search-results__title">
                          <Trans chiave="Categorie" />
                        </p>

                        <div className="search-results__list">
                          {result?.categorie?.slice(0, 5).map((categoria, i) => (
                            // <Link href={categoria?.url} shallow={false}>
                            <a href={categoria?.url} className="search-results__item" key={`risultati-categoria-${i}`}>
                              {categoria?.titolo}
                              {categoria?.parent_name && (
                                <span className="search-results__light">
                                  {' '}
                                  <Trans chiave="in" /> {categoria?.parent_name}
                                </span>
                              )}
                            </a>
                            // </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    {result?.autocomplete?.length > 0 && (
                      <div className="search-results__block">
                        <p className="search-results__title">
                          <Trans chiave="I nostri Suggerimenti" />
                        </p>
                        <div className="search-results__list">
                          {result?.autocomplete?.slice(0, 5).map((suggerimento, i) => (
                            <a href={suggerimento?.url} className="search-results__item" key={`risultati-suggerimento-${i}`}>
                              {suggerimento?.titolo}
                              {/* TODO: specificare num risultati o altre specifiche dello zeplin? <span className="search-results__light">testo esempio</span> */}
                              {suggerimento?.parent_name && (
                                <span className="search-results__light">
                                  {' '}
                                  <Trans chiave="in" /> {suggerimento?.parent_name}
                                </span>
                              )}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {result?.prodotti?.length > 0 && (
                <div className="search-results__view-all">
                  <a href={`/${router.locale}/search/${inputValue}`}>
                    <Button
                      variant="ghost"
                      size="large"
                      label={t('Vedi tutti i risultati per ') + `"${inputValue}"`}
                      icon={<ArrowRight />}
                    />
                  </a>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
