import React from 'react'

const Taglia = ({ isActive, taglia, onClick }) => {
  return (
    <p className={`taglia ${isActive && 'taglia__active'}`} onClick={onClick}>
      {taglia}
    </p>
  )
}

export default Taglia
