import * as React from 'react'

function SvgDocuments(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--documenti ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M19.74 8.33l-5.44-6a.997.997 0 00-.74-.33h-7A2.53 2.53 0 004 4.5v15A2.53 2.53 0 006.56 22h10.88A2.53 2.53 0 0020 19.5V9a.994.994 0 00-.26-.67zM14 5l2.74 3h-2a.79.79 0 01-.741-.836L14 7.15V5zm3.44 15H6.56a.53.53 0 01-.56-.498V4.5a.53.53 0 01.558-.5H12v3.15A2.79 2.79 0 0014.71 10H18v9.5a.53.53 0 01-.558.5h-.002z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDocuments
