import { useState } from 'react'
import { displayNotification } from '../components/atoms/Notifica/Notifica'
import { downloadFileFromApi } from '../utils/download'
import useTrans from './useTrans'


export default function useDownloadFromApi() {
  const t = useTrans()
  const [downloading, setDownloading] = useState(null)

  const saveFile = (id, url, filename) => {
    if (downloading === id) return

    setDownloading(id)

    downloadFileFromApi(url, filename)
    .then(res => {
      setDownloading(null)
    })
    .catch((err) => { 
      displayNotification('error', t('Impossibile scaricare il file richiesto'), 'error')
      setDownloading(null)
    })
  }

  return { downloading, saveFile }
}
