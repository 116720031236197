import React, { useEffect, useState } from 'react'
import FormInput from '../../atoms/FormInput/FormInput'
import FormField from '../../atoms/FormField/FormField'
import Button from '../../atoms/Button/Button'
import { useSelector } from 'react-redux'
import { LogoSipec } from '../../atoms/NewIcons'
import { api } from '../../../utils'
import { useRouter } from 'next/router'
import { Formik } from 'formik'
import * as yup from 'yup'
import useTrans from '../../../hooks/useTrans'


const validationSchema = yup.object({
  username: yup.string().required('il campo username è richiesto'),
  password: yup.string().required('Il campo password è richiesto'),
})

const LoginServiceGift = ({ logo }) => {
  const router = useRouter()
  const t = useTrans()
  const [logoImage, setLogoImage] = useState(logo)
  const [error, setError] = useState(false)
  const { service_gift } = useSelector((state) => state.servicegift)

  useEffect(() => {
    service_gift && service_gift?.logo_url && logoImage !== 'logosipec'
      ? setLogoImage(service_gift?.logo_url)
      : ''
  }, [service_gift])

  const login = async (params) => {
    const sgInput = { username: params.username, password: params.password }
    return api.post('/login-servicegift/', sgInput)
  }

  return (
    <div className="login__servicegift">
      <div className="login__servicegift__content">
        {logoImage === 'logosipec' ? (
          <LogoSipec />
        ) : (
          <img src={logoImage} className="login__servicegift__content__image" />
        )}
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            password: '',
            username: '',
          }}
          onSubmit={({ username, password }) => {
            const params = { username, password }
            login(params)
              .then(() => {
                setError(false)
                router.reload()
              })
              .catch((err) => setError(true))
          }}
        >
          {(props) => (
            <>
              <FormField
                label="Username"
                status={
                  props.touched.username && props.errors.username
                    ? 'error'
                    : props.touched.username && 'success'
                }
                errorMessage={props.errors.username}
              >
                <FormInput
                  value={props.values.username}
                  onChange={props.handleChange('username')}
                  onBlur={props.handleBlur('username')}
                />
              </FormField>

              <FormField
                label="Password"
                status={
                  props.touched.password && props.errors.password
                    ? 'error'
                    : props.touched.password && 'success'
                }
                errorMessage={props.errors.password}
              >
                <FormInput
                  type="password"
                  value={props.values.password}
                  onChange={props.handleChange('password')}
                  onBlur={props.handleBlur('password')}
                />
              </FormField>
              {error && <p className="login__servicegift__content__error">{t('Credenziali Errate')}</p>}

              <Button
                label="Accedi"
                variant="primary"
                size="medium"
                onClick={() => props.handleSubmit()}
                disabled={!props.isValid || !props.dirty}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default LoginServiceGift
