import React, { memo, useEffect, useState } from 'react'

const Icon = ({ size, icon_name, tooltip, onClick, cName }) => {
  const [icon, setIcon] = useState('')

  useEffect(async () => {
    let icon_svg = await import(`./assets/${icon_name}.svg`)
    setIcon(icon_svg.default)
  }, [])

  useEffect(async () => {
    let icon_svg = await import(`./assets/${icon_name}.svg`)
    setIcon(icon_svg.default)
  }, [icon_name])

  if (tooltip) {
    return (
      <>
        <div className="image__position">
          <img src={icon}></img>

          <p className="icon_container">
            {tooltip}
            <span className="icon_container__arrow_up"></span>
          </p>
        </div>
      </>
    )
  }

  return <img className={cName ? cName : ''} onClick={onClick} src={icon} />
}

export default React.memo(Icon)
