import React, { useEffect, useState } from 'react'

export const AuthContext = React.createContext()

const AuthContextProvider = (props) => {
  const [isAuth, setAuth] = useState()

  useEffect(() => {
    setAuth(localStorage.getItem('auth-tokens-development') ? true : false)
  }, [])

  return <AuthContext.Provider value={{ isAuth, setAuth }}>{props.children}</AuthContext.Provider>
}

export default AuthContextProvider
