import _ from 'lodash'

export const calcoloMarkup = ({ type, value, moltiplicatore = 1, totale }) => {
  switch (type) {
    case '%':
      return Number((1 + value / 100) * parseFloat(totale)).toFixed(2)
    case '€':
      return Number(parseFloat(totale) + parseFloat(value) * moltiplicatore).toFixed(2)
  }
}

const cacoloTotaleAltriCosti = (altri_costi, global) => {
  const result = parseFloat(
    Object.values(altri_costi).reduce((p, acc) => {
      return (p || 0) + (parseFloat(global ? acc?.valore_carrello : acc?.totale_riga) || 0)
    }, 0)
  )
  return result
}

const calcoloTotaleQuotazione = (articoli, altri_costi) => {
  const totale_altri_costi_originali = cacoloTotaleAltriCosti(altri_costi, true)
  const totale_altri_costi = cacoloTotaleAltriCosti(altri_costi)
  const totale = Object.values(articoli)?.reduce((p, acc) => {
    return p + parseFloat(acc?.totale_riga)
  }, 0)

  return parseFloat(totale + (totale_altri_costi || 0)).toFixed(2)
}

const initArticoliFromOfferta = (payload) => {
  const getRigaPersonalizzata = (id) => {
    const riga = payload?.personalizzazioni_offerta?.filter(
      (personalizzazione) => personalizzazione?.id === id
    )?.[0]

    return riga
  }
  const result = payload?.righe_offerta
    ?.map((riga) => ({
      [riga?.articolo?.codice + (riga?.personalizzazione_carrello?.id ? `_${riga?.personalizzazione_carrello?.id}` : "")]: {
        rigaId: riga?.id,
        value: (riga?.markup_percentuale ? riga?.markup_percentuale : riga?.markup_valore) || 0,
        totale_riga: calcoloMarkup({
          value: (riga?.markup_percentuale ? riga?.markup_percentuale : riga?.markup_valore) || 0,
          type: riga?.markup_percentuale ? '%' : '€',
          totale: parseFloat(riga?.totale),
        }),
        type: riga?.markup_percentuale ? '%' : '€',
      },
      ...(riga?.personalizzazione_carrello && {
        [riga?.personalizzazione_carrello?.id]: {
          value:
            (getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_percentuale
              ? getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_percentuale
              : getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_valore) || 0,
          type: riga?.markup_percentuale ? '%' : '€',
          rigaId: riga?.id,
          personalizzazione_carrello_id: riga?.personalizzazione_carrello_id,
          totale_riga: calcoloMarkup({
            value:
              (getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_percentuale
                ? getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_percentuale
                : getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_valore) || 0,

            type: getRigaPersonalizzata(riga?.personalizzazione_carrello_id)?.markup_percentuale
              ? '%'
              : '€',
            totale: parseFloat(payload?.personalizzazioni_carrello?.find((p) => p.id === riga?.personalizzazione_carrello_id)?.totale_personalizzazione),
          }),
        },
      }),
    }))
    .reduce((p, c) => {
      const isObject = !!(typeof c === 'object')
      return { ...p, ...(isObject && { ...c }) }
    }, {})

  return result
}

const initArticoli = (payload, markup) => {
  const result = payload?.righe_carrello
    ?.map((riga) => ({
      [riga?.articolo?.codice + (riga?.personalizzazione_carrello?.id ? `_${riga?.personalizzazione_carrello?.id}` : "")]: {
        value: markup?.markup_merce_neutra || 0,
        rigaId: riga?.id,
        totale_riga: calcoloMarkup({
          value: markup?.markup_merce_neutra || 0,
          type: '%',
          totale: parseFloat(riga?.totale),
        }),
        type: '%',
      },
      ...(riga?.personalizzazione_carrello && {
        [riga?.personalizzazione_carrello?.id]: {
          value: markup?.markup_personalizzazione || 0,
          type: '%',
          rigaId: riga?.id,
          personalizzazione_carrello_id: riga?.personalizzazione_carrello_id,
          totale_riga: calcoloMarkup({
            value: markup?.markup_personalizzazione || 0,
            type: '%',
            totale: parseFloat(payload?.personalizzazioni_carrello?.find((p) => p.id === riga?.personalizzazione_carrello_id)?.totale_personalizzazione),
          }),
        },
      }),
    }))
    .reduce((p, c) => {
      const isObject = !!(typeof c === 'object')

      return { ...p, ...(isObject && { ...c }) }
    }, {})

  return result
}

const initTotaleQuotazione = (tipologiaMarkup, articoli, altri_costi, calcoloMarkupTotale) => {
  return tipologiaMarkup === 'sul_totale'
    ? parseFloat(
        calcoloMarkup({
          type: calcoloMarkupTotale?.type,
          totale: parseFloat(calcoloMarkupTotale?.totale || 0),
          value: calcoloMarkupTotale?.value,
        })
      ) + cacoloTotaleAltriCosti(_.pick(altri_costi, 'trasporto'))
    : calcoloTotaleQuotazione(articoli, altri_costi)
}

export default function reducer(
  state = {
    articoli: {},
    totale_quotazione: 0,
    tuoiDati: {},
    altri_costi: { trasporto: 0, impianti: 0, gestione: 0 },
    type: '%',
    markup: 0,
    tipologiaMarkup: 'sul_singolo',
    logoFile: null,
    logoId: null,
    totale_imponibile_carrello: 0,
  },
  action
) {
  switch (action.type) {
    case 'RESET_STATE':
      return {
        articoli: {},
        totale_quotazione: 0,
        tuoiDati: {},
        altri_costi: { trasporto: 0, impianti: 0, gestione: 0 },
        type: '%',
        markup: 0,
        tipologiaMarkup: 'sul_singolo',
        logoFile: null,
        logoId: null,
        totale_imponibile_carrello: 0,
      }
    case 'SAVE_TUOI_DATI':
      return { ...state, tuoiDati: action?.payload?.tuoiDati }

    case 'SAVE_LOGO_FILE':
      return { ...state, logoFile: action?.payload?.logoFile, logoId: null }
    case 'SAVE_LOGO_ID':
      return { ...state, logoId: action?.payload?.logoId, logoFile: null }

    case 'INIT_ARTICOLI_FROM_FETCHED':
      const totale_imponibile_carrello = action?.payload?.carrello?.totale_imponibile
      const initied_totale_quotazione = initTotaleQuotazione(
        action?.payload?.carrello?.markup_sul_totale > 0 ||
          action?.payload?.carrello?.markup_sul_totale_percentuale > 0
          ? 'sul_totale'
          : 'sul_singolo',
        initArticoliFromOfferta(action.payload.carrello),
        action?.payload?.altri_costi,
        {
          totale: totale_imponibile_carrello,
          type: action?.payload?.carrello?.markup_sul_totale_percentuale > 0 ? '%' : '€',
          value:
            action?.payload?.carrello?.markup_sul_totale_percentuale ||
            action?.payload?.carrello?.markup_sul_totale,
        }
      )
      return {
        ...state,
        tuoiDati: {
          nome: action?.payload?.carrello?.nome,
          email: action?.payload?.carrello?.email,
          riferimento: action?.payload?.carrello?.riferimento,
          telefono: action?.payload?.carrello?.telefono,
          indirizzo: action?.payload?.carrello?.indirizzo,
          created: action?.payload?.carrello?.created,
        },
        markup:
          action?.payload?.carrello?.markup_sul_totale ||
          action?.payload?.carrello?.markup_sul_totale_percentuale,
        logoId: action?.payload?.logoId,
        totale_quotazione: initied_totale_quotazione,
        tipologiaMarkup:
          action?.payload?.carrello?.markup_sul_totale > 0 ||
          action?.payload?.carrello?.markup_sul_totale_percentuale > 0
            ? 'sul_totale'
            : 'sul_singolo',
        ...(action?.payload?.altri_costi && { altri_costi: { ...action?.payload?.altri_costi } }),
        articoli: initArticoliFromOfferta(action.payload.carrello),
        totale_imponibile_carrello: totale_imponibile_carrello,
      }
    case 'INIT_ARTICOLI':
      const {
        markup_avviamento,
        markup_impianti,
        markup_merce_neutra,
        markup_personalizzazione,
        markup_sul_totale,
        markup_trasporti,
        markup_tracciatura,
        markup_tracciatura_logo_percentuale,
        markup_impianti_percentuale,
        markup_avviamento_percentuale,
      } = action?.payload?.markupFields || {}

      const initialized_articoli = initArticoli(action.payload.carrello, {
        markup_merce_neutra,
        markup_personalizzazione,
      })
      return {
        ...state,
        ...(action?.payload?.altri_costi && { altri_costi: { ...action?.payload?.altri_costi } }),
        articoli: initialized_articoli,
        markup: markup_sul_totale,
        totale_imponibile_carrello: action?.payload?.carrello?.totale_imponibile,
        altri_costi: {
          tracciatura: {
            valore_carrello: action?.payload?.carrello?.valore_tracciatura_logo || 0,
            markup: markup_tracciatura || markup_tracciatura_logo_percentuale || 0,
            type: markup_tracciatura_logo_percentuale > 0 ? '%' : '€',
            totale_riga: calcoloMarkup({
              type: markup_tracciatura_logo_percentuale > 0 ? '%' : '€',
              totale: action?.payload?.carrello?.valore_tracciatura_logo || 0,
              value: markup_tracciatura || markup_tracciatura_logo_percentuale || 0,
              moltiplicatore: action?.payload?.carrello?.numero_tracciature_logo || 0,
            }),
          },
          trasporto: { markup: markup_trasporti || 0, type: '€', totale_riga: markup_trasporti }, // ! controllare come modificare
          impianti: {
            valore_carrello: action?.payload?.carrello?.valore_impianto || 0,
            markup: markup_impianti || markup_impianti_percentuale || 0,
            type: markup_impianti_percentuale > 0 ? '%' : '€',
            totale_riga: calcoloMarkup({
              type: markup_impianti_percentuale > 0 ? '%' : '€',
              totale: action?.payload?.carrello?.valore_impianto || 0,
              value: markup_impianti || markup_impianti_percentuale || 0,
              moltiplicatore: action?.payload?.carrello?.numero_impianti || 0,
            }),
          },
          gestione: {
            valore_carrello: action?.payload?.carrello?.valore_gestione || 0,
            markup: markup_avviamento || markup_avviamento_percentuale || 0,
            type: markup_avviamento_percentuale > 0 ? '%' : '€',
            totale_riga: calcoloMarkup({
              type: markup_avviamento_percentuale > 0 ? '%' : '€',
              totale: action?.payload?.carrello?.valore_gestione || 0,
              value: markup_avviamento || markup_avviamento_percentuale || 0,
              moltiplicatore:  action?.payload?.carrello?.valore_gestione > 0 ? 1 : 0,
            }),
          },
        },
      }
    case 'CONFERMA_TOTALE':
      return {
        ...state,
        totale_quotazione:
          state.tipologiaMarkup === 'sul_totale'
            ? parseFloat(
                calcoloMarkup({
                  type: state?.type,
                  totale: state.totale_imponibile_carrello,
                  value: state?.markup,
                })
              ) + cacoloTotaleAltriCosti(_.pick(state?.altri_costi, 'trasporto'))
            : calcoloTotaleQuotazione(state?.articoli, state?.altri_costi),
      }
    case 'CHANGE_TIPOLOGIA_MARKUP':
      return {
        ...state,
        tipologiaMarkup: action.payload.tipologia,
        totale_quotazione:
          action?.payload?.tipologia === 'sul_totale'
            ? parseFloat(
                calcoloMarkup({
                  type: state?.type,
                  value: state?.markup,
                  totale: parseFloat(state.totale_imponibile_carrello || 0),
                })
              ) + parseFloat(cacoloTotaleAltriCosti(_.pick(state?.altri_costi, 'trasporto')) || 0)
            : calcoloTotaleQuotazione(state?.articoli, state?.altri_costi),
      }
    case 'CHANGE_TYPE':
      return {
        ...state,
        type: action?.payload?.type,
        totale_quotazione:
          parseFloat(
            calcoloMarkup({
              value: state?.markup,
              type: action?.payload?.type || '%',
              totale: parseFloat(state.totale_imponibile_carrello || 0),
            })
          ) + cacoloTotaleAltriCosti(_.pick(state?.altri_costi, 'trasporto')),
      }

    case 'CHANGE_MARKUP_RIGA_ALTRI_COSTI':
      if (action?.payload) {
        const { field, markup, totale_riga, moltiplicatore } = action?.payload
        const altri_costi_updated = {
          ...state?.altri_costi,
          [field]: {
            ...state?.altri_costi[field],
            markup: markup,
            totale_riga: calcoloMarkup({
              value: markup || 0,
              type: state?.altri_costi[field]?.type || '€',
              totale: totale_riga || 0,
              moltiplicatore: moltiplicatore || 0,
            }),
          },
        }
        return {
          ...state,
          altri_costi: altri_costi_updated,
          totale_quotazione: calcoloTotaleQuotazione(state?.articoli, altri_costi_updated),
        }
      }
      return { ...state }
    case 'CHANGE_TYPE_RIGA_ALTRI_COSTI':
      if (action?.payload) {
        const { field, type, totale_riga, moltiplicatore } = action?.payload
        const altri_costi_updated = {
          ...state?.altri_costi,
          [field]: {
            ...state?.altri_costi[field],
            type: type,
            totale_riga: calcoloMarkup({
              value: state?.altri_costi[field]?.markup || 0,
              type: type,
              totale: totale_riga || 0,
              moltiplicatore: moltiplicatore || 0,
            }),
          },
        }
        return {
          ...state,
          altri_costi: altri_costi_updated,
          totale_quotazione: calcoloTotaleQuotazione(state?.articoli, altri_costi_updated),
        }
      }
      return { ...state }
    case 'CHANGE_TYPE_RIGA':
      const articoli_updated_type = {
        ...state?.articoli,
        [action?.payload?.codice]: {
          ...state?.articoli?.[action?.payload?.codice],
          type: action?.payload?.type,
          rigaId: action?.payload?.rigaId,
          totale_riga: calcoloMarkup({
            value: parseFloat(state?.articoli?.[action?.payload?.codice]?.value),
            type: action?.payload?.type || '%',
            totale: parseFloat(action?.payload?.totale),
          }),
        },
      }
      return {
        ...state,
        totale_quotazione: calcoloTotaleQuotazione(articoli_updated_type, state?.altri_costi),
        articoli: articoli_updated_type,
      }
    case 'CHANGE_MARKUP_RIGA':
      const articoli_updated_markup = {
        ...state?.articoli,
        [action?.payload?.codice]: {
          ...state?.articoli?.[action?.payload?.codice],
          value: action?.payload?.value,
          type: state?.articoli?.[action?.payload?.codice]?.type || '%',
          rigaId: action?.payload?.rigaId,
          totale_riga: calcoloMarkup({
            value: action?.payload?.value,
            type: state?.articoli?.[action?.payload?.codice]?.type || '%',
            totale: action?.payload?.totale,
          }),
        },
      }
      return {
        ...state,
        totale_quotazione: calcoloTotaleQuotazione(articoli_updated_markup, state?.altri_costi),
        articoli: articoli_updated_markup,
      }
    case 'CHANGE_MARKUP':
      return {
        ...state,
        markup: action?.payload?.value || 0,
        totale_quotazione:
          parseFloat(
            calcoloMarkup({
              type: state?.type || '%',
              value: action?.payload?.value || 0,
              totale: parseFloat(state.totale_imponibile_carrello || 0),
            })
          ) + parseFloat(cacoloTotaleAltriCosti(_.pick(state?.altri_costi, 'trasporto')) || 0),
      }
    case 'CHANGE_MARKUP_ALTRI_COSTI':
      const altri_costi_updated = {
        ...state?.altri_costi,
        [action?.payload?.field]: {
          type: '€',
          markup: action?.payload?.value,
          totale_riga: action?.payload?.value,
        },
      }

      return {
        ...state,
        altri_costi: altri_costi_updated,
        totale_quotazione:
          state?.tipologiaMarkup === 'sul_singolo'
            ? calcoloTotaleQuotazione(state?.articoli, altri_costi_updated)
            : parseFloat(
                calcoloMarkup({
                  totale: parseFloat(state.totale_imponibile_carrello || 0),
                  type: state?.type,
                  value: state?.markup,
                })
              ) + cacoloTotaleAltriCosti(_.pick(altri_costi_updated, 'trasporto')),
      }
    default: {
      return state
    }
  }
}
