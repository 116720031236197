import * as React from 'react'

function SvgArrowLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--arrow-left ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="arrow-left_svg__a"
          d="M15.833 9.167H5.946l3.027-3.634a.833.833 0 10-1.28-1.066l-4.166 5c-.033.039-.05.085-.074.128-.02.035-.044.065-.059.103a.835.835 0 00-.06.299v.006c0 .102.023.203.06.299.015.038.04.068.06.103.023.043.04.09.073.128l4.166 5a.831.831 0 001.174.107.833.833 0 00.106-1.173l-3.027-3.634h9.887a.834.834 0 100-1.666"
        />
      </defs>
      <use xlinkHref="#arrow-left_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgArrowLeft
