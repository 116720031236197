import * as React from 'react'

function SvgTimer(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={'icon icon--timer ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#04ACEF"
            d="M10 20c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1h5zm-3-4c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1s.448-1 1-1h4zm3-4c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1h5z"
          />
          <path
            fill="#038ABF"
            d="M21 4c.552 0 1 .448 1 1s-.448 1-1 1h-1l.001 2.05C25.054 8.551 29 12.814 29 18c0 5.523-4.477 10-10 10S9 23.523 9 18c0-5.185 3.947-9.449 9-9.95V6h-1c-.552 0-1-.448-1-1s.448-1 1-1h4zm-2 6c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
          />
          <path
            fill="#82DAFD"
            d="M19 13c.552 0 1 .448 1 1v3h3c.552 0 1 .448 1 1s-.448 1-1 1h-4c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgTimer
