import * as React from 'react'

function SvgMinusCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--minus-circle ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-minus-circle_svg__a"
          d="M15 11H9c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1m-3 9c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2"
        />
      </defs>
      <use xlinkHref="#24-minus-circle_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgMinusCircle
