import React, { useState } from 'react'
import MyImage from '../../atoms/MyImage/MyImage'
import { ChevronRight } from '../../atoms/NewIcons'
import Link from 'next/link'

const AltreCategorieMenu = ({ categorie, setCurrentItem }) => {
  const [hoveredItem, setHoveredItem] = useState()

  const AltreCategorieMenuItem = ({ onMouseEnter, categoria }) => {
    return (
      <Link href={categoria.url}>
        <div onMouseEnter={onMouseEnter} className="acm__item" onClick={() => setCurrentItem(null)}>
          <p className="acm__item__title">{categoria.nome}</p>
          <span className="acm__item__icon">
            <ChevronRight />
          </span>
        </div>
      </Link>
    )
  }
  return (
    <div
      className="submenu altrecategoriemenu"
      onMouseLeave={() => {
        setHoveredItem()
        setCurrentItem(null)
      }}
    >
      <div className="altrecategoriemenu__content">
        <div className="altrecategoriemenu__items">
          {categorie &&
            categorie.map((categoria) => (
              <AltreCategorieMenuItem
                key={categoria.nome}
                onMouseEnter={() => setHoveredItem(categoria)}
                categoria={categoria}
              />
            ))}
        </div>
        <div className="altrecategoriemenu__subitems">
          <div className="abbigliamento__menu__content">
            <div className="abbigliamento__menu__content__categorie">
              {hoveredItem?.children &&
                hoveredItem.children
                  .filter((subcategoria) => subcategoria.numero_prodotti > 0)
                  .map((subcategoria, index) => (
                    <>
                      <div className="abbigliamento__menu__categoria">
                        <div className="abbigliamento__menu__categoria__image-box">
                          {subcategoria.main_image_url && (
                            <MyImage
                              className="abbigliamento__menu__categoria__image"
                              src={subcategoria.main_image_url}
                              alt={subcategoria.nome}
                              width={48}
                              height={48}
                            />
                          )}
                        </div>
                        {subcategoria.url && (
                          <Link href={subcategoria.url}>
                            <a
                              className="abbigliamento__menu__categoria__title"
                              onClick={() => setCurrentItem(null)}
                            >
                              {subcategoria.nome}
                              <span className="abbigliamento__menu__categoria__num-prod">
                                ({subcategoria.numero_prodotti})
                              </span>
                            </a>
                          </Link>
                        )}
                      </div>
                    </>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltreCategorieMenu
