import * as React from 'react'

function SvgPosizioneLoghi2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 89"
      className={'icon icon--posizione-loghi-2 ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#04ACEF"
                d="M3.2 15.2H60.800000000000004V48.8H3.2z"
                transform="translate(-284 -2022) translate(284 2022) translate(16)"
              />
              <text
                fill="#131518"
                fontFamily="OpenSans-Bold, Open Sans"
                fontSize="14"
                fontWeight="bold"
                transform="translate(-284 -2022) translate(284 2022) translate(16)"
              >
                <tspan x="27.17" y="36.6">
                  A
                </tspan>
              </text>
            </g>
            <g>
              <path
                fill="#82DAFD"
                d="M3.2 15.2H60.800000000000004V48.8H3.2z"
                transform="translate(-284 -2022) translate(284 2022) translate(0 25)"
              />
              <text
                fill="#131518"
                fontFamily="OpenSans-Bold, Open Sans"
                fontSize="14"
                fontWeight="bold"
                transform="translate(-284 -2022) translate(284 2022) translate(0 25)"
              >
                <tspan x="27.17" y="36.6">
                  A
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgPosizioneLoghi2
