import * as React from 'react'

function SvgCartNormal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--cart-normal ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M19 19.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-10 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7.382-5.5H8.764L7.127 8h12.255l-3 6zm4.701-7.052A1.986 1.986 0 0019.382 6h-12.8l-.617-2.263A1 1 0 005 3H3a1 1 0 100 2h1.236l2.799 10.263A1 1 0 008 16h9c.379 0 .725-.214.895-.553l3.276-6.553a1.988 1.988 0 00-.088-1.946z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCartNormal
