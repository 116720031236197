import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Close } from '../NewIcons'

export function displayNotification(
  NotificaId,
  placeholder,
  type = 'default',
  onClick,
  onClickLabel
) {
  if (!toast.isActive(NotificaId)) {
    NotificaId = toast(
      onClickLabel ? (
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: 10 }}>{placeholder}</p>
          <p style={{ textDecoration: 'underline' }}>{onClickLabel}</p>
        </div>
      ) : (
        `${placeholder}`
      ),
      {
        className: `notifica notifica__container ${type === 'error' && 'notifica__error'} ${
          type === 'success' && 'notifica__success'
        }`,
        closeOnClick: onClick ? false : true,
        toastId: NotificaId,
        onClick: onClick ? onClick : null,
        autoClose: 5000,
        hideProgressBar: true,
        closeButton: CloseButton,
        position: toast.POSITION.TOP_RIGHT,
      }
    )
  } else {
    console.log('Notifica già aperta')
  }
}

const CloseButton = ({ closeToast }) => (
  <div className="notifica__close" onClick={() => closeToast()}>
    <Close />
  </div>
)
//da modificare la grandezza una volta disponibile su zeplin
//mantenere lo sfondo grigio finchè non ci saranno gli svg icon in bianco e accent
const Notifica = ({ leftIcon, rightIcon, Show, onClick, placeholder = 'Notification' }) => {
  const { width } = useWindowDimensions()
  return (
    <>
      <ToastContainer style={{ width: width < 600 ? width : 468 }} />
    </>
  )
}

export default Notifica
