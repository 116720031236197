import React, { useState } from 'react'
import { Trans } from '../../../components/index'
import MyImage from '../../atoms/MyImage/MyImage'
import { Book, ChevronRight, Download } from '../../../components/atoms/NewIcons'
import Link from 'next/link'
import useTrans from '../../../hooks/useTrans'
import { trackingDownload } from '../../../utils/gtm'

const CataloghiTemplate = (props) => {
  const t = useTrans()
  const cataloghi = props.cataloghi.map((obj) => {
    return (
      <div className="cataloghi-listing__item" key={'catalogo_' + obj.id}>
        <div className="cataloghi-card">
          <div className="cataloghi-card__image-box">
            <MyImage
              src={obj.immagine_url}
              alt={obj.titolo}
              width={290}
              height={325}
              layout="responsive"
            />
          </div>
          <h3 className="cataloghi-card__title">{obj.titolo}</h3>
          <p className="cataloghi-card__description">{obj.descrizione}</p>
          <div className="cataloghi-card__buttons" style={{ position: 'relative' }}>
            {(obj?.file_prezzi_url || obj?.file_senza_prezzi) && (
              <DropdownMenu
                variant="catalogo"              
                icon={<Download />}
                label={t('Scarica')}
                Options={[
                  ...(obj?.file_prezzi_url ? [{
                    label: t('Scarica catalogo con prezzi'),
                    onClick: () => {
                      trackingDownload('cataloghi', obj?.titolo)
                    },
                    type: 'download',
                    link: obj?.file_prezzi_url,
                  }] : []),
                  ...(obj?.file_senza_prezzi ? [{
                    label: t('Scarica catalogo senza prezzi'),
                    onClick: () => {
                      trackingDownload('cataloghi', obj?.titolo)
                    },
                    type: 'download',
                    link: obj?.file_senza_prezzi,
                  }] : []),
                ]}
              />
            )}
            {(obj?.link_sfogliabile_prezzi || obj?.link_sfogliabile_senza_prezzi) && (
              <DropdownMenu
                variant="catalogo" 
                icon={<Book />}
                label={t('Sfoglia')}
                Options={[
                  ...(obj?.link_sfogliabile_prezzi ? [{
                    label: t('Sfoglia catalogo con prezzi'),
                    onClick: () => {
                      trackingDownload('cataloghi', obj?.titolo)
                    },
                    type: 'blank',
                    link: obj?.link_sfogliabile_prezzi,
                  }] : []),
                  ...(obj?.link_sfogliabile_senza_prezzi ? [{
                    label: t('Sfoglia catalogo senza prezzi'),
                    onClick: () => {
                      trackingDownload('cataloghi', obj?.titolo)
                    },
                    type: 'blank',
                    link: obj?.link_sfogliabile_senza_prezzi,
                  }] : []),
                ]}
              />
            )}
          </div>
        </div>
      </div>
    )
  })
  return (
    <div className="w-container">
      <div className="cataloghi-header">
        <h1 className="page__title">{t('Cataloghi e materiali promozionali')}</h1>
        <div className="cataloghi-listing">
          <h2 className="cataloghi-listing__title">
            <Trans chiave="Cataloghi" />
          </h2>
          <div className="cataloghi-listing__list">{cataloghi}</div>
        </div>
      </div>
    </div>
  )
}

export default CataloghiTemplate

export const DropdownMenu = ({ variant, Options, label, icon, IconClass }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className={'link-utili-catalogo'} style={{ marginTop: -16 }}>
      <div className="link-utili-catalogo__content" style={{ position: 'relative' }}>
        <div
          className="link-utili-catalogo__item link-utili-catalogo__item--download"
          onClick={() => setOpen(!isOpen)}
        >
          <span className={IconClass ? IconClass : 'link-utili-catalogo__icon'}>{icon}</span>
          {label}
        </div>

        <div
          className={'download-menu' + (isOpen ? ' download-menu--open' : '') + (variant ? ` download-menu--${variant}` : '')}
          onMouseLeave={() => setOpen(false)}
        >
          {Options?.map((item, k) => (
            <ul className="download-menu__list" key={k}>
              <li className="download-menu__item">
                {item?.separatore && (
                  <div
                    style={{
                      borderTop: 'solid 1px',
                      width: '100%',
                      paddingBottom: '10px',
                      marginTop: '10px',
                    }}
                  ></div>
                )}
                <div
                  className="download-menu__inner-item"
                  onClick={item?.onClick}
                  style={{ cursor: 'pointer' }}
                >
                  {item?.type === 'download' && (
                    <a download target="_blank" href={item?.link}>
                      {item?.label}
                    </a>
                  )}

                  {item?.type === 'blank' && (
                    <a target="_blank" href={item?.link}>
                      {item?.label}
                    </a>
                  )}

                  {item?.type === 'link' && (
                    <Link href={item?.link}>
                      <a>{item?.label}</a>
                    </Link>
                  )}

                  {!item?.type && item?.label}
                </div>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  )
}

export const CatalogoCard = ({ obj }) => {
  const t = useTrans()
  return (
    <div className="cataloghi-listing__item">
      <div className="cataloghi-card">
        <div className="cataloghi-card__image-box">
          <MyImage
            src={obj.immagine_url}
            alt={obj.titolo}
            width={290}
            height={325}
            layout="responsive"
          />
        </div>
        <h3 className="cataloghi-card__title">{obj.titolo}</h3>
        <p className="cataloghi-card__description">{obj.descrizione}</p>
        <div className="cataloghi-card__buttons" style={{ position: 'relative' }}>
          {(obj?.file_prezzi_url || obj?.file_senza_prezzi) && (
            <DropdownMenu
              variant="catalogo" 
              icon={<Download />}
              label={t('Scarica')}
              Options={[
                ...(obj?.file_prezzi_url ? [{
                  label: t('Scarica catalogo con prezzi'),
                  onClick: () => {
                    trackingDownload('cataloghi', obj?.titolo)
                  },
                  type: 'download',
                  link: obj?.file_prezzi_url,
                }] : []),
                ...(obj?.file_senza_prezzi ? [{
                  label: t('Scarica catalogo senza prezzi'),
                  onClick: () => {
                    trackingDownload('cataloghi', obj?.titolo)
                  },
                  type: 'download',
                  link: obj?.file_senza_prezzi,
                }] : []),
              ]}
            />
          )}
          {(obj?.link_sfogliabile_prezzi || obj?.link_sfogliabile_senza_prezzi) && (
            <DropdownMenu
              variant="catalogo" 
              icon={<Book />}
              label={t('Sfoglia')}
              Options={[
                ...(obj?.link_sfogliabile_prezzi ? [{
                  label: t('Sfoglia catalogo con prezzi'),
                  onClick: () => {
                    trackingDownload('cataloghi', obj?.titolo)
                  },
                  type: 'blank',
                  link: obj?.link_sfogliabile_prezzi,
                }] : []),
                ...(obj?.link_sfogliabile_senza_prezzi ? [{
                  label: t('Sfoglia catalogo senza prezzi'),
                  onClick: () => {
                    trackingDownload('cataloghi', obj?.titolo)
                  },
                  type: 'blank',
                  link: obj?.link_sfogliabile_senza_prezzi,
                }] : []),
              ]}
            />
          )}
        </div>
      </div>
    </div>
  )
}
