import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { UTENTE } from '../../../utils/endpoint'
import { api } from '../../../utils'
import { Trans } from '../../atoms'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Toolbar = (props) => {
  const { utente } = useSelector((state) => state.utente)
  const { toolbarPage = null, toolbarItem = null } = props
  const router = useRouter()

  const toolbar = useSelector((state) => state.toolbar)

  if (!(utente.is_staff || utente.is_staff_sg || utente.is_impersonate)) return null

  const [loading, setLoading] = useState(false)

  const handleSwitchMode = (edit) => {
    api
      .post(UTENTE + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    router.events.on('routeChangeStart', () => setLoading(true))
    router.events.on('routeChangeComplete', () => setLoading(false))
  }, [])

  return (
    <div className="toolbar">
      {utente.is_impersonate ? (
        <div className="toolbar__user">
          Stai impersonando {utente.nome} {utente.cognome} -{' '}
          <a href="/d/impersonate/stop/">Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <div className="toolbar__user">
            {utente.nome} {utente.cognome}
          </div>
          <section className="toolbar__left"></section>
          <section className="toolbar__right">
            {toolbarItem && toolbarItem.model_class && toolbarItem.admin_url && (
              <a href={toolbarItem.admin_url} className="button button--tertiary" target="_blank">
                Modifica {toolbarItem.model_class?.capitalize()}
              </a>
            )}
            {toolbarPage && toolbarPage.admin_url && (
              <a href={toolbarPage.admin_url} className="button button--tertiary" target="_blank">
                <Trans chiave="Admin Pagina" />
              </a>
            )}
            {loading ? (
              <button className="button button--primary button--red toolbar__right__caricamento">
                LOADING
              </button>
            ) : utente.edit ? (
              <button
                className="button button--primary toolbar__right__pubblicata"
                onClick={() => handleSwitchMode(false)}
              >
                <Trans chiave="VEDI PUBBLICATA" />
              </button>
            ) : (
              <button
                className="button button--primary button--red toolbar__right__modifica"
                onClick={() => handleSwitchMode(true)}
              >
                <Trans chiave="MODIFICA" />
              </button>
            )}
          </section>
        </>
      )}
    </div>
  )
}

export default Toolbar
