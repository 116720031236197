import React from 'react'
import Icon from '../../atoms/Icon/Icon'
import LoadingSvg from '../Loading/Loading'

const Button = ({
  label,
  variant,
  colorVariant,
  size,
  responsive,
  icon,
  bg,
  color,
  icon_name,
  icon_size,
  icon_pos,
  onClick,
  disabled,
  className,
  type,
  href,
  target,
  loading,
  noPointer,
}) => {
  const ButtonIcon = () => (icon ? icon : <Icon icon_name={icon_name} size={icon_size} />)
  return href ? (
    <a
      href={href}
      target={target}
      className={
        `button button__${variant ? variant : 'ghost'} button__${size ? size : 'medium'}` +
        (responsive ? ' button__' + size + '--responsive' : '') +
        (colorVariant ? ' button--' + colorVariant : '') +
        (className ? ' ' + className : '')
      }
      style={{
        order: 0,

        backgroundColor: bg ? bg : '',
        color: color ? color : '',
      }}
    >
      <div className="button__content">
        {(icon || icon_name) && (
          <div className={`button__icon button__icon--${icon_pos === 'left' ? 'left' : 'right'}`}>
            <ButtonIcon style={{ alignSelf: 'center' }} />
          </div>
        )}
        <span className="button__label">{label}</span>
      </div>
    </a>
  ) : (
    <button
      type={type}
      disabled={disabled}
      onClick={(e) => {
        if (!loading && !!onClick) onClick(e)
      }}
      className={
        `button button__${variant ? variant : 'ghost'} button__${size ? size : 'medium'}` +
        (responsive ? ' button__' + size + '--responsive' : '') +
        (colorVariant ? ' button--' + colorVariant : '') +
        (noPointer ? ' button__no-pointer' : '') +
        (className ? ' ' + className : '')
      }
      style={{
        order: 0,
        backgroundColor: bg ? bg : '',
        color: color ? color : '',
      }}
    >
      <div className="button__content">
        {loading ? (
          <div className={`button__icon button__icon--${icon_pos === 'left' ? 'left' : 'right'}`}>
            <div style={{ alignSelf: 'center' }}>
              <LoadingSvg width={24} color={variant === 'primary' || !variant ? 'white' : null} />
            </div>
          </div>
        ) : (
          (icon || icon_name) && (
            <div className={`button__icon button__icon--${icon_pos === 'left' ? 'left' : 'right'}`}>
              <ButtonIcon style={{ alignSelf: 'center' }} />
            </div>
          )
        )}
        <span className="button__label">{label}</span>
      </div>
    </button>
  )
}

export default React.memo(Button)
