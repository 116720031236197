import * as React from 'react'

function SvgChevronRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--chevron-right ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M13.586 12L9.293 7.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L13.586 12z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronRight
