import React, { Fragment } from 'react'
import { BreadcrumbJsonLd } from 'next-seo'
import { ChevronRight } from '../../atoms/NewIcons'
import Link from 'next/link'
const Breadcrumbs = React.memo(({ breadcrumbs = [] }) => {
  const BreadCrumbsSeparator = () => {
    return (
      <li className="breadcrumbs__separator">
        <ChevronRight />
      </li>
    )
  }

  if (breadcrumbs.length <= 1) {
    return <ul className="breadcrumbs"></ul>
  }

  const itemListElements = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.titolo.replace('"', ''),
    item: item.url,
  }))

  const lastItem = breadcrumbs[breadcrumbs.length - 1]
  const breadcrumbsList = breadcrumbs.slice(1, -1).map((item) => (
    <Fragment key={item.titolo}>
      <li className="breadcrumbs__item">
        {item.url ? (
          <Link href={item.url}>
            <a>{item.titoloOverride ? item.titoloOverride : item.titolo}</a>
          </Link>
        ) : item.titoloOverride ? (
          item.titoloOverride
        ) : (
          item.titolo
        )}
      </li>
      <BreadCrumbsSeparator />
    </Fragment>
  ))

  return (
    <>
      <ul className="breadcrumbs">
        <li className="breadcrumbs__item">
          <Link href="/">
            <a>Home</a>
          </Link>
        </li>
        <BreadCrumbsSeparator />
        {breadcrumbsList}
        <li className="breadcrumbs__item breadcrumbs__item--active">
          {lastItem.titoloOverride ? lastItem.titoloOverride : lastItem.titolo}
        </li>
      </ul>
      <BreadcrumbJsonLd itemListElements={itemListElements} />
    </>
  )
})

export default Breadcrumbs
