import React from 'react'
import Icon from '../../atoms/Icon/Icon'
import { AlertTriangleLine, Checkmark } from '../NewIcons'

const FormField = ({
  onBlur,
  label,
  variant,
  iconName,
  status,
  size,
  errorMessage,
  infoMessage,
  children,
  className,
  layout,
}) => {
  return (
    <div
      onBlur={onBlur}
      className={
        `formfield` +
        (size ? ` formfield--${size}` : '') +
        (variant ? ` formfield--${variant}` : '') +
        (status ? ` formfield--${status}` : '') +
        (layout ? ` formfield--layout-${layout}` : '') +
        (className ? ' ' + className : '')
      }
    >
      {label ? <label className="formfield__label">{label}</label> : ''}
      <div className="formfield__input-wrapper">
        {children}
        {iconName ? (
          <Icon icon_name={iconName} cName={'formfield__icon'} size={size == 'lg' ? 24 : 20} />
        ) : (
          <></>
        )}
        {status === 'error' ? (
          <div className={'formfield__error-icon'}>
            {' '}
            <AlertTriangleLine />
          </div>
        ) : (
          <></>
        )}
        {status === 'success' ? (
          <div className={'formfield__success-icon'}>
            <Checkmark />
          </div>
        ) : (
          <></>
        )}
      </div>
      {status === 'error' && errorMessage ? (
        <div className="formfield__error">{errorMessage}</div>
      ) : (
        <></>
      )}
      {infoMessage ? <div className="formfield__info">{infoMessage}</div> : <></>}
    </div>
  )
}

export default FormField
