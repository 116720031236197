import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../../utils'
import { Button } from '../../atoms'
import FormCheckbox from '../../atoms/FormCheckbox/FormCheckbox'
import NewModal from '../../molecules/NewModal/NewModal'
import useTrans from '../../../hooks/useTrans'
import * as Sentry from '@sentry/browser'

const CondizioneServizio = () => {
  const t = useTrans()
  const { page } = useSelector((state) => state.paginaCorrente.paginaCorrente)
  const { utente, fetched } = useSelector((state) => state.utente)
  const { service_gift } = useSelector((state) => state.servicegift)
  const [isLoading, setLoading] = useState(false)
  const [condizioneServizio, setCondizioneServizio] = useState(utente?.condizione_servizio)

  const router = useRouter()

  useEffect(() => {
    if (page?.chiave !== 'condizioni-servizio' && page?.chiave !== 'informativa-privacy') {
      utente && fetched && setCondizioneServizio(utente.condizione_servizio)
    }
  }, [utente])

  useEffect(() => {}, [condizioneServizio])

  const _handleChange = ({ value, id }) => {
    setCondizioneServizio((prev) => [
      ...prev?.filter((item) => item?.id !== id),
      { ...utente?.condizione_servizio?.filter((item) => item?.id === id)?.[0], value: value },
    ])
  }

  const AccettaCondizioni = async (ids) => {
    await api.post('accettazione-condizioni-servizio/', { ids: ids })
  }

  const isDisabled = () => {
    let valid = true
    condizioneServizio?.forEach((item) => {
      item?.checkbox && item?.obbligatoria === true && item?.value !== true && (valid = false)
    })
    return !valid
  }

  const _handleSubmit = async () => {
    setLoading(true)
    const condizioniServizioIds = condizioneServizio
      .filter((item) => item?.value || !item?.checkbox)
      .map((item) => item.id)

    try {
      await AccettaCondizioni(condizioniServizioIds)
    } catch (error) {
      Sentry.captureException(error)
    }

    router.reload()
  }

  return !service_gift?.attivo ? (
    <>
      {condizioneServizio?.length > 0 && (
        <NewModal
          visible={true}
          alert={false}
          title={t('Condizioni del servizio')}
          actions={[
            <Button
              label="Conferma Consensi"
              disabled={isDisabled() || isLoading}
              variant="primary"
              size="medium"
              onClick={() => _handleSubmit()}
            />,
          ]}
        >
          <div className="condizioni-servizio">
            <p className="condizioni-servizio__description">{condizioneServizio?.descrizione}</p>
            <div className="condizioni-servizio__fields">
              {utente?.condizione_servizio?.map((item) => (
                <div>
                  {item?.checkbox ? (
                    <FormCheckbox
                      size="sm"
                      checked={condizioneServizio.prima}
                      label={<div dangerouslySetInnerHTML={{ __html: item?.descrizione }} />}
                      onChange={(e) => {
                        _handleChange({
                          value: e.target.checked,
                          id: item?.id,
                          obbligatoria: item?.obbligatoria,
                        })
                      }}
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: item?.descrizione }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </NewModal>
      )}{' '}
    </>
  ) : null
}

export default CondizioneServizio
