import * as React from 'react'

function SvgCheckmark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--strokes icon--checkmark ' + (props.classes ? ' ' + props.classes : '')}
      {...props}
    >
      <path
        d="M9.86 18a.997.997 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a.998.998 0 01-.73.33h-.01z"
        strokeWidth="1"
      />
    </svg>
  )
}

export default SvgCheckmark
