import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { Button } from '../components'
import useTrans from '../hooks/useTrans'

export default function Custom404() {
  const t = useTrans()
  const router = useRouter()

  const [customMessage, setCustomMessage] = useState()

  useEffect(() => {
    if (router.pathname.includes('/flusso-personalizzazione')) {
      setCustomMessage(
        t("Non è stato possibile recuperare l'ordine richiesto, contattare l'assistenza")
      )
    }
  }, [])

  return (
    <main className="page404">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          margin: '16px 0',
        }}
      >
        <h1 style={{ fontWeight: 'bold', fontSize: '28px' }}>404</h1>
      </div>
      {customMessage && (
        <div
          style={{
            textAlign: 'center',
            margin: '16px 0',
          }}
        >
          <h2>{customMessage}</h2>
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Link href="/">
          <Button label="Torna alla homepage" variant="primary" />
        </Link>
      </div>
    </main>
  )
}
