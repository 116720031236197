import * as React from 'react'

function SvgAltezza(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--altezza ' + (props.classes ? props.classes : '')}
      {...props}
      style={{ transform: 'rotate(90deg)' }}
    >
      <path
        d="M18 7.637L22.363 12 18 16.363l-1.414-1.414L18.534 13H5.507l1.95 1.95-1.414 1.414L1.679 12l4.364-4.363L7.457 9.05 5.507 11h13.027l-1.948-1.95L18 7.638z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAltezza
