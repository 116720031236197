import { WISHLISTELEMENTS, GETPRODOTTOVALUESPRODOTTI } from '../../../utils/endpoint'
import React, { useEffect, useState } from 'react'
import { api } from '../../../utils'
import { useRouter } from 'next/router'
import { Button } from '../../atoms'
import { Trans } from '../../atoms'
import NewProdottoCard from '../NewProdottoCard/NewProdottoCard'
import Dropdown from '../../atoms/Dropdown/Dropdown'
import { Filter, ChevronDown, GridA2V1, GridA3V1 } from '../../atoms/NewIcons'
import Sticky from '../../organisms/Sticky/Sticky'
import { useRef } from 'react'
export const listingItemClass = 'listing__list__item col-md-4'
import FiltriListing from '../../organisms/FiltriListing/FiltriListing'
import Pagination from '../../organisms/Pagination/Pagination'
import { useContext } from 'react'
import { ListingContext } from '../../../context/ListingContext'
import SkeletonLoading from '../../atoms/SkeletonLoading/SkeletonLoading'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import useTrans from '../../../hooks/useTrans'
import { displayNotification } from '../../atoms/Notifica/Notifica'
import Chip, { ChipCloseFilter } from '../../atoms/Chip/Chip'
import { useSelector } from 'react-redux'
import { trackingGAEvent, trackingProductImpression } from '../../../utils/gtm'
const queryString = require('query-string')

const ProdottiListing = ({
  model,
  elemento,
  pagina,
  actual_categoria,
  categorie_list,
  extra_params,
  url,
  variant,
  listingPage,
  search,
  ord,
}) => {
  const { utente } = useSelector((state) => state.utente)
  const { categorie } = useSelector((state) => state.categorie)
  const filters_ref = useRef()
  const [canLoad, setCanLoad] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)
  const [listing, setListing] = useState(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filtri, setFiltri] = useState([])
  const [selezionati, setSelezionati] = useState({})
  const [vista2, setVista2] = useState(false)
  const [pagination, setPagination] = useState({ num_pages: 1 })
  const [currentPage, setCurrentPage] = useState(listingPage ? listingPage : 1)
  const [ordinamento, setOrdinamento] = useState(null)
  const [ordinamenti, setOrdinamenti] = useState([])
  const [selectedFilter, setSelectedFilter] = useState()
  let filtriInitial = elemento && elemento.filtri_initial ? elemento.filtri_initial : ''
  const [isSticky, setSticky] = useState(false)
  const { showFilters, setShowFilters } = useContext(ListingContext)
  const { width } = useWindowDimensions()
  const router = useRouter()
  const t = useTrans()

  useEffect(() => {
    if (!router.query.o) {
      setOrdinamento(null)
    }
    if (!router.query.page) {
      setCurrentPage(1)
    }
  }, [router.query])

  // !Attenzione per Testare il service gift tornare su "/" e poi ricliccare sulla categoria
  useEffect(() => {
    if (canLoad) handleFetching().then(fetchListing())
  }, [currentPage, JSON.stringify(selezionati), ordinamento, categorie, vista2, canLoad])

  const handleFetching = async () => {
    if (!loading) setLoading(true)
  }
  /* useEffect(() => {
    listingPage ? setCurrentPage(listingPage) : setCurrentPage(1)
  }, [listingPage]) */

  useEffect(() => {
    let rt = queryString.parseUrl(router.asPath)
    let ob = {}
    Object.entries(rt.query).forEach((item) => {
      if (item[0] != 'page' && item[0] != 'ids' && item[0] != 'o') {
        let value = []
        if (Array.isArray(item[1])) {
          value = item[1].map((i) => Number(i)).filter((i) => !isNaN(i))
        } else {
          value = [Number(item[1])].filter((i) => !isNaN(i))
        }
        if (value.length > 0) ob[item[0]] = value
      }
    })
    if (Object.keys(ob).length !== 0) {
      setSelezionati(ob)
    } else {
      setSelezionati({})
    }
    setCanLoad(true)
  }, [router.asPath])

  const TrovaPadre = (cat) => {
    let padre = cat.filter((item) => item.id == actual_categoria)
    padre.length == 0 &&
      (padre = cat.filter(
        (item) => item.children.filter((element) => element.id == actual_categoria).length > 0
      ))
    return padre[0]
  }

  const FindIds = (categorie, padre) => {
    if (categorie.length == 1) {
      return categorie
    }
    return categorie.filter((item) => item != padre.id)
  }
  const fetchListing = async (page = currentPage) => {
    if (categorie.length < 1 && !search) {
      return
    }
    let params = {}
    if (search) {
      params = {
        model,
        p: page,
        o: ordinamento ? ordinamento.value : ord ? ord : null,
        chiave: pagina ? pagina.chiave : null,
        search: search,
        lang: router.locale,
        filtri_initial: filtriInitial,
        filtri_id: Object.values(selezionati).join('/'),
      }
    } else {
      let padre = TrovaPadre(categorie_list)
      let ordine = ordinamento ? ordinamento.value : ord ? ord : null
      params = {
        model,
        p: page,
        o: ordine,
        chiave: pagina ? pagina.chiave : null,
        filtri_initial: filtriInitial,
        filtri_id: Object.values(selezionati).join('/'),
        categorie_ids: FindIds(categorie, padre),
        search: search,
        lang: router.locale,
        ...extra_params,
      }
    }
    api
      .get(url, { params })
      .then((response) => {
        initializeListing(response.data.listing, false).then()
      })
      .catch((error) => {
        setCurrentPage(1)
        console.log(error)
      })
  }

  const loadPagination = () => {
    //setCurrentPage(listing.page)
    setPagination(listing.paginator)
  }

  const loadPrezzi = async (prodotti) => {
    if (!prodotti || prodotti.length == 0) {
      return null
    }
    let ids = []
    prodotti.map((prodotto) => ids.push(prodotto.id))
    const params = {
      prodotti_ids: ids,
      listing: 1,
    }
    await api
      .get(GETPRODOTTOVALUESPRODOTTI, { params })
      .then((res) =>
        prodotti.map(
          (item) => (item.listino = res.data.find((prezzo) => prezzo.prodotto__id == item.id))
        )
      )
      .catch((error) => console.log(error))
    return prodotti
  }

  const handleNotification = (active) => {
    !active &&
      displayNotification('wishlist_add', 'Hai salvato tra i preferiti questo prodotto', 'success')
    active &&
      displayNotification('wishlist_remove', 'Hai rimosso dai preferiti questo prodotto', 'success')
  }

  const loadItems = async (loadPrice = false) => {
    const list = loadPrice ? await loadPrezzi(listing.elementi) : listing.elementi
    const items = list?.map((item, index) => (
      <article
        key={item.id}
        className={`listing__list__item  ${vista2 ? 'col-md-6' : 'col-md-4'}`}
        style={{ marginBottom: 24 }}
      >
        <NewProdottoCard
          utente={utente}
          prodotto={item}
          vista2={width < 545 ? false : vista2}
          handleNotification={handleNotification}
          priceLoaded={loadPrice}
          currentPage={currentPage}
          index={index}
        />
      </article>
    ))

    setItems(items)

    if (!loadPrice) {
      setLoading(false)
      loadItems(true)
    } else trackingProductImpression(list, currentPage)
  }

  const handleOnChange = (value, placeholder, attributo, checked) => {
    let filtrati = { ...selezionati }
    if (checked) {
      trackingGAEvent(
        'filter_on_listing_top',
        value?.nome_filtro || '',
        placeholder ? placeholder.replace(/ \(\d+\)$/, '') : ''
      )
      if (!filtrati[attributo]) {
        filtrati[attributo] = [value.id]
      } else {
        filtrati[attributo].push(value.id)
      }
    } else {
      if (Array.isArray(filtrati[attributo]) && filtrati[attributo].length > 1) {
        try {
          filtrati[attributo] = filtrati[attributo]?.filter((item) => item != value.id)
        } catch (error) {
          console.error(error)
        }
      } else {
        delete filtrati[attributo]
      }
    }
    setSelezionati(filtrati)
  }

  const changePage = (page) => {
    handleFetching().then(window.scrollTo(0, 0))
    setCurrentPage(page)
    //fetchListing(page)
  }

  const changeOrdinamento = (value, label, useless) => {
    setOrdinamento(value)
  }

  const changeOrdinamentoDropdown = (value) => {
    trackingGAEvent('filter_on_listing', 'sort_by', value?.label || '')
    const ordinamentoValue = ordinamenti.filter((x) => x.value == value.value)
    changeOrdinamento(ordinamentoValue.length ? ordinamentoValue[0] : null)
  }

  const loadFiltriDesktop = () => {
    setOrdinamenti(listing.ordinamenti)
    const filtri = listing?.attributi
      ?.filter((attr) => attr.valori_disponibili.length > 0)
      ?.slice(0, 4)
      .map((attr) => (
        <Chip
          key={attr.id}
          placeholder={attr.nome}
          size="md"
          icon={<ChevronDown />}
          onClick={() => {
            setSelectedFilter(attr?.nome), setShowFilters(true)
          }}
        />
      ))
    // if (listing?.attributi?.filter((attr) => attr.valori_disponibili.length > 0).length > 4) {
    filtri.push(
      <Chip
        placeholder={t('Altri filtri')}
        size="md"
        icon={<Filter />}
        iconPos="left"
        onClick={() => {
          setSelectedFilter(''), setShowFilters(true)
        }}
      />
    )
    // }
    setFiltri(filtri)
  }

  const fetchWishlist = async (ids) => {
    if (utente) {
      const params = {
        elements: ids,
      }
      const response = await api.get(WISHLISTELEMENTS, { params })
      return response.data
    }
  }

  const initializeListing = async (listing, add) => {
    if (add) {
    } else {
      setListing(listing)
      //setCurrentPage(listing.page)
      setPagination(listing.paginator)
    }
  }

  useEffect(() => {
    if (listing) {
      let rt = queryString.parseUrl(router.asPath)
      let obj = {}
      Object.keys(selezionati).forEach((key) => (obj[key] = selezionati[key]))
      let params = {}
      if (
        (search && !(!listing?.ordine && currentPage === 1 && Object.keys(obj).length === 0)) ||
        (!search &&
          !(
            categorie.length === 1 &&
            categorie[0] === actual_categoria &&
            !listing?.ordine &&
            currentPage === 1 &&
            Object.keys(obj).length === 0
          ))
      ) {
        params = {
          page: currentPage,
          ids: categorie,
          ...obj,
          o: listing?.ordine,
        }
      }
      const urlString = queryString.stringifyUrl({
        url: `/${router.locale}${rt.url}`,
        query: params,
        arrayFormat: 'braket',
        skipNull: true,
      })
      loadFiltriDesktop()
      loadItems()
      loadPagination()
      // non aggiorniamo l'url se siamo appena atterrati sulla pagina
      if (!firstFetch) router.push(urlString, undefined, { shallow: true })
      setFirstFetch(false)
    }
  }, [listing])

  const classVariant = variant ? 'listing--' + variant : ''
  return (
    <div className={`listing ${classVariant}`} style={{ height: loading ? '150rem' : 'auto' }}>
      <div className="listing__options">
        <FiltriListing
          initialValues={selectedFilter ? selectedFilter : null}
          Selezionati={selezionati}
          setSelezionati={setSelezionati}
          filtriOpen={showFilters}
          onClose={() => {
            setShowFilters(false)
          }}
          lista_filtri={listing?.attributi}
          handleOnChange={handleOnChange}
          numeroElementi={listing?.number}
        />
        <Sticky
          className="listing-options"
          offSetY={600}
          isSticky={isSticky}
          setStickyFromProps={setSticky}
        >
          <div className="listing-options__row listing-options__row--filters" ref={filters_ref}>
            <div className="listing-options__row-content">
              <p className="listing-location">
                <Trans chiave="Ti trovi in: " />
                <strong>{categorie_list && TrovaPadre(categorie_list)?.nome}</strong>
              </p>
              {filtri.length ? (
                <div className="listing-filters">
                  <p className="listing-filters__title">
                    <Trans chiave={'Filtra per'} />
                  </p>
                  <div className={`listing-filters__filters`}>{filtri}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* {listing?.attributi.filter(
            (item) =>
              item.valori_disponibili.filter((element) => element.selezionato == true).length > 0
          ).length > 0 ? ( */}
          {listing?.valori_filtrati.length > 0 ? (
            <div className="listing-options__row listing-options__row--selection">
              <div className="listing-options__row-content">
                <div className="listing-selection">
                  {listing?.valori_filtrati.map((item) => (
                    <ChipCloseFilter
                      key={item.id}
                      placeholder={
                        <>
                          <strong>
                            {item.attributo_chiave.charAt(0).toUpperCase() +
                              item.attributo_chiave.slice(1)}
                            :
                          </strong>{' '}
                          {item.nome}
                        </>
                      }
                      onClose={() =>
                        handleOnChange(
                          {
                            id: item.id,
                            nome: item.codice,
                            nome_filtro: item.attributo_chiave,
                            attributo: item.attributo,
                          },
                          '',
                          item.attributo,
                          false
                        )
                      }
                    />
                  ))}
                  <Button
                    label={t('Rimuovi filtri')}
                    variant="ghost"
                    size="medium"
                    onClick={() => setSelezionati({})}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="listing-options__row listing-options__row--settings">
            <div className="listing-options__row-content">
              <div className="listing-ordinamento">
                <Dropdown
                  onChange={changeOrdinamentoDropdown}
                  placeholder={t('Ordina per')}
                  options={ordinamenti?.map((option) => {
                    return { label: option.placeholder, value: option.value }
                  })}
                  value={
                    listing
                      ? ordinamenti.filter((option) => option.value == listing.ordine)[0]
                        ? {
                            label: ordinamenti.filter((option) => option.value == listing.ordine)[0]
                              .placeholder,
                            value: ordinamenti.filter((option) => option.value == listing.ordine)[0]
                              .value,
                          }
                        : null
                      : null
                  }
                />
              </div>
              <div className="listing-vista">
                <GridA3V1
                  classes={'listing-vista__icon' + (!vista2 ? ' listing-vista__icon--active' : '')}
                  onClick={() => setVista2(false)}
                />
                <GridA2V1
                  classes={'listing-vista__icon' + (vista2 ? ' listing-vista__icon--active' : '')}
                  onClick={() => setVista2(true)}
                />
              </div>
              {listing && (
                <div className="listing-num">
                  {listing.number} {t('articoli')}
                </div>
              )}
            </div>
          </div>
        </Sticky>
        <div className="listing__list">
          <div className="row ">
            {loading
              ? Array(vista2 ? 6 : 9)
                  .fill('')
                  .map((_, i) => <SkeletonLoading vista2={vista2 ? 1 : 0} key={i} />)
              : items}
          </div>
        </div>
        {!loading && pagination.num_pages > 1 && (
          <Pagination
            pagination={pagination}
            handleChangePage={changePage}
            currentPage={currentPage}
          />
        )}
        <div className="listing__options-right"></div>
      </div>
    </div>
  )
}

export default React.memo(ProdottiListing)
