import { api } from '../utils'
import { MENU_BANNER } from '../utils/endpoint'

export const fetchBanner = (lang = 'it', apiBaseUrl = '') => ({
  type: 'FETCH_BANNER',
  payload: api.get(apiBaseUrl + MENU_BANNER, { params: { lang } }),
})

export default function reducer(
  state = {
    bannerData: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_BANNER_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_BANNER_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_BANNER_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        bannerData: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
