import React from 'react'

const FormInput = ({
  name,
  type,
  placeholder,
  disabled,
  value,
  onBlur,
  onChange,
  defaultChecked,
  max = null,
  autoComplete = false,
}) => {
  return max ? (
    <input
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className="forminput formfield__input"
      type={type ? type : 'text'}
      disabled={disabled}
      placeholder={placeholder}
      defaultChecked={defaultChecked}
      maxLength={max}
      {...(!autoComplete ? { autoComplete: 'off' } : {})}
    />
  ) : (
    <input
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className="forminput formfield__input"
      type={type ? type : 'text'}
      disabled={disabled}
      placeholder={placeholder}
      defaultChecked={defaultChecked}
      {...(!autoComplete ? { autoComplete: 'off' } : {})}
    />
  )
}

export default FormInput
