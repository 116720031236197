import React from 'react'
import { Checkmark } from '../NewIcons'

const FormCheckbox = ({
  name,
  size,
  label,
  labelHtml,
  disabled,
  value,
  onChange,
  checked,
  readOnly,
  onBlur,
}) => {
  return (
    <label
      className={
        `formcheckbox` +
        (size ? ` formcheckbox--${size}` : '') +
        (disabled ? ' formcheckbox--disabled' : '')
      }
    >
      <input
        checked={checked}
        name={name}
        value={value}
        className="formcheckbox__input"
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
      <span className="formcheckbox__styledinput">
        <Checkmark />
      </span>
      {!!labelHtml && (
        <span className="formcheckbox__label" dangerouslySetInnerHTML={{ __html: labelHtml }} />
      )}
      {!!label && !labelHtml && <span className="formcheckbox__label">{label}</span>}
    </label>
  )
}

export default FormCheckbox
