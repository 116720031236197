import * as React from 'react'

function SvgClock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--clock ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M12 2l.28.004C17.673 2.152 22 6.57 22 12l-.004.28C21.848 17.673 17.43 22 12 22 6.477 22 2 17.523 2 12S6.477 2 12 2zm0 2a8 8 0 000 16l.25-.004A8 8 0 0020 12l-.004-.25A8 8 0 0012 4zm0 3a1 1 0 011 1v3h3a1 1 0 010 2h-4a1 1 0 01-1-1V8a1 1 0 011-1z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgClock
