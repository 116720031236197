import React from 'react'
import { ChevronUp } from '../NewIcons'
import useTrans from '../../../hooks/useTrans'

const ScrollTop = () => {
  const t = useTrans()
  return (
    <button className="scrolltop" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <span className="scrolltop__icon">
        <ChevronUp />
      </span>
      <span className="scrolltop__label">{t('Torna su')}</span>
    </button>
  )
}

export default ScrollTop
