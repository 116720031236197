import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--search ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <g>
          <path d="M20.71 19.29l-3.4-3.39c1.097-1.398 1.692-3.123 1.69-4.9 0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.777.002 3.502-.593 4.9-1.69l3.39 3.4c.389.392 1.022.395 1.414.006l.006-.006c.392-.389.395-1.022.006-1.414l-.006-.006zM5 11c0-3.314 2.686-6 6-6s6 2.686 6 6-2.686 6-6 6-6-2.686-6-6z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgSearch
