import { UTENTE } from '../utils/endpoint'
import { api } from '../utils'

export const fetchEdit = (apiBaseUrl = '', lang = 'it', headers) => ({
  type: 'FETCH_EDIT',
  payload: api.get(apiBaseUrl + UTENTE + 'get_edit/', { params: { lang } }, headers),
})

export const setRoute = (route) => ({
  type: 'SET_ROUTE',
  route,
})

export default function toolbar(
  state = {
    edit: false,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_EDIT_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_EDIT_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_EDIT_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        edit: action.payload.data,
      }
    }
    case 'SET_ROUTE': {
      return Object.assign({}, state, {
        route: action.route,
      })
    }

    default:
      return state
  }
}
