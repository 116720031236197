import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../utils'
import { ADDTOMAIN, CARRELLOCOUNT, CARRELLOMAIN } from '../utils/endpoint'
import { useRouter } from 'next/router'
import { isCarrelloDisponibile } from '../utils/servicegift'

export const CartContext = React.createContext()

const CartContextProvider = (props) => {
  const [cartCount, setCartCount] = useState()
  const [cartMain, setCartMain] = useState()
  const [isLoadingCount, setLoadingCount] = useState(false)
  const [isLoadingCart, setLoadingCart] = useState(false)
  const { utente } = useSelector((state) => state.utente)
  const service_gift = useSelector((state) => state.servicegift?.service_gift)
  const router = useRouter()

  useEffect(() => {
    if (cartMain) {
      getCount()
    }
  }, [cartMain])

  useEffect(() => {
    if (isCarrelloDisponibile(utente, service_gift)) {
      getCount()
    }
  }, [])

  const getCount = async () => {
    if (isCarrelloDisponibile(utente, service_gift)) {
      setLoadingCount(true)
      api
        .get(CARRELLOCOUNT)
        .then((res) => {
          setCartCount(res.data)
          setLoadingCount(false)
        })
        .catch((err) => {
          console.log(err)
          setLoadingCount(false)
        })
    }
  }
  const getCart = async () => {
    if (isCarrelloDisponibile(utente, service_gift)) {
      setLoadingCart(true)
      await api
        .get(CARRELLOMAIN, { params: { lang: router.locale } })
        .then((res) => {
          setLoadingCart(false)
          setCartMain(res.data)
        })
        .catch((err) => {
          setLoadingCart(false)
          console.log(err.message)
        })
    }
  }

  const refreshCart = async () => {
    if (isCarrelloDisponibile(utente, service_gift)) {
      await api
        .get(CARRELLOMAIN, { params: { lang: router.locale } })
        .then((res) => setCartMain(res.data))
        .catch((err) => console.log(err))
    }
  }

  const addToCart = async () => {
    if (cartMain && cartMain?.id) {
      api
        .post(`${CARRELLO}${cartMain.id}/${ADDTOMAIN}`, {
          salvato_per_dopo: false,
        })
        .then((response) => {
          setCartMain(response.data)
          displayNotification('add_to_main', 'Hai aggiunto gli articoli nel Carrello', 'success')
        })
        .catch((err) => displayNotification('error', err.message, 'error'))
    }
  }

  const addToLater = async () => {}

  const moveToMain = async () => {}

  return (
    <CartContext.Provider
      value={{
        cartMain,
        setCartMain,
        addToCart,
        cartCount,
        setCartCount,
        refreshCart,
        isLoadingCart,
        isLoadingCount,
        setLoadingCount,
        getCart,
        getCount,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}

export default CartContextProvider
