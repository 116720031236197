import { TRADUZIONI } from '../utils/endpoint'
import { api } from '../utils'

// se client-side e non utente staff prendo da localStorage
export const fetchTraduzioni = (isCachable = false, lang, apiBaseUrl = '') => {
  // let payload = null
  // if (typeof window !== 'undefined') {
  //   if (isCachable && localStorage.getItem('traduzioni-' + lang)) {
  //     payload = { data: JSON.parse(localStorage.getItem('traduzioni-' + lang)) }
  //   } else {
  //     payload = api.get(apiBaseUrl + TRADUZIONI, { params: { lang } })
  //   }
  // } else {
  //   payload = api.get(apiBaseUrl + TRADUZIONI, { params: { lang } })
  // }

  const payload = api.get(apiBaseUrl + TRADUZIONI, { params: { lang } })
  return {
    type: 'FETCH_TRADUZIONI',
    payload,
  }
}

export default function reducer(
  state = {
    traduzioni: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_TRADUZIONI_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_TRADUZIONI_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_TRADUZIONI_FULFILLED': {
      const lang = action.payload.config.params.lang
      const traduzioni = action.payload.data
      if (typeof window !== 'undefined')
        localStorage.setItem('traduzioni-' + lang, JSON.stringify(action.payload.data))

      return {
        ...state,
        fetching: false,
        fetched: true,
        traduzioni,
        lang,
      }
    }

    default: {
      return state
    }
  }
}
