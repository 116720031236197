import * as React from 'react'

function SvgOfferte(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--offerte ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-offerte_svg__a"
          d="M4 13.755v4.313c0 .238.224.432.5.432h15c.276 0 .5-.194.5-.432v-8.59c0-.239-.224-.432-.5-.432H12a.999.999 0 01-.774-.368L8.626 5.5H4.5c-.276 0-.5.193-.5.431v7.824zM19.5 20.5h-15c-1.378 0-2.5-1.09-2.5-2.432V5.931c0-1.34 1.122-2.43 2.5-2.43h4.601c.299 0 .584.133.774.366l2.599 3.18H19.5c1.378 0 2.5 1.09 2.5 2.431v8.59c0 1.341-1.122 2.432-2.5 2.432z"
        />
      </defs>
      <use xlinkHref="#24-offerte_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgOfferte
