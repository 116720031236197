import { PAGINEDETAIL } from '../utils/endpoint'
import { api } from '../utils'

export const fetchPrivacy = (locale, apiBaseUrl = '') => ({
  type: 'FETCH_INFORMATIVA_PRIVACY',
  payload: api.get(apiBaseUrl + PAGINEDETAIL, {
    params: {
      chiave: 'informativa-privacy',
      lang: locale,
    },
  }),
})

export default function reducer(
  state = {
    privacy: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_INFORMATIVA_PRIVACY_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_INFORMATIVA_PRIVACY_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_INFORMATIVA_PRIVACY_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        privacy: action.payload.data !== undefined ? action.payload.data : undefined,
      }
    }

    default: {
      return state
    }
  }
}
