import * as React from 'react'

function SvgChevronUp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--chevron-up ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M12 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L12 10.414z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgChevronUp
