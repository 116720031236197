import * as React from 'react'

function SvgGridA2V1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--grid-a2-v1 ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M19 2a2 2 0 012 2v16a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2h14zm-1 2H6a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V5a1 1 0 00-1-1z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgGridA2V1
