import React, { useEffect, useState } from 'react'
import { Trans } from '../../atoms'
import Button from '../../atoms/Button/Button'
import { Close } from '../../atoms/NewIcons'
import Accordion from '../../atoms/Accordion/Accordion'
import FormCheckbox from '../../atoms/FormCheckbox/FormCheckbox'
import MyImage from '../../atoms/MyImage/MyImage'
import useTrans from '../../../hooks/useTrans'

const FiltriListing = ({
  lista_filtri,
  filtriOpen,
  onClose,
  handleOnChange,
  setSelezionati,
  // Selezionati,
  initialValues,
  numeroElementi,
}) => {
  const [open, setOpen] = useState(initialValues ? initialValues : null)
  const [filtri, setFiltri] = useState(lista_filtri)
  const t = useTrans()

  useEffect(() => {
    initialValues && setOpen(initialValues)
    initialValues && setFiltri(lista_filtri)
  }, [initialValues])

  useEffect(() => {
    lista_filtri && setFiltri(lista_filtri)
  }, [lista_filtri])

  const _handleChecked = (nome) => {
    open.filter((item) => item === nome).length === 0 && setOpen([...open, nome])
    return true
  }
  const CheckOpen = (nome) => {
    let result = false
    if (!open) {
      result = false
    }
    if (nome == open) {
      result = true
    }
    return result
  }
  return (
    <>
      {filtriOpen && <div className="filtri-overlay" onClick={onClose}></div>}
      <div className={'filtri__listing' + (filtriOpen ? ' filtri__listing--open' : '')}>
        <div className="filtri__listing__close" onClick={onClose}>
          <Close />
        </div>

        <div className="filtri__listing__content">
          <p className="filtri__listing__title">{t('Filtri')}</p>
          <div className="filtri__listing__content__options">
            {filtri &&
              filtri.map(
                (filtro, i) =>
                  filtro.valori_disponibili.length > 0 && (
                    <Accordion
                      key={i}
                      withoutBorder
                      placeholder={filtro.nome}
                      size="large"
                      initialValue={CheckOpen(filtro.nome)}
                    >
                      <div
                        className={
                          'filtri__listing__accordion-content' +
                          (filtro.id === 7 ? ' filtri__listing__accordion-content--color' : '')
                        }
                      >
                        {filtro.valori_disponibili &&
                          filtro.valori_disponibili.map((valore) => (
                            <div className="filtri__listing__value" key={valore.id}>
                              {filtro.id === 7 ? (
                                <FiltroColore
                                  valore={valore}
                                  onClick={(checked) =>
                                    handleOnChange(
                                      {
                                        id: valore.id,
                                        nome: valore.codice,
                                        nome_filtro: filtro.nome,
                                        attributo: valore.attributo,
                                      },
                                      checked ? valore.nome : '',
                                      valore.attributo,
                                      checked
                                    )
                                  }
                                />
                              ) : (
                                <FormCheckbox
                                  label={<>{valore.nome}</>}
                                  disabled={valore.disabilitato}
                                  checked={valore.selezionato}
                                  onChange={(e) =>
                                    handleOnChange(
                                      {
                                        id: valore.id,
                                        nome: valore.codice,
                                        nome_filtro: filtro.nome,
                                        attributo: valore.attributo,
                                      },
                                      e.target.checked ? valore.nome : '',
                                      valore.attributo,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </Accordion>
                  )
              )}
          </div>
        </div>

        <div className="filtri__listing__footer">
          <p>
            {numeroElementi} <Trans chiave="Articoli trovati" />
          </p>
          <div className="filtri__listing__footer__actions">
            <Button
              label={t('Rimuovi Filtri')}
              size="medium"
              variant="secondary"
              onClick={() => {
                setSelezionati({}), setOpen([])
              }}
            />
            <Button
              label={t('Vedi Risultati')}
              size="medium"
              variant="primary"
              onClick={() => onClose(false)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const FiltroColore = ({ valore, onClick }) => {
  return (
    <div
      className={
        'filtro-colore' +
        (valore.disabilitato ? ' filtro-colore--disabled' : '') +
        (valore.selezionato ? ' filtro-colore--active' : '')
      }
      onClick={() => onClick(!valore.selezionato)}
    >
      <span className="filtro-colore__selection">
        <span className="filtro-colore__image-box">
          <MyImage
            className="color__image"
            src={valore.image}
            alt={valore.nome}
            title={valore.nome}
            width={60}
            height={60}
          />
        </span>
      </span>
      <span className="filtro-colore__label">{valore.nome}</span>
    </div>
  )
}

export default React.memo(FiltriListing)
