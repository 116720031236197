import React, { useEffect, useMemo, useState } from 'react'

import AbbigliamentoMenu from '../../../molecules/AbbigliamentoMenu/AbbigliamentoMenu'
import AltreCategorieMenu from '../../../molecules/AltreCategorieMenu/AltreCategorieMenu'
import { Close, ArrowLeft, ArrowRight } from '../../../atoms/NewIcons'
import Button from '../../../atoms/Button/Button'
import Trans from '../../../atoms/Trans/Trans'
import Link from 'next/link'
import MyImage from '../../../atoms/MyImage/MyImage'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import useTrans from '../../../../hooks/useTrans'

const MainMenu = ({ categorieapi, openMenuMobile, setOpenMenuMobile, headerData }) => {
  const t = useTrans()
  const [currentItem, setCurrentItem] = useState(null)
  const [submenuMobile, setSubmenuMobile] = useState(null)
  const isHover = (item) => currentItem && currentItem.nome == item.nome
  const [menuItems, setMenuItems] = useState(null)

  const closeMenuMobile = () => {
    setOpenMenuMobile(false)
    setSubmenuMobile(null)
  }

  //TODO modificare lo slice che sarà sbagliato in futuro
  const categorieMenuItems = useMemo(
    () => Object.values(categorieapi.filter((f) => f.in_menu).slice(0, 6)),
    [categorieapi]
  )

  const categorieMenuItemsMobile = useMemo(
    () => Object.values(categorieapi.filter((f) => f.in_menu)),
    [categorieapi]
  )

  useEffect(() => {
    setMenuItems(categorieapi.filter((f) => f.in_menu).slice(0, 6))
  }, [categorieapi])

  const subMenuChildren = () => {
    let subItems = categorieapi.filter((f) => f.in_menu)
    if (currentItem && currentItem?.children?.length)
      subItems = currentItem.children.filter((f) => f.in_menu)

    return <AltreCategorieMenu categorie={subItems} setCurrentItem={setCurrentItem} />
  }
  const MainMenuMobile = () => {
    return (
      <>
        <div
          className={'nav-mobile-overlay' + (openMenuMobile ? ' nav-mobile-overlay--open' : '')}
          onClick={() => closeMenuMobile()}
        ></div>
        <div className={'nav-mobile' + (openMenuMobile ? ' nav-mobile--open' : '')}>
          <button role="button" className="nav-mobile__close" onClick={() => closeMenuMobile()}>
            <Close />
          </button>
          <ul className="nav-mobile__nav">
            {categorieMenuItemsMobile?.map((item, k) => (
              <li className="nav__item" key={k}>
                {item.children?.length > 0 ? (
                  <span onClick={() => setSubmenuMobile(item)}>{item.nome}</span>
                ) : (
                  <Link href={item.url}>
                    <a onClick={closeMenuMobile}>{item.nome}</a>
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <ul className="nav-mobile__nav-bottom">
            {headerData?.header?.map((item, k) => {
              return (
                <li key={k} className="nav__item">
                  <Link href={item?.url ? item.url : '#'}>
                    <a target={item?.blank ? '_blank' : undefined} onClick={item?.url ? closeMenuMobile : null}>{item.nome}</a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={'subnav-mobile' + (submenuMobile ? ' subnav-mobile--open' : '')}>
          <button role="button" className="subnav-mobile__close" onClick={() => closeMenuMobile()}>
            <Close />
          </button>
          <div className="subnav-mobile__head">
            <button
              type="button"
              className="subnav-mobile__back"
              onClick={() => setSubmenuMobile(null)}
            >
              <ArrowLeft />
            </button>
            <p className="subnav-mobile__title">{submenuMobile?.nome}</p>
          </div>
          <ul className="subnav-mobile__nav">
            {submenuMobile?.children?.length &&
              submenuMobile.children.map((item, k) => (
                <li key={k} className="nav__item nav__item--with-image">
                  <Link href={item?.url ? item.url : '#'}>
                    <a className="nav__item__link" onClick={item?.url ? closeMenuMobile : null}>
                      <div className="nav__item__image-box">
                        {item?.main_image_url && (
                          <MyImage
                            className="nav__item__image"
                            src={item.main_image_url}
                            alt={item.nome}
                            width={48}
                            height={48}
                          />
                        )}
                      </div>
                      {item?.url && (
                        <div className="nav__item__title">
                          {item?.nome}
                          <span className="nav__item__num-prod">({item?.numero_prodotti})</span>
                        </div>
                      )}
                    </a>
                  </Link>
                </li>
              ))}
            <li className="subnav-mobile__viewall">
              <Link href={submenuMobile?.url ? submenuMobile.url : '#'}>
                <a onClick={() => setCurrentItem(null)}>
                  <Button
                    variant="ghost"
                    size="large"
                    icon={<ArrowRight />}
                    label={t('Vedi tutti')}
                    onClick={closeMenuMobile}
                  />
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </>
    )
  }

  return (
    <>
      <nav id="navigation" onMouseLeave={() => setCurrentItem(null)}>
        <div className="w-container">
          <ul className="nav">
            <li
              className="nav__item"
              key="6"
              onMouseEnter={() => setCurrentItem({ nome: 'altro' })}
              // onMouseLeave={() => setCurrentItem(null)}
            >
              <Link href="#">
                <a className={isHover({ nome: 'altro' }) ? 'active' : ''}>
                  <Trans chiave="Tutte le categorie" />
                </a>
              </Link>
            </li>
            {categorieMenuItems?.map((item, k) => (
              <li className="nav__item" key={k} onMouseEnter={() => setCurrentItem(item)}>
                <Link href={item.url}>
                  <a className={isHover(item) ? 'active' : ''}>{item.nome}</a>
                </Link>
              </li>
            ))}
          </ul>

          {currentItem?.children?.length > 0 && (
            <AbbigliamentoMenu
              categorie={currentItem.children}
              trends={currentItem.trends}
              setCurrentItem={setCurrentItem}
              categoriaParent={currentItem}
            ></AbbigliamentoMenu>
          )}

          {currentItem?.nome == 'altro' ? (
            <div
              onMouseEnter={() => setCurrentItem(currentItem)}
              onMouseLeave={() => setCurrentItem(null)}
            >
              <ul className="submenu__list">{subMenuChildren()}</ul>
            </div>
          ) : null}
        </div>
      </nav>
      <MainMenuMobile
        openMenuMobile={openMenuMobile}
        setOpenMenuMobile={setOpenMenuMobile}
        headerData={headerData}
      />
    </>
  )
}

MainMenu.propTypes = {
  categorieapi: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    categorieapi: state.categorieapi.categorieapi,
  }
}

export default connect(mapStateToProps, null)(MainMenu)
