import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { api } from '../utils'

export default function useAuth() {
  const [isLogginOut, setLogginOut] = useState(false)
  const dispatch = useDispatch()
  const router = useRouter()

  const Logout = async (redirect_url) => {
    setLogginOut(true)
    await api.get('/me/logout').catch((err) => console.log(err))
    dispatch({ type: 'LOGOUT' })

    router.push(redirect_url ? redirect_url : '/')
    setLogginOut(false)
    // process.browser &&
    //   window !== undefined &&
    //   ((window.location.href = redirect_url ? redirect_url : '/'), window.location.reload())
  }

  return { Logout, isLogginOut }
}
