import * as React from 'react'

function SvgCustomize(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--customize ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-customize_svg__a"
          d="M17.766 7.393l-5.867 5.865a4.137 4.137 0 00-.521-.636 3.94 3.94 0 00-.637-.517l5.869-5.867a.84.84 0 011.157 0 .798.798 0 01.233.59.786.786 0 01-.234.565M8.246 17.94l-2.228.04.039-2.23a2.399 2.399 0 012.339-2.338l.04-.001c.584 0 1.125.22 1.528.624.413.412.634.968.624 1.566a2.4 2.4 0 01-2.342 2.339M20 6.828a2.757 2.757 0 00-.82-2.004c-1.061-1.061-2.924-1.064-3.984 0l-6.613 6.61c-.075-.004-.147-.02-.222-.021-2.334.042-4.265 1.973-4.304 4.304L4 18.983A1.002 1.002 0 005 20h.019l3.266-.06c2.332-.04 4.262-1.971 4.303-4.304 0-.075-.015-.146-.017-.222l6.609-6.607c.538-.538.83-1.25.82-1.98"
        />
      </defs>
      <use xlinkHref="#24-customize_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgCustomize
