export function escape(s) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

export const numberNotation = (valore, numeroDecimali) => {
  let valoreSafe = parseFloat(valore) || 0
  let [intPart, decimals] = valoreSafe.toFixed(numeroDecimali).toString().split('.')
  return intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (numeroDecimali > 0 ? ',' + decimals : '')
}

// Lo distinguo da numberNotation, non si sa mai che i prezzi possano avere un formato diverso successivamente (es includere valuta)
export const priceNotation = (valore, numeroDecimali) => {
  return numberNotation(valore, numeroDecimali)
}

export const priceNotationNumber = (valore, numeroDecimali) => {
  const valoreStringa = String(valore).replace(',', '.')
  const valoreNumerico = parseFloat(valoreStringa)
  const valoreSafe = !isNaN(valoreNumerico) ? valoreNumerico : 0
  return parseFloat(valoreSafe.toFixed(numeroDecimali))
}

export const dateIsoToIt = (dateIso) => {
  let dateParts = dateIso ? dateIso.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/) : null
  return dateParts ? dateParts[3] + '/' + dateParts[2] + '/' + dateParts[1] : ''
}

export const handleScrollRef = (ref) => {
  if (ref?.current) {
    window.scrollTo(
      0,
      ref.current.offsetTop -
        (document.getElementsByClassName('header').length > 0
          ? document.getElementsByClassName('header')[0].clientHeight
          : 0) -
        (document.getElementsByClassName('toolbar').length > 0
          ? document.getElementsByClassName('toolbar')[0].clientHeight
          : 0)
    )
  }
}
