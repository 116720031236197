import * as React from 'react'

function SvgPdf(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={'icon icon--pdf ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M23.203 2a2 2 0 011.498.675l8.797 9.939c.323.366.502.837.502 1.326v18.289C34 34.859 31.904 37 29.308 37H12.692C10.096 37 8 34.86 8 32.229V6.77C8 4.141 10.096 2 12.692 2zm-1.141 2h-9.37C11.21 4 10 5.236 10 6.771V32.23c0 1.534 1.21 2.77 2.692 2.77h16.616C30.79 35 32 33.764 32 32.229V14.03h-5.937a4 4 0 01-3.995-3.8l-.005-.2L22.062 4zm8.249 8.031l-6.249-7.06v5.06c0 1.054.816 1.918 1.851 1.995l.15.005h4.248z" />
        <g transform="translate(5 18.75)">
          <rect width={22.5} height={10} rx={1} />
          <path
            fill="#FFF"
            d="M5.56 7.5V5.596h.486c.664 0 1.176-.153 1.536-.458.36-.305.54-.746.54-1.322 0-.554-.169-.971-.507-1.25-.338-.28-.832-.42-1.481-.42h-1.71V7.5h1.135zm.373-2.834h-.374v-1.59h.516c.31 0 .538.064.683.19.146.128.218.324.218.59 0 .264-.086.465-.26.603-.173.138-.434.207-.783.207zM10.649 7.5c.948 0 1.672-.234 2.174-.703s.752-1.144.752-2.025c0-.828-.241-1.472-.725-1.934-.483-.461-1.162-.692-2.036-.692h-1.68V7.5h1.515zm.107-.938h-.487V3.076h.604c1.015 0 1.523.575 1.523 1.725 0 1.174-.547 1.761-1.64 1.761zm5.046.938V5.383h1.816v-.926h-1.816v-1.38h1.952v-.931h-3.069V7.5h1.117z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgPdf
