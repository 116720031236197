import React from 'react'
import Link from 'next/link'
import Chip from '../Chip/Chip'

const TrendButton = ({ label, url }) => {
  return (
    <Link href={url}>
      <a className="trendbutton">
        <Chip size="md" placeholder={label} />
      </a>
    </Link>
  )
}

export default React.memo(TrendButton)
