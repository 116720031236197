import * as React from 'react'

function SvgEdit(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
      <path
        fill={props.nofill ? '' : props.fill ? props.fill : '#131518'}
        d="M16.167 6.117l-2.284-2.284a1.665 1.665 0 00-2.216-.058l-7.5 7.5c-.27.272-.437.628-.475 1.008l-.359 3.475a.836.836 0 00.834.909h.075l3.475-.317a1.67 1.67 0 001.008-.475l7.5-7.5a1.6 1.6 0 00-.058-2.258zm-8.6 8.566l-2.5.234.225-2.5L10 7.767l2.25 2.25-4.683 4.666zM13.333 8.9L11.1 6.667 12.725 5 15 7.275 13.333 8.9z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgEdit
