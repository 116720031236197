import * as React from 'react'

function SvgPin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--pin ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="pin_svg__a"
          d="M10 9.166a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5M10 5a2.92 2.92 0 00-2.917 2.916A2.92 2.92 0 0010 10.833a2.92 2.92 0 002.917-2.917A2.92 2.92 0 0010 5m0 11.372c-1.396-1.32-5-5.026-5-8.104 0-2.72 2.242-4.935 5-4.935s5 2.215 5 4.935c0 3.078-3.604 6.784-5 8.104m0-14.705c-3.676 0-6.667 2.96-6.667 6.601 0 4.563 5.874 9.65 6.124 9.864a.835.835 0 001.086 0c.25-.215 6.124-5.301 6.124-9.864 0-3.64-2.991-6.601-6.667-6.601"
        />
      </defs>
      <use xlinkHref="#pin_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgPin
