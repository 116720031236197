import * as React from 'react'

function SvgPosizioneLoghi1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      className={'icon icon--posizione-loghi-1 ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="#82DAFD"
              d="M4 19H76V61H4z"
              transform="translate(-146 -2027) translate(146 2027)"
            />
            <text
              fill="#131518"
              fontFamily="OpenSans-Bold, Open Sans"
              fontSize="16"
              fontWeight="bold"
              transform="translate(-146 -2027) translate(146 2027)"
            >
              <tspan x="35.434" y="45">
                1
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgPosizioneLoghi1
