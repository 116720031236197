import { connect } from 'react-redux'
import { removeCategoria, addCategoria, clearCategorie } from '../../../containers/categorie'
import Link from 'next/link'
import { Router, useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const CategoriaListingMenu = ({ lang, categorie_list, current, categorie, dispatch }) => {
  const [lista, setLista] = useState(null)
  const [padre, setPadre] = useState(null)

  useEffect(() => {
    setLista(GetCategorieList(categorie_list, categorie, true, current, categorie_list))
    setPadre(TrovaPadre(current, categorie_list))
  }, [categorie, current])

  function GetCategorieList(categorie_list, categorie, checkChildren, current, listaCompleta) {
    let contenuto = (
      <ul>
        {categorie_list?.map((item, index) => {
          item.active = false
          categorie.forEach((element) => {
            element == item.id && (item.active = true)
          })
          let props = {
            item: item,
            dispatch: dispatch,
            categorie: categorie,
            listaCompleta: listaCompleta,
            current,
            lang,
          }
          return (
            <li key={index}>
              <CheckBox {...props} checkChildren={checkChildren}></CheckBox>
              {checkChildren
                ? GetCategorieList(item.children, categorie, false, current, listaCompleta)
                : null}
            </li>
          )
        })}
      </ul>
    )
    return contenuto
  }

  const TrovaPadre = (selected_item, cat) => {
    let padre = cat.filter((item) => item.id == selected_item.id)
    padre.length == 0 &&
      (padre = cat.filter(
        (item) => item.children.filter((element) => element.id == selected_item.id).length > 0
      ))
    return padre[0]
  }
  const CheckIsChildren = (selected_item) => {
    let isChildren = false
    if (selected_item.id == padre.id) {
      return true
    }
    padre.children.filter((item) => item.id == selected_item.id).length > 0 && (isChildren = true)
    return isChildren
  }

  const ComposeQueryString = (checked) => {
    var esc = encodeURIComponent
    var query =
      categorie.length == 1 || categorie.length == 0
        ? '?ids[]=' + esc(checked.id)
        : '?ids[]=' +
          esc(checked.id) +
          '&' +
          categorie
            .filter((item) => item != current.id)
            .map((value) => 'ids[]' + '=' + esc(value))
            .join('&')
    return query
  }

  const HandleRemove = (unchecked, current = null, new_element = null) => {
    let subcat = []
    if (current && new_element) {
      subcat = categorie
        .filter((element) => element != unchecked.id)
        .filter((element) => element != current.id)
        .filter((element) => element != new_element.id)
    } else {
      current
        ? (subcat = categorie
            .filter((element) => element != unchecked.id)
            .filter((element) => element != current.id))
        : (subcat = categorie.filter((element) => element != unchecked.id))
    }
    var esc = encodeURIComponent
    var query = subcat.length > 1 ? subcat.map((value) => 'ids[]' + '=' + esc(value)).join('&') : ''
    dispatch(removeCategoria(unchecked.id))
    return query
  }

  function handleChange(e, item, props, router) {
    e.preventDefault()
    if (e.target.checked) {
      CheckIsChildren(item)
        ? (router.push(`${props.current.url}${ComposeQueryString(item)}`, undefined, {
            shallow: true,
          }),
          dispatch(addCategoria(item.id)))
        : (router.push(`${item.url}/`, undefined, {
            shallow: true,
          }),
          dispatch(clearCategorie()),
          dispatch(addCategoria(item.id), setPadre(TrovaPadre(item, categorie_list))))
      return
    } else {
      //caso in cui sia l'unica selezionata
      if (categorie.length == 0 || categorie.length == 1) {
        return
      }
      if (item.id == current.id) {
        let new_element_id = categorie.filter((item) => item != current.id)[0]
        let new_element = props.listaCompleta.filter((item) => item.id == new_element_id)[0]
        !new_element &&
          (new_element = props.listaCompleta
            .filter(
              (item) => item.children.filter((element) => element.id == new_element_id).length > 0
            )
            .map((item) => item.children.filter((child) => child.id == new_element_id)[0])[0])
        router.push(`${new_element.url}${HandleRemove(item, current, new_element)}`)
        return
      } else {
        //caso in cui non sia l'unica selezionata
        router.push(`${props.current.url}${HandleRemove(item)}`, undefined, {
          shallow: true,
        })
      }
      //caso in cui si rimuove la current e si deve selezionare la seconda in lista
    }
  }

  function CheckBox(props) {
    let router = useRouter()
    let item = props.item
    return (
      <div className="checkbox">
        {item.active ? (
          <div className="checkbox__wrapper" style={{ marginLeft: props.checkChildren ? 0 : 15 }}>
            <input
              checked={item.active}
              onChange={(e) => handleChange(e, item, props, router)}
              type="checkbox"
            ></input>
            <a>
              {item.nome}({item.numero_prodotti})
            </a>
          </div>
        ) : (
          <div className="checkbox__wrapper" style={{ marginLeft: props.checkChildren ? 15 : 0 }}>
            <input
              checked={item.active}
              onChange={(e) => handleChange(e, item, props, router)}
              type="checkbox"
            ></input>
            {/* href={ComposeURL(props.lang, arr, props.categorie, item, props.current, false)} */}
            <Link href={item.url} scroll={false}>
              <a onClick={(e) => (dispatch(clearCategorie()), dispatch(addCategoria(item.id)))}>
                {item.nome}({item.numero_prodotti})
              </a>
            </Link>
          </div>
        )}
      </div>
    )
  }

  return <>{lista}</>
}
const mapStateToProps = (state) => {
  return {
    categorie: state.categorie.categorie,
    url: state.categorie.url,
  }
}
export default connect(mapStateToProps, null)(CategoriaListingMenu)
