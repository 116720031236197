import * as React from 'react'

function SvgClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--close ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M13.41 12l4.3-4.29a1.004 1.004 0 00-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 00-.006 1.414l.006.006a1 1 0 001.414.006l.006-.006 4.29-4.3 4.29 4.3a1 1 0 001.414.006l.006-.006a1 1 0 00.006-1.414l-.006-.006-4.3-4.29z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgClose
