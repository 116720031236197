import * as React from 'react'

function SvgCustomerService(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={'icon icon--customer-service ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <rect width="26" height="2" x="3" y="26" fill="#038ABF" rx="1" />
          <path
            fill="#82DAFD"
            d="M16 5c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
          />
          <path
            fill="#04ACEF"
            d="M16 10C8.82 10 3 15.82 3 23v1h26v-1c0-7.18-5.82-13-13-13zm.288 2.004c5.559.143 10.092 4.41 10.653 9.854l.013.142H5.045l.014-.142C5.63 16.319 10.31 12 16 12l.288.004z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgCustomerService
