import * as React from 'react'

function SvgArrowUp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--arrow-up ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="arrow-up_svg__a"
          d="M4.36 8.867a.833.833 0 001.173.106l3.634-3.027v9.887a.834.834 0 101.666 0V5.946l3.634 3.027a.833.833 0 101.066-1.28l-5-4.166c-.039-.034-.086-.05-.129-.074-.034-.02-.064-.044-.102-.059a.83.83 0 00-.302-.06.839.839 0 00-.303.06c-.037.015-.067.039-.101.059-.044.025-.09.04-.13.074l-5 4.166a.834.834 0 00-.106 1.174"
        />
      </defs>
      <use xlinkHref="#arrow-up_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgArrowUp
