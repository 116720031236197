import * as React from 'react'

function SvgQualita(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={'icon icon--qualita ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#038ABF"
            d="M21 16c.513 0 .936.333.993.761l.007.1v10.415c0 .952-.895 1.724-2 1.724-.35 0-.694-.08-.996-.23l-.148-.08L16 26.974l-2.856 1.716c-.906.545-2.153.353-2.785-.428-.2-.248-.32-.536-.351-.835l-.008-.15V16.861c0-.476.448-.862 1-.862.513 0 .936.333.993.761l.007.1v10.415l2.856-1.716c.642-.385 1.48-.411 2.147-.077l.14.077L20 27.276V16.862c0-.476.448-.862 1-.862z"
          />
          <circle cx="16" cy="11" r="7" fill="#FFF" />
          <path
            fill="#04ACEF"
            d="M16 2c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 2c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z"
          />
          <path
            fill="#82DAFD"
            d="M18.345 8.375c.39-.392 1.023-.393 1.415-.004.361.36.39.927.086 1.32l-.083.094-3.976 3.998c-.39.392-1.023.394-1.414.004l-.002-.002-2.078-2.078c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083 1.369 1.368 3.27-3.286z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgQualita
