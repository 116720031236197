// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://d516f5f3f8e440479e9eb8583e1e37c6@o19983.ingest.sentry.io/5449735',
  // Adjust this value in production, or use tracesSampler for greater control
  enabled: process.env.NODE_ENV !== 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id })
  //   }
  //   return event
  // },
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection captured*',
  ],
})
