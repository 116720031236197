import * as React from 'react'

function SvgAlert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      className={'icon icon--alert ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M23.09 25.278l.555-20.369h-6.321l.568 20.37h5.199zm-2.599 9.091c.91 0 1.703-.331 2.38-.994a3.305 3.305 0 001.03-2.415c-.01-.928-.353-1.723-1.03-2.386s-1.47-.994-2.38-.994c-.937 0-1.742.331-2.414.994-.673.663-1.004 1.458-.995 2.386-.01.947.322 1.752.995 2.415a3.316 3.316 0 002.414.994z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAlert
