import * as React from 'react'

function SvgPlus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--plus ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M19 11h-6V5a1 1 0 00-2 0v6H5a1 1 0 000 2h6v6a1 1 0 002 0v-6h6a1 1 0 000-2z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgPlus
