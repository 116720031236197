import React from 'react'
import { Alert, Close } from '../../atoms/NewIcons'
import LoadingSvg from '../../atoms/Loading/Loading'

const Modal = ({
  variant,
  className,
  visible,
  onClose,
  alert,
  title,
  text,
  children,
  actions,
  loading,
}) => {
  return (
    <>
      {visible && (
        <>
          <div className={visible && 'newmodal-overlay'} onClick={onClose}></div>
          <div
            className={
              'newmodal' +
              (className ? ' ' + className : '') +
              (variant === 'checkout' ? ' newmodal--checkout' : '') +
              (actions ? ' newmodal--actions' : '')
            }
          >
            {loading ? (
              <LoadingSvg />
            ) : (
              <>
                {onClose && (
                  <button className="newmodal__close" onClick={onClose}>
                    <Close />
                  </button>
                )}
                {alert && (
                  <span className="newmodal__alert">
                    <Alert />
                  </span>
                )}
                {title && <p className="newmodal__title">{title}</p>}
                {(text || children) && (
                  <div className="newmodal__content">
                    {text && <div className="newmodal__text">{text}</div>}
                    {children && <div className="newmodal__custom-content">{children}</div>}
                  </div>
                )}
                {actions && <div className="newmodal__actions">{actions}</div>}{' '}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default React.memo(Modal)
