import React from 'react'
import { Timer, Qualita, Custom, CustomerService, LogoSipec } from '../../atoms/NewIcons'
import Accordion from '../../atoms/Accordion/Accordion'
import Link from 'next/link'
import { Trans } from '../../../components/index'
import ScrollTop from '../../atoms/ScrollTop/ScrollTop'
import MyImage from '../../atoms/MyImage/MyImage'
import useTrans from '../../../hooks/useTrans'
//Mancano le icons celesti all'interno degli assets

const PreFooter = () => {
  return (
    <div className="prefooter">
      <div className="w-container">
        <div className="prefooter__content">
          <div className="prefooter__content__item">
            <Timer classes="prefooter__content__item__icon" />
            <p className="prefooter__content__item__title">
              <Trans chiave="Velocità nella stampa e nella consegna" />
            </p>
          </div>

          <div className="prefooter__content__item">
            <Qualita classes="prefooter__content__item__icon" />
            <p className="prefooter__content__item__title">
              <Trans chiave="Servizio di alta qualità ed affidabile" />
            </p>
          </div>

          <div className="prefooter__content__item">
            <Custom classes="prefooter__content__item__icon" />
            <p className="prefooter__content__item__title">
              <Trans chiave="Articoli personalizzati su misura" />
            </p>
          </div>

          <div className="prefooter__content__item">
            <CustomerService classes="prefooter__content__item__icon" />
            <p className="prefooter__content__item__title">
              <Trans chiave="Team dedicato al servizio clienti" />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

//Creare Versione Semplificata; Mobile
const Footer = ({ semplificato, prefooter, isServiceGift, footerData, logo, azienda }) => {
  const t = useTrans()

  const static_options = {
    name: isServiceGift ? azienda.nome : t('footer_nome_azienda'),
    info: isServiceGift ? azienda.dettagli : t('footer_dettaglio_azienda'),
    text: t('Gli specialisti in gadget promozionali'),
  }

  const menu_columns = [
    {
      name: t('Esplora'),
      in_service_gift: true,
    },
    {
      name: t('Servizi'),
      in_service_gift: true,
    },
    {
      name: t('Supporto'),
      in_service_gift: true,
    },
    {
      name: t('Certificazioni'),
      in_service_gift: false,
    },
  ]

  const footerKeys = Object.keys(footerData)

  if (semplificato) {
    return (
      <div className="page__bottom">
        <ScrollTop />
        <footer className="footer">
          <div className="w-container">
            <div className="footer__semplificato__content">
              <p className="footer__semplificato__item">
                <Trans chiave="Aiuto" />
              </p>
              <span className="footer_items_separator"></span>
              <p className="footer__semplificato__item">
                <Trans chiave="Contatti" />
              </p>
              <span className="footer_items_separator"></span>
              <p className="footer__semplificato__item">
                <Trans chiave="Informativa sulla privacy" />
              </p>
            </div>
            <div className="footer__bottom">
              <strong>{static_options.name}</strong>
              {static_options.description}
            </div>
          </div>
        </footer>
      </div>
    )
  }

  return (
    <div className="page__bottom">
      <ScrollTop />
      {!isServiceGift && <PreFooter />}
      <footer id="footer" className="footer">
        <div className="w-container">
          <div className="footer__content">
            <div className="footer__logo">
              <div className="footer__logo__image-box">
                {logo?.image ? (
                  <MyImage
                    src={logo?.image}
                    alt={logo?.title}
                    title={logo?.title}
                    width={182}
                    height={48}
                  />
                ) : (
                  <div className="footer__logo__image--sipec">
                    <LogoSipec />
                  </div>
                )}
              </div>
              {!logo?.image && <p className="footer__logo__title">{static_options.text}</p>}
            </div>

            <div className="footer__mobile">
              <div className="footer__mobile__accordions">
                {footerKeys.map((key, index) => (
                  <>
                    {(!isServiceGift || menu_columns[index].in_service_gift === true) &&
                      footerData[key].length > 0 &&
                      index !== footerKeys.length - 1 && (
                        <Accordion
                          size="full"
                          placeholder={menu_columns[index].name}
                          key={'mobile_' + index}
                        >
                          {footerData[key].map((obj, k) => (
                            <>
                              {obj.url && obj.nome && (
                                <div
                                  className="footer__mobile__accordion__items"
                                  key={'mobile_item_' + k}
                                >
                                  <Link href={obj.url ?? '#'}>
                                    <a target={obj?.blank ? '_blank' : undefined} className="footer__list__item">{obj.nome}</a>
                                  </Link>
                                </div>
                              )}
                            </>
                          ))}
                        </Accordion>
                      )}
                  </>
                ))}
              </div>
            </div>

            {footerKeys.map((key, index) => (
              <>
                {(!isServiceGift || menu_columns[index].in_service_gift === true) &&
                  footerData[key].length > 0 && (
                    <div
                      className={`footer__list${
                        index === footerKeys.length - 1 ? ' footer__list--show-mobile' : ''
                      }`}
                      key={'footer-column-' + index}
                    >
                      <p className="footer__list__title">{menu_columns[index].name}</p>
                      <div className="footer__list__items">
                        {footerData[key].map((obj, k) => (
                          <>
                            {obj.url && obj.nome && (
                              <Link
                                href={obj.url ?? '#'}
                                key={'footer-item-link' + index + '-' + k}
                              >
                                <a target={obj?.blank ? '_blank' : undefined} className={`footer__list__item`}>{obj?.nome}</a>
                              </Link>
                            )}
                            {obj.image && (
                              <MyImage
                                className="footer__list__image"
                                src={obj.image}
                                alt={obj.nome}
                                title={obj.nome}
                                width={88}
                                height={88}
                                priority={false}
                                loading="lazy"
                                key={'footer-item-image' + index + '-' + k}
                              />
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
              </>
            ))}
          </div>

          <div className="footer__bottom">
            <strong>{static_options.name}</strong>
            {static_options.info}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
