import React from 'react'
import Image from 'next/image'
import LoadingSvg from '../../atoms/Loading/Loading'

const MyImage = (props) => {
  let placeholder = 'https://via.placeholder.com/'
  props.width ? (placeholder += props.width) : (placeholder = 'https://via.placeholder.com/264')
  props.height && props.width && (placeholder = placeholder + 'x' + props.height)

  return props?.className ? (
    <div className={props?.className}>
      {' '}
      <Image
        layout={props?.layout ? props?.layout : 'intrinsic'}
        {...props}
        src={props.src ? props.src : placeholder}
      />
    </div>
  ) : (
    <Image
      layout={props?.layout ? props?.layout : 'intrinsic'}
      {...props}
      src={props.src ? props.src : placeholder}
    />
  )
}

export default MyImage
