import * as React from 'react'

function SvgAlertTriangleLine(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--alert-triangle-line ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M10.153 2.537A3.43 3.43 0 0114.89 3.58l7.67 12.72a3 3 0 01.05 3.04l-.113.18A3.371 3.371 0 0119.67 21H4.33a3.37 3.37 0 01-2.94-1.7l-.097-.19a2.998 2.998 0 01.147-2.81L9.11 3.58a3.436 3.436 0 011.043-1.043zm2.74 1.796a1.48 1.48 0 00-2.073.287L3.14 17.35a.998.998 0 000 1l.08.121c.257.347.672.548 1.11.529h15.34a1.31 1.31 0 001.19-.65l.059-.12c.117-.29.093-.619-.069-.89L13.18 4.62a1.472 1.472 0 00-.287-.287zm-.186 10.96a1 1 0 11-1.416 1.414 1 1 0 011.416-1.414zM12 8l.117.007A1 1 0 0113 9v4a1 1 0 01-2 0V9l.007-.117A1 1 0 0112 8z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAlertTriangleLine
