import * as React from 'react'

function SvgMinus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--minus ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="minus_svg__a"
          d="M15.833 10.833H4.167a.833.833 0 110-1.666h11.666a.833.833 0 110 1.666"
        />
      </defs>
      <use xlinkHref="#minus_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgMinus
