import * as React from 'react'

function SvgDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--download ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="download_svg__a"
          d="M15.833 13.333c.459 0 .834.375.834.834v1.666c0 .423-.32.775-.73.827l-.104.007H4.167a.836.836 0 01-.834-.834v-1.666c0-.459.375-.834.834-.834.458 0 .833.375.833.834V15h10v-.833c0-.459.375-.834.833-.834zM10 2.5c.46 0 .833.373.833.833V10l2-1.499a.832.832 0 111 1.333l-3.333 2.5a.833.833 0 01-.98.015l-3.332-2.345a.832.832 0 11.957-1.363l2.024 1.425L9.167 10V3.333c0-.46.372-.833.833-.833z"
        />
      </defs>
      <use xlinkHref="#download_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgDownload
