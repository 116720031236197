import * as React from 'react'

function SvgCart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--cart ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M15.833 16.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm-8.333 0a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm6.152-4.583H7.303l-1.364-5h10.213l-2.5 5zm3.917-5.877A1.656 1.656 0 0016.152 5H5.485l-.514-1.886a.835.835 0 00-.804-.614H2.5a.833.833 0 000 1.667h1.03l2.333 8.552a.833.833 0 00.804.614h7.5a.832.832 0 00.745-.46l2.73-5.461a1.657 1.657 0 00-.073-1.622z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCart
