import * as React from 'react'

function SvgWishlistLine(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--wishilist-line ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-wishlist-line_svg__a"
          d="M7.22 6c-.864 0-1.674.334-2.28.941a3.242 3.242 0 000 4.573L12 18.585l7.06-7.071a3.24 3.24 0 000-4.573c-1.212-1.215-3.348-1.213-4.56 0l-1.792 1.795a1.028 1.028 0 01-1.416 0L9.5 6.94A3.2 3.2 0 007.22 6M12 21a.994.994 0 01-.707-.294l-7.768-7.78a5.245 5.245 0 010-7.399C4.509 4.543 5.82 4 7.22 4s2.712.543 3.695 1.527L12 6.614l1.085-1.086A5.185 5.185 0 0116.78 4a5.19 5.19 0 013.694 1.527 5.243 5.243 0 010 7.399l-7.767 7.781a1 1 0 01-.708.293"
        />
      </defs>
      <use xlinkHref="#24-wishlist-line_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgWishlistLine
