import React, { useEffect, useState } from 'react'

export const ListingContext = React.createContext()

const ListingContextProvider = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)

  return (
    <ListingContext.Provider
      value={{ openMenu, setShowFilters: setOpenFilters, setOpenMenu, showFilters: openFilters }}
    >
      {props.children}
    </ListingContext.Provider>
  )
}

export default ListingContextProvider
