import * as React from 'react'

function SvgImballi(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={'icon icon--imballi ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fillRule="evenodd">
        <g>
          <path d="M11.51 6c.275 0 .537.114.725.311l.075.09L16 11.33l3.69-4.93c.188-.251.485-.4.8-.4H30c.847 0 1.31.988.769 1.64l-4.77 5.734L26 25c0 .513-.386.936-.883.993L25 26H7c-.513 0-.936-.386-.993-.883L6 25V13.373L1.23 7.639c-.517-.622-.118-1.55.656-1.633L2 6h9.51zm3.74 8H8v10h7.25V14zM24 14h-6.75v10H24V14zm-10 7v2h-2v-2h2zM11.009 8H4.132l3.327 4h6.543l-2.993-4zm16.859 0H20.99l-2.993 4h6.543l3.327-4z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgImballi
