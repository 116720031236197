import * as React from 'react'

function SvgLogoSipec(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 304 79"
      className={'icon icon--menu ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path fill="#1D1D1B" d="M137.526 67.513L153.346 67.513 153.346 7.98 137.523 23.8z" />
            <path fill="#009FE3" d="M177.948 39.344s-.003 0 0 0zM178.31 7.98h-.344.344z" />
            <path
              fill="#1D1D1B"
              d="M181.092 7.99h-8.129l-2.2 2.208-11.402 11.385-2.217 2.217v43.724h15.823V23.8h7.78c4.292 0 7.77 3.48 7.77 7.77 0 4.292-3.478 7.771-7.77 7.771h-3.993v15.837h4.104c12.875-.144 23.256-10.588 23.322-23.465.066-12.876-10.208-23.426-23.081-23.702l-.007-.02zM245.441 23.81L261.261 7.991 224.017 7.991 224.017 7.98 224.006 7.991 208.194 23.8 208.194 23.807 208.197 29.839 208.194 29.839 208.194 45.659 208.197 45.659 208.197 51.694 208.194 51.694 208.194 67.513 208.197 67.513 224.017 67.513 245.438 67.513 245.438 51.69 224.017 51.694 224.017 45.659 245.438 45.659 245.438 29.835 224.017 29.839 224.017 23.81z"
            />
            <path
              fill="#1D1D1B"
              d="M279.436 7.99h-.706c-16.333.245-29.417 13.608-29.317 29.943.1 16.335 13.347 29.536 29.682 29.58h8.965v-15.82h-8.982c-7.701.096-14.02-6.07-14.116-13.77-.095-7.701 6.07-14.02 13.771-14.116h9.323l9.437-9.437 6.383-6.383h-24.444l.004.004zM133.485 45.659c-1.488-9.113-9.359-15.806-18.592-15.81h-6.035c-1.076.013-2.077-.55-2.627-1.475-.55-.925-.563-2.074-.036-3.012.527-.939 1.515-1.525 2.59-1.538h24.947l15.82-15.82h-41.059c-10.207.346-18.28 8.762-18.198 18.975.08 10.213 8.286 18.5 18.498 18.683h6.104c1.665 0 3.016 1.35 3.016 3.016s-1.35 3.016-3.016 3.016H90.023V67.53h24.922c10.383-.03 18.785-8.453 18.79-18.836-.005-1.012-.09-2.023-.25-3.023v-.013z"
            />
            <path
              fill="#009FE3"
              d="M78.689 39.344C78.689 17.615 61.074 0 39.344 0 17.615 0 0 17.615 0 39.344v.331c0 21.73 17.615 39.344 39.344 39.344 21.73 0 39.345-17.615 39.345-39.344v-.165-.166zM41.806 4.524c9.234 0 18.09 3.668 24.62 10.198 6.529 6.529 10.197 15.385 10.197 24.619 0 0-18.006-9.003-40.625-9.003-1.508-.035-2.963.55-4.028 1.618-1.446 1.446-1.033 4.359.778 7.385H6.988c0-19.229 15.59-34.817 34.818-34.817zm-4.923 69.971c-9.234 0-18.09-3.668-24.62-10.197-6.53-6.53-10.197-15.386-10.197-24.62 0 0 18.006 9.003 40.625 9.003 1.507.035 2.963-.55 4.028-1.618 1.446-1.446 1.033-4.358-.778-7.385H71.69c.002 19.226-15.581 34.814-34.807 34.817z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgLogoSipec
