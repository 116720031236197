import React, { useState, useEffect } from 'react'
import Tag from '../../atoms/Tag/Tag'
import Color from '../../atoms/Color/Color'
import MyImage from '../../atoms/MyImage/MyImage'
import { CheckmarkCircle2 } from '../../atoms/NewIcons'
import { Trans } from '../../atoms'
import Wishlist from '../../atoms/WishList/WishList'
import { priceNotation } from '../../../utils/safe'
import { HandleWishlist } from '../../../utils/HelperNewProdottoCard/utils'
import Link from 'next/link'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, A11y } from 'swiper'
import useTrans from '../../../hooks/useTrans'
import { trackingProductClick } from '../../../utils/gtm'
SwiperCore.use([Navigation, A11y])

const NewProdottoCard = ({
  utente,
  prodotto,
  variant,
  vista2,
  handleNotification,
  priceLoaded,
  noShallow,
  currentPage = null,
  index = null,
}) => {
  const [articoli, setArticoli] = useState(prodotto.articoli_colore)
  const [colori, setColori] = useState(prodotto?.articoli_colore?.map((item) => item.colore))
  const [selectedArticle, setSelectedArticle] = useState(
    articoli?.filter((item) => item?.colore?.id == colori[0]?.id)[0]
  )
  const { width } = useWindowDimensions()
  const [prodottoWishlist, setProdottoWishlist] = useState(
    prodotto.wishlist ? prodotto.wishlist : false
  )
  const t = useTrans()
  const ArrowClick = () => {
    HandleWishlist(prodottoWishlist, prodotto).then((response) => {
      setProdottoWishlist(response)
      if (handleNotification) handleNotification(prodottoWishlist)
    })
  }

  const HandleClick = (index) => {
    setSelectedArticle(articoli.filter((item) => item.colore.id == colori[index].id)[0])
  }

  const getRandomNumber = (max) => {
    return Math.floor(Math.random() * max)
  }

  useEffect(() => {
    const default_articolo = articoli?.filter((articolo) => articolo?.default === true)
    const random_number = getRandomNumber(articoli?.length)
    default_articolo?.length > 0
      ? setSelectedArticle(default_articolo[0])
      : setSelectedArticle(articoli[random_number])
  }, [])

  const VisualizzazionePrezzi = () => {
    let listino = prodotto?.listino?.prezzo_listino
    const fasce = prodotto?.listino?.con_fasce
    let currentPrezzo = prodotto?.listino?.prezzo
    let text = ''
    let show = false
    if (fasce) {
      if (prodotto?.listino?.fasce?.length) {
        let fascia = prodotto?.listino?.fasce[0]?.prezzo
        listino = currentPrezzo
        currentPrezzo = fascia
      }
      text = t('Prezzo da:')
      if (currentPrezzo != listino && utente) {
        text = t('Prezzo riservato da:')
      }
    } else {
      text = t('Prezzo:')
      if (currentPrezzo != listino && utente) {
        text = t('Prezzo riservato:')
        show = true
      }
    }

    return (
      <>
        <div className="card__prezzo">
          <p className="card__prezzo__label">{text}</p>
          <div className="card__prezzo__value">
            {currentPrezzo != listino &&
              utente &&
              (prodotto?.listino?.prezzo < prodotto?.listino?.fasce[0]?.prezzo || show) && (
                <span className="card__prezzo__value__full-price">
                  {priceNotation(listino, 3)} €
                </span>
              )}
            <span className="card__prezzo__value__final-price">
              {priceNotation(currentPrezzo, 3)} €
            </span>
          </div>
        </div>
      </>
    )
  }
  return (
    <div
      className={`card ${vista2 ? 'card--vista-2' : ''}`}
      onClick={() => trackingProductClick(prodotto, index || 0, currentPage || 1, selectedArticle)}
    >
      {prodotto.brand_image && <img src={prodotto.brand_image} className="card__brand-image" />}
      {utente && (
        <button className="card__wishlist" onClick={() => ArrowClick()}>
          <Wishlist isActive={prodottoWishlist} />
        </button>
      )}

      <div className="card__image__wrapper">
        {noShallow ? (
          <a href={selectedArticle?.url}>
            <MyImage
              src={
                selectedArticle?.thumb_image
                  ? selectedArticle?.thumb_image
                  : selectedArticle?.main_image
                  ? selectedArticle?.main_image
                  : selectedArticle?.image
                  ? selectedArticle?.image
                  : 'https://via.placeholder.com/264'
              }
              alt={selectedArticle?.titolo}
              title={selectedArticle?.titolo}
              width={264}
              height={264}
            />
          </a>
        ) : (
          <Link href={selectedArticle?.url}>
            <a>
              <MyImage
                src={
                  selectedArticle?.main_image
                    ? selectedArticle?.main_image
                    : 'https://via.placeholder.com/264'
                }
                alt={selectedArticle?.titolo}
                title={selectedArticle?.titolo}
                width={264}
                height={264}
              />
            </a>
          </Link>
        )}
        <span className="card__image__tag">
          {prodotto.trend && (
            <Tag
              variant={prodotto.trend.codice}
              colore={prodotto.trend.colore}
              label={prodotto.trend.nome}
              isBrand={false}
            />
          )}
        </span>
      </div>

      <div className="card__colors swiper--colors">
        <Swiper
          nested={true}
          id={prodotto.codice}
          navigation={true}
          pagination={false}
          spaceBetween={8}
          watchOverflow
          slidesPerView={6}
          breakpoints={{
            320: {
              slidesPerView: 6,
            },
            375: {
              slidesPerView: 8,
            },
            546: {
              slidesPerView: vista2 ? 10 : 6,
            },
          }}
        >
          {colori &&
            colori.map((colore, index) => (
              <SwiperSlide key={index}>
                <Color
                  isActive={selectedArticle?.colore.id == colore.id ? true : false}
                  onClick={() => {
                    HandleClick(index)
                  }}
                  img={colore?.image}
                  colorName={colore?.codice + ' - ' + colore?.nome}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <div className="card__codice">{prodotto?.codice}</div>
      <div className="card__titolo over__card">
        {noShallow ? (
          <a href={selectedArticle?.url}>{selectedArticle?.titolo ? selectedArticle.titolo : ''}</a>
        ) : (
          <Link href={selectedArticle?.url}>
            <a>{selectedArticle?.titolo ? selectedArticle.titolo : ''}</a>
          </Link>
        )}
      </div>

      {/*Completare la logica della disponibilità prodotto*/}
      <div className="card__footer">
        <div className="card__pezzi__wrapper">
          {/* {utente && priceLoaded && prodotto.listino && (
            <>
              <CheckmarkCircle2 className="card__pezzi__icon" />
              <span className="card__pezzi__label">
                {articoli.reduce((totale) => prodotto.listino.disponibilita + totale, 0)}{' '}
                <Trans chiave={'Pezzi'} />
              </span>
            </>
          )} */}
        </div>

        {
          priceLoaded && prodotto?.listino && VisualizzazionePrezzi()
          // <div className="card__prezzo">
          //   <p className="card__prezzo__label">
          //     {prodotto.listino?.prezzo == prodotto.listino?.prezzo_listino ? (
          //       <Trans chiave={'Prezzo'} />
          //     ) : (
          //       <Trans chiave={'Prezzo riservato'} />
          //     )}{' '}
          //     {prodotto?.listino?.con_fasce && <Trans chiave={'da'} />}:
          //   </p>
          //   <div className="card__prezzo__value">
          //     {prodotto.listino?.prezzo != prodotto.listino?.prezzo_listino && (
          //       <>
          //         <span className="card__prezzo__value__full-price">
          //           {priceNotation(prodotto.listino?.prezzo_listino, 3)} €
          //         </span>{' '}
          //       </>
          //     )}
          //     <span className="card__prezzo__value__final-price">
          //       {priceNotation(prodotto.listino?.prezzo, 3)} €
          //     </span>
          //   </div>
          // </div>
        }
      </div>
    </div>
  )
}

export default React.memo(NewProdottoCard)
