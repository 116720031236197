import * as React from 'react'

function SvgRemove(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--remove ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-remove_svg__a"
          d="M14.707 9.293a.999.999 0 00-1.414 0L12 10.586l-1.293-1.293a.999.999 0 10-1.414 1.414L10.586 12l-1.293 1.293a.999.999 0 101.414 1.414L12 13.414l1.293 1.293a.997.997 0 001.414 0 .999.999 0 000-1.414L13.414 12l1.293-1.293a.999.999 0 000-1.414M12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2"
        />
      </defs>
      <use xlinkHref="#24-remove_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgRemove
