import { PAGINEDETAIL } from '../utils/endpoint'
import { api } from '../utils'

export const fetchLoginText = (headers, apiBaseUrl = '', locale) => ({
  type: 'FETCH_LOGINTEXT',
  payload: api
    .get(PAGINEDETAIL, { params: { chiave: 'registrazione', lang: locale } })
    .catch((err) => console.log(err)),
})

export default function reducer(
  state = {
    login_text: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_LOGINTEXT_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_LOGINTEXT_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_LOGINTEXT_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        login_text: action.payload?.data.id !== undefined ? action.payload.data : undefined,
      }
    }

    default: {
      return state
    }
  }
}
