import { useEffect } from 'react'
import { useSelector } from 'react-redux'

function SgScript() {
  const { service_gift } = useSelector((state) => state.servicegift)

  useEffect(async () => {
    if (service_gift?.cookie_banner?.tipo_banner === 'banner_osano' && service_gift?.cookie_banner?.url) {
      const element = document.getElementById('cookie-banner-script')
      if (!element) {
        const newElement = document.createElement('script')
        newElement.setAttribute('id', 'cookie-banner-script')
        newElement.setAttribute('src', service_gift?.cookie_banner?.url)
        document.body.insertBefore(newElement, document.body.firstChild)
      }
    }
    if (service_gift?.analytics_scripts) {
      const parse = Range.prototype.createContextualFragment.bind(document.createRange())
      document.body.insertBefore(parse(service_gift?.analytics_scripts), document.body.firstChild)
    }
  }, [])

  return <></>
}

export default SgScript
