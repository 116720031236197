import { api } from '..'
import { WISHLISTELEMENTS, GETPRODOTTOVALUES } from '../endpoint'

export const HandleWishlist = async (active, prodotto) => {
  const params = {
    object_id: prodotto.id,
  }
  if (!active) {
    const response = await api.post(WISHLISTELEMENTS, { params })
    return response.data.created
  } else {
    const response = await api.delete(WISHLISTELEMENTS, { params })
    return !response.data.success
  }
}

export const HandleLoadPrezzi = async (values) => {
  const ids = values.map((item) => item.id)
  const params = {
    articoli_ids: ids,
  }
  const response = await api.get(GETPRODOTTOVALUES, { params })
  if (response.data.length > 0) {
    response.data.forEach((element, index) => {
      values[index].prezzo = element.prezzo
      values[index].prezzo_listino = element.prezzo_listino
      values[index].disponibilita = element.disponibilita
      values[index].in_opzione = element.in_opzione
    })
  }
  return values
}
