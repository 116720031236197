import * as React from 'react'

function SvgProfile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--profile ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M12 16c-3.658 0-5.845 1.01-6.648 3h13.296c-.803-1.99-2.99-3-6.648-3zm0-2c5.515 0 9 2.275 9 7H3c0-4.725 3.485-7 9-7zm0-10c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm0 2c-1.095 0-2 .905-2 2s.905 2 2 2 2-.905 2-2-.905-2-2-2z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgProfile
