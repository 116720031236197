import React, { useState } from 'react'
import { ChevronDown, Remove } from './../NewIcons'

const Chip = ({ placeholder, size, variant, icon, iconPos, onClick, selected }) => {
  return (
    <div
      style={{ backgroundColor: selected ? 'lightgray' : undefined }}
      className={'chip chip--' + (size ? size : 'md') + (variant ? ' chip--' + variant : '')}
      onClick={onClick}
    >
      {icon && iconPos === 'left' && <div className="chip__icon">{icon}</div>}
      {placeholder && <p className="chip__placeholder">{placeholder}</p>}
      {icon && iconPos !== 'left' && <div className="chip__icon">{icon}</div>}
    </div>
  )
}

export const ChipDropdown = ({ placeholder, size, options, value, setValue }) => {
  const [optionsOpen, setOptionsOpen] = useState(false)

  return (
    <div className={'chip-dropdown chip-dropdown--' + (size ? size : 'md')}>
      <Chip
        placeholder={placeholder}
        size={size}
        icon={<ChevronDown />}
        onClick={() => setOptionsOpen(!optionsOpen)}
      />
      {optionsOpen && (
        <div className="chip-dropdown__options">
          {options?.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setOptionsOpen(false)
                setValue(option.value)
              }}
              className={
                'chip-dropdown__option' +
                (option.value == value ? ' chip-dropdown__option--selected' : '')
              }
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export const ChipCloseFilter = ({ placeholder, size, onClose }) => {
  return (
    <Chip
      placeholder={placeholder}
      size={size}
      icon={<Remove />}
      onClick={onClose}
      variant="close-filter"
    />
  )
}

export default React.memo(Chip)
