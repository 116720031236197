import * as React from 'react'

function SvgCar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--car ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="car_svg__a"
          d="M15.833 15a.833.833 0 11-1.666 0 .833.833 0 011.666 0zM10 12.5H3.333l.014-8.333H12.5l-.013 8.333H10zM5.833 15a.833.833 0 11-1.666 0 .833.833 0 011.666 0zm10.834-4.6v2.1h-2.5V8.4l2.5 2zm1.354-1.05l-3.854-3.084v-2.1c0-.919-.678-1.666-1.511-1.666h-9.48c-.832 0-1.51.748-1.51 1.667V12.5c0 .727.428 1.34 1.018 1.568A2.474 2.474 0 002.5 15c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.294-.06-.572-.154-.833h5.308A2.448 2.448 0 0012.5 15c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.294-.06-.572-.154-.833h.154c.46 0 .833-.373.833-.834V10a.833.833 0 00-.312-.65z"
        />
      </defs>
      <use xlinkHref="#car_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgCar
