import { useEffect, useRef, useState } from 'react'

import { TRADUZIONI } from '../../../utils/endpoint'
import { api } from '../../../utils'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Trans = (props) => {
  const { traduzioni } = useSelector((state) => state.traduzioni)
  const [valore, setValore] = useState(null)
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    initializeValore()
  }, [traduzioni])

  const initializeValore = async () => {
    if (traduzioni.length) {
      let mapping = []
      let children = []

      let chiave = ''

      props.children
        ? (props.children.forEach((child, i) => {
            if (typeof child !== 'string') {
              const key = Object.keys(child)[0]
              children[i] = key
              mapping.push(child)
            } else children[i] = child
          }),
          (chiave = children.join('')))
        : (chiave = props.chiave)

      const traduzione = traduzioni.find((t) => t.chiave === chiave)

      let display = ''

      if (traduzione) {
        display = traduzione.valore
      } else {
        display = chiave
        api
          .post(TRADUZIONI, { chiave })
          // .then((response) => console.log(response.data))
          .catch((error) => console.log(error))
      }

      if (mapping.length) {
        mapping.forEach((map) => {
          display = display.replace(Object.keys(map)[0], Object.values(map)[0])
        })
      }

      setValore(display)
    }
  }

  return valore ? valore : props?.chiave
}
export default Trans
