import * as React from 'react'

function SvgStar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={'icon icon--star ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="24-star_svg__a"
          d="M12 16.05c.159 0 .318.038.463.113l3.769 1.97-.717-4.157a.999.999 0 01.29-.889l3.036-2.936-4.203-.612a.999.999 0 01-.752-.544L12 5.201l-1.886 3.794a.999.999 0 01-.752.544l-4.203.612 3.036 2.936a.999.999 0 01.29.889l-.717 4.157 3.769-1.97c.145-.075.304-.113.463-.113m5.562 4.905a.993.993 0 01-.463-.113l-5.1-2.664-5.098 2.664a1 1 0 01-1.448-1.057l.972-5.628-4.12-3.985a.998.998 0 01.551-1.708l5.7-.828 2.548-5.126c.338-.68 1.454-.68 1.792 0l2.547 5.126 5.7.828a.998.998 0 01.552 1.708l-4.12 3.985.972 5.628a1.002 1.002 0 01-.985 1.17"
        />
      </defs>
      <use xlinkHref="#24-star_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgStar
