import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from '../NewIcons'
//mancano gli esempi all'interno di Zeplin per questo Componente
//di conseguenza inserisco children per aumentare la flessibilità del componente
const Accordion = ({
  placeholder,
  children,
  initialValue,
  size,
  placeholderEllipses,
  withoutBorder,
  className,
  ref,
}) => {
  const [open, setOpen] = useState(initialValue ? initialValue : false)
  useEffect(() => {
    initialValue === null && setOpen(false)
    initialValue !== null && setOpen(initialValue)
  }, [initialValue])
  return (
    <div
      ref={ref}
      className={
        `accordion accordion--${size ? size : 'large'}` +
        `${!withoutBorder ? ' accordion--with-border' : ''}` +
        `${placeholderEllipses ? ' accordion--placeholder-ellipses' : ''}` +
        `${className ? ' ' + className : ''}`
      }
    >
      <div className="accordion__container" onClick={() => setOpen(!open)}>
        <p className="accordion__placeholder">{placeholder ? placeholder : 'Accordion'}</p>
        <div className="accordion__arrow">{open ? <ChevronUp /> : <ChevronDown />}</div>
      </div>

      {open && <div className="accordion__children">{children}</div>}
    </div>
  )
}

export default Accordion
