import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const Sticky = ({ children, offSetY, className, isSticky, setStickyFromProps }) => {
  const [sticky, setSticky] = useState()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  const handleScroll = () => {
    const offset = window.scrollY
    offset > offSetY ? setSticky(true) : setSticky(false)
    isSticky !== undefined && offset > offSetY
      ? setStickyFromProps(true)
      : setStickyFromProps(false)
  }

  return (
    <div
      className={
        'sticky' +
        (isSticky ? ' sticky--active' : '') +
        (className ? ' ' + className : '') +
        (isSticky && className ? ' ' + className + '--sticky' : '')
      }
    >
      {children}
    </div>
  )
}

export default React.memo(Sticky)
