import React from 'react'
import { WishlistLine, WishlistFill } from '../NewIcons'

const Wishlist = ({ isActive, onClick }) => {
  return isActive ? (
    <WishlistFill classes="wishlist wishlist--active" onClick={onClick} />
  ) : (
    <WishlistLine classes="wishlist" onClick={onClick} />
  )
}

export default Wishlist
