import React from 'react'

const IconBase = ({ name, className, w, h, children }) => (
  <svg
    viewBox={`0 0 ${w} ${h ? h : w}`}
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon--${name}${className ? ' ' + className : ''}`}
  >
    {children}
  </svg>
)

export default IconBase
