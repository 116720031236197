import * as React from 'react'

function SvgEMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={'icon icon--email ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <defs>
        <path
          id="e-mail_svg__a"
          d="M15.833 15H4.167a.834.834 0 01-.834-.833V6.042L9.5 10.667a.832.832 0 001 0l6.167-4.625v8.125c0 .46-.375.833-.834.833zm-.556-10L10 8.958 4.723 5h10.555zm.556-1.667H4.167a2.503 2.503 0 00-2.5 2.5v8.334c0 1.378 1.121 2.5 2.5 2.5h11.666c1.379 0 2.5-1.122 2.5-2.5V5.833c0-1.378-1.121-2.5-2.5-2.5z"
        />
      </defs>
      <use xlinkHref="#e-mail_svg__a" fillRule="evenodd" />
    </svg>
  )
}

export default SvgEMail
